<template>
  <div class="provider-body">
    <div class="main-wrapper">
      <dashboardnavbar />

      <div class="page-wrapper">
        <sidebar />

        <breadcrumb :title="title" :text="text" :text1="text1" />

        <div class="content">
          <div class="container" v-if="type">
            <div class="row">
              <div class="col-md-6 col-lg-4 d-flex">
                <CardTO
                  style="cursor: pointer"
                  @click="onClick('pauli')"
                  :image="require('@/views/tryouts/type/PAULI.png')"
                  :name="'TES PAULI'"
                  :icon="type.icon"
                  :description="'tes'"
                />
              </div>

              <div class="col-md-6 col-lg-4 d-flex">
                <CardTO
                  style="cursor: pointer"
                  @click="onClick('kraepelin')"
                  :image="require('@/views/tryouts/type/KRAEPELIN.png')"
                  :name="'TES KRAEPELIN'"
                  :icon="type.icon"
                  :description="'tes'"
                />
              </div>

              <div class="col-md-6 col-lg-4 d-flex">
                <router-link
                  :to="{ path: '/tryouts/group', query: { type: type.id } }"
                >
                  <CardTO
                    :name="'TES ANGKA HILANG'"
                    :image="require('@/views/tryouts/type/ANGKAHILANG.png')"
                    :icon="type.icon"
                    :description="'tes'"
                  />
                </router-link>
              </div>
            </div>
          </div>
          <div class="center" v-else>
            <img :src="comingsoon" style="width: 100%" />
            <br />
            <div class="m-4 row">
              <div class="col-12 mt-4">
                <h3 style="text-align: center">Coming Soon</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import comingsoon from "@/assets/img/coming-soon.png";
import CardTO from "@/views/tryouts/components/CardTO.vue";
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "axios";
import { useAuthStore } from "@/stores/authStore";
import swal from "sweetalert2";
const type = ref({});
const $route = useRoute();
const router = useRouter();
const title = "Pilih Try Out yang Akan Kamu Kerjakan!";
const text = "Home";
const text1 = "Tipe";
const pauli = ref({});
const kraepelin = ref(null);
const type_id = $route.query.type;
const fetchData = async (id) => {
  try {
    const res = await axios.get(`/api/types/${id}/edit`);
    type.value = res.data.type;
    console.log(type.value);
  } catch (error) {
    console.error("Error while fetching type data:", error);
  }
  try {
    const res = await axios.get(`/api/pauli-questions/${type_id}`);
    pauli.value = res.data.pauli;
  } catch (error) {
    console.error("Error while fetching type data:", error);
  }
  try {
    const res = await axios.get(`/api/kraepelin-questions/${type_id}`);
    kraepelin.value = res.data.kraepelin;
    console.log(kraepelin.value);
  } catch (error) {
    console.error("Error while fetching type data:", error);
  }
};

const authStore = useAuthStore();
const onClick = (genre) => {
  const item = genre == "pauli" ? pauli.value : kraepelin.value;
  const tryout_id = item.id;
  // :to="{ path: index == 0 ? '/tryouts/kecermatan/'+itemTryout.id : '/tryouts/pilihanGanda/'+itemTryout.id}"
  swal
    .fire({
      text: "Apakah anda ingin memulai tryout ini?",
      width: 340,
      padding: "30px",
      showCancelButton: true,
      confirmButtonText: "Mulai",
      reverseButtons: true,
      backdrop: `
      rgba(0,0,123,0.4)
      url("/images/nyan-cat.gif")
      left top
      no-repeat
    `,
    })
    .then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        console.log({
          auth: authStore.user.type,
          acc: item,
        });
        if (
          authStore.user.type === item.access ||
          (authStore.user.type === "premium" && item.access === "superior") ||
          (authStore.user.type === "genius" && item.access === "vip") ||
          (authStore.user.type === "superior" && item.access === "premium") ||
          (authStore.user.type === "vip" && item.access === "genius") ||
          (authStore.user.type === "genius" && item.access === "premium") ||
          (authStore.user.type === "genius" && item.access === "superior")
        ) {
          swal.fire("Selamat mengerjakan", "", "success");
          try {
            const res = await axios.post(
              `/api/submit-result_tryout_user/${tryout_id}`
            );
            if (res.data.genre == "number") {
              router.push({
                path: "/tryouts/kecermatan/" + res.data.data.id,
              });
            } else if (res.data.genre == "multiple_choice") {
              router.push({
                path: "/tryouts/pilihanGanda/" + res.data.data.id,
              });
            } else if (res.data.genre == "pauli") {
              router.push({
                path: "/tryouts/tni/" + res.data.data.id,
              });
            } else if (res.data.genre == "kraepelin") {
              router.push({
                path: "/tryouts/tni/" + res.data.data.id,
              });
            }
          } catch (err) {}
        } else if (item.access == "free") {
          try {
            const res = await axios.post(
              `/api/submit-result_tryout_user/${tryout_id}`
            );
            console.log(res.data);
            if (res.data.genre == "number") {
              router.push({
                path: "/tryouts/kecermatan/" + res.data.data.id,
              });
            } else if (res.data.genre == "multiple_choice") {
              router.push({
                path: "/tryouts/pilihanGanda/" + res.data.data.id,
              });
            } else if (res.data.genre == "pauli") {
              router.push({
                path: "/tryouts/tni/" + res.data.data.id,
              });
            } else if (res.data.genre == "kraepelin") {
              router.push({
                path: "/tryouts/tni/" + res.data.data.id,
              });
            }
          } catch (err) {
            console.error("Error while submitting result:", err);
            // Tindakan lain yang sesuai untuk menangani kesalahan
          }
        } else {
          swal
            .fire({
              title: "Anda tidak memiliki akses",
              text: "Untuk mengakses premium silahkan membeli voucher terlebih dahulu",
              icon: "error",
              showCancelButton: true,
              confirmButtonText: "Beli Voucher",
              cancelButtonText: "Tidak",
              reverseButtons: true,
            })
            .then((result) => {
              if (result.isConfirmed) {
                router.push("/vouchers");
              } else {
              }
            });
        }
      }
    });
};

onMounted(() => {
  fetchData($route.query.type);
});
</script>

<style scoped>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 30%;
}

.card_info {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
}

.card_info img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(100%);
  transition: all 0.3s ease-in-out;
}

.card_info::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(76, 64, 237, 0.349);
}

.card_info:hover img {
  filter: grayscale(0);
}

.card_info__content {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Align content vertically center */
  align-items: center;
  text-align: center;
  padding: 20px;
}

.card_info__content h5 {
  color: white;
  font-weight: bold;
  font-size: 2rem;
}
</style>
