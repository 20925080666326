<template>
  <div class="provider-body">
    <div class="main-wrapper">
      <dashboardnavbar />

      <div class="page-wrapper">
        <sidebar />

        <div class="container">
          <div class="content p-5">
            <!-- <div @click="testSnap">Test Snap</div> -->
            <div
              class="row shadow-sm align-items-center justify-content-between"
            >
              <div class="col-12 col-lg-6 d-flex justify-content-center">
                <div class="table-responsive p-4" style="width: 100%">
                  <table class="table table-bordered shadow-sm">
                    <thead>
                      <tr>
                        <th scope="col">Pilihan Produk</th>
                        <th scope="col">Access</th>
                        <th scope="col">Jumlah</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in vouchers">
                        <td>{{ item.name }}</td>
                        <td>{{ item.access }}</td>
                        <td class="d-flex justify-content-center">
                          <div
                            class="voucher__amount"
                            style="width: max-content"
                          >
                            <div
                              class="voucher__amount_min"
                              @click="minVoucher(index)"
                            >
                              -
                            </div>
                            <div class="voucher__amount_content">
                              {{ amount[index] }}
                            </div>
                            <div
                              class="voucher__amount_plus"
                              @click="plusVoucher(index)"
                            >
                              +
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <b-button v-if="payment.payment_details.length > 0" @click="onSubmit()" class="mt-3" style="width: 100%"
                    >Beli</b-button
                  >
                  <div class="detail mt-3">
                    <div class="detail-content">
                      <h6>Rincian</h6>
                      <div>
                        Voucher Tryout Online (x{{ totalVoucher(amount) }})
                      </div>
                      <div class="line"></div>
                      Jenis Voucher :
                      <div v-for="(item, index) in payment.payment_details">
                        {{ vouchers[index].name }} (x{{ item.qty }}) Rp.
                        {{ vouchers[index].amount * item.qty }}
                      </div>
                      <div class="line"></div>
                      Total : Rp. {{ totalPayment() }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6 voucher_desc">
                <div class="p-5">
                  <h6>Yang Anda Dapatkan:</h6>
                  <ul>
                    <li>
                      <i class="fa-solid fa-check"></i> Akses semua paket try
                      out dan fitur premium
                    </li>
                    <li>
                      <i class="fa-solid fa-check"></i> Paket Try Out Tes TNI,
                      POLRI, Kedinasan, dan Tes CPNS
                    </li>
                    <li>
                      <i class="fa-solid fa-check"></i> Try Out Tes Psikologi
                    </li>
                    <li><i class="fa-solid fa-check"></i> Pass Hand Test</li>
                    <li><i class="fa-solid fa-check"></i> Tes Kecerdasan</li>
                    <li><i class="fa-solid fa-check"></i> Tes Kepribadian</li>
                    <li>
                      <i class="fa-solid fa-check"></i> Tes Sikap Kerja
                      (Kecermatan)
                    </li>
                    <li><i class="fa-solid fa-check"></i> Tes Akademik</li>
                    <li>
                      <i class="fa-solid fa-check"></i> Tes SKD Kedinasan dan
                      CPNS
                    </li>
                    <li><i class="fa-solid fa-check"></i> Riwayat Try Out</li>
                    <li><i class="fa-solid fa-check"></i> Perankingan Hasil</li>
                    <li>
                      <i class="fa-solid fa-check"></i> Fitur Skoring Tes
                      Jasmani, Psikologi, dan Nilai Akhir
                    </li>
                    <li>
                      <i class="fa-solid fa-check"></i> Tes Kompetensi
                      Manajerial
                    </li>
                    <li>
                      <i class="fa-solid fa-check"></i> Dan banyak fitur lainnya
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { usePaymentStore } from "@/stores/paymentStore";
const router = useRouter();
const store = usePaymentStore();
import swal from "sweetalert2";
import { useAuthStore } from "@/stores/authStore";
const authStore = useAuthStore();
let payment = ref({
  payment_details: [],
});
let amount = ref([]);
let vouchers = ref([]);
const onSubmit = async () => {
  try {
    const { data } = await store.payment(payment.value);
    if (data.status === 201) {
      window.snap.pay(data.snap_token, {
        onSuccess: async (result) => {
          try {
            const res = await store.callbackPayment(result);

            authStore.setUser(res.data.user);

            swal.fire({
              icon: "success",
              text: "Pembelian Berhasil !!",
            });

            router.push('riwayat-transaksi');
          } catch (error) {
            console.error("Error while processing payment callback:", error);
          }


        },
        onPending: async (result) => {
          try {
            await store.callbackPayment(result);
            router.push('riwayat-transaksi');
          } catch (error) {
            console.error("Error while processing payment callback:", error);
          }
        },
        onError: async (result) => {
          try {
            await store.callbackPayment(result);
            swal.fire({
              icon: "success",
              text: "Pembelian Gagal !!",
            });
          } catch (error) {
            console.error("Error while processing payment callback:", error);
          }
        },
        onClose: async () => {
          try {
            await store.deletePayment(data.data.id);
          } catch (error) {
            console.error("Error while deleting payment:", error);
          }
        },
      });
    }
  } catch (error) {
    console.log(error);
  }
};

const generateSnap = () => {
  let recaptchaScript = document.createElement("script");
  recaptchaScript.setAttribute("src", process.env.VUE_APP_MIDTRANS_SNAP_URL);
  recaptchaScript.setAttribute(
    "data-client-key",
    process.env.VUE_APP_MIDTRANS_CLIENT_KEY
  );
  document.head.appendChild(recaptchaScript);
};

// Function to filter data based on category id
const fetchData = async () => {
  try {
    const res = await axios.get(`/api/vouchers/`);
    vouchers.value = res.data.vouchers;
    for (let i = 0; i < vouchers.value.length; i++) {
      amount.value.push(0);
    }
  } catch (error) {
    console.error("Error while fetching vouchers data:", error);
  }
};

const minVoucher = (index) => {
  if (amount.value[index] > 0) {
    amount.value[index]--;
    const itemIndex = payment.value.payment_details.findIndex(
      (item) => item.voucher_id === vouchers.value[index].id
    );
    if (itemIndex !== -1) {
      payment.value.payment_details[itemIndex].qty = amount.value[index];
      if (amount.value[index] === 0) {
        payment.value.payment_details.splice(itemIndex, 1); // Remove item if quantity is 0
      }
    }
  }
};

const plusVoucher = (index) => {
  amount.value[index]++;
  const itemIndex = payment.value.payment_details.findIndex(
    (item) => item.voucher_id === vouchers.value[index].id
  );
  if (itemIndex !== -1) {
    payment.value.payment_details[itemIndex].qty = amount.value[index];
    if (amount.value[index] === 0) {
      payment.value.payment_details.splice(itemIndex, 1); // Remove item if quantity is 0
    }
  } else {
    payment.value.payment_details.push({
      voucher_id: vouchers.value[index].id,
      qty: amount.value[index],
    });
  }
};

const totalVoucher = () => {
  let total = 0;
  for (let i = 0; i < amount.value.length; i++) {
    total += amount.value[i];
  }
  return total;
};

const totalPayment = () => {
  let total = 0;
  payment.value.payment_details.forEach((item) => {
    const voucher = vouchers.value.find(
      (voucher) => voucher.id === item.voucher_id
    );
    if (voucher) {
      total += voucher.amount * item.qty;
    }
  });
  return total;
};
onMounted(() => {
  generateSnap();
  fetchData();
});
</script>

<style scoped>
.line {
  margin-top: 5px;
  margin-bottom: 5px;
  border-bottom: 1px dashed #000; /* Garis putus-putus dengan warna hitam */
}

.detail-content {
  border: 1px solid #9292c4;
  padding: 20px;
  border-radius: 10px;
}

table.table-bordered {
  border: 1px solid #f7f7ff;
  margin-top: 20px;
}
table.table-bordered > thead > tr > th {
  border: 1px solid #f7f7ff;
}
table.table-bordered > tbody > tr > td {
  border: 1px solid #f7f7ff;
}

.voucher_desc {
  background: #f7f7ff;
  min-height: 400px;
  justify-content: center;
  padding: 40px;
  border-radius: 10px;
  color: rgb(46, 46, 46);
}
.voucher__amount {
  background: white;
  width: max-content !important;
  display: flex;
  justify-content: center;
}

.table-price {
  border-color: #f7f7ff !important;
}

.voucher__amount_min {
  width: 40px;
  height: 40px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Align content vertically center */
  align-items: center;
  background: #f7f7ff;
  color: rgb(46, 46, 46);
  cursor: pointer;
}

.voucher__amount_content {
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Align content vertically center */
  align-items: center;
  background: #ffffff;
  border: solid 1px #f7f7ff;
  color: rgb(46, 46, 46);
}

.voucher__amount_plus {
  width: 40px;
  height: 40px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Align content vertically center */
  align-items: center;
  background: #f7f7ff;
  color: rgb(46, 46, 46);
  cursor: pointer;
}

.voucher__amount_min,
.voucher__amount_plus {
  user-select: none;
}
</style>
