<template>
  <div class="login-body">
    <div class="main-wrapper">
      <otpheader />

      <div class="page-wrapper">
        <passwordrecoverycontent />
      </div>

      <truelyfooter />
    </div>
  </div>
</template>
