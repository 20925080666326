<template>
  <div class="provider-body">
    <div class="main-wrapper">
      <dashboardnavbar />

      <div class="page-wrapper">
        <sidebar />
        <div class="container">
          <div class="content">
          <b-form @submit.prevent="updatePassword">
            <div class="row">
              <div class="col-lg-7 col-md-9 col-sm-10 col-xs-12 m-auto">
                <div class="content-page-header">
                  <h5 class="mb-2">Change Password</h5>
                </div>
                <!-- Password fields -->
                <div class="col-12">
                  <div class="form-group">
                    <label>Old Password</label>
                    <input
                      v-model="data.old_password"
                      type="password"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <label>New Password</label>
                    <input
                      v-model="data.new_password"
                      type="password"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <label>Confirm New Password</label>
                    <input
                      v-model="data.password_confirmation"
                      type="password"
                      class="form-control"
                    />
                  </div>
                </div>

                <!-- Submit Button -->
                <div class="col-12 mt-3">
                  <div class="btn-path">
                    <router-link to="/profile" class="btn btn-cancel me-3"
                      >Cancel</router-link
                    >
                    <b-button type="submit" variant="primary"
                      >Change Password</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </b-form>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import swal from "sweetalert2";
import axios from "axios";
import { useLoading } from "vue-loading-overlay";
import { useRoute, useRouter } from "vue-router";

let errors = ref({});
const $loading = useLoading({});
const router = useRouter();
const route = useRoute();
let data = ref({});

const updatePassword = async () => {
  const loader = $loading.show({});
  try {
    const formData = {
      old_password: data.value.old_password,
      new_password: data.value.new_password,
      password_confirmation: data.value.password_confirmation,
    };

    const response = await axios.put(
      `api/users/${route.params.id}/updateProfile`,
      formData
    );
    loader.hide();
    swal.fire({ icon: "success", text: response.data.message });
    router.push("/profile");
  } catch (error) {
    loader.hide();
    errors.value = error.response.data;
  }
};

const fetchData = async () => {
  try {
    const response = await axios.get(`api/users/${route.params.id}/edit`);
    data.value = response.data.user;
  } catch (error) {
    errors.value = error.response.data;
  }
};

onMounted(() => {
  fetchData();
});
</script>
