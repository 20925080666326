<template>
  <div class="provider-body">
    <div class="main-wrapper">
      <dashboardnavbar />

      <div class="page-wrapper">
        <sidebar />
        <div
          class="container-fluid mx-auto justify-content-center row pt-3 gap-4 mt-2"
        >
          <div class="col-md-12 col-xl-8 d-flex justify-content-center">
            <div class="col-auto p-3 timer">Timer : {{ formattedTime }}</div>
          </div>
          <div class="col-md-12 col-12 col-xl-3"></div>
        </div>
        <div
          v-if="data[page]"
          class="container-fluid mx-auto justify-content-center row p-3 gap-4"
        >
          <div class="col-md-12 col-xl-8">
            <div class="content mb-3">Soal No : {{ page + 1 }}</div>
            <div class="content">
              <div v-html="data[page].questions"></div>
              <div v-if="data[page].image">
                <img class="img-fluid mb-4" :src="data[page].image" />
              </div>
            </div>
            <div class="content mt-3" v-if="data[page].type == 'single'">
              <label class="custom_radio" v-if="data[page].choice_a">
                <input
                  type="radio"
                  v-model="selected[page]"
                  @change="
                    onSend({
                      question_id: data[page].id,
                      answer_user: data[page].choice_a,
                      result_tryout_user_id: Result_RTO,
                      a: selected[page].a,
                      b: selected[page].b,
                      c: selected[page].c,
                      d: selected[page].d,
                      e: selected[page].e,
                    })
                  "
                  :value="{
                    question_id: data[page].id,
                    answer_user: data[page].choice_a,
                    result_tryout_user_id: Result_RTO,
                    a: selected[page].a,
                    b: selected[page].b,
                    c: selected[page].c,
                    d: selected[page].d,
                    e: selected[page].e,
                  }"
                  name="payment"
                  class="card-payment"
                />
                <span class="checkmark"></span>
                <span>A) {{ data[page].choice_a }}</span>
                <span>
                <div v-if="data[page].img_choice_a">
                <img class="img-fluid mb-4" :src="data[page].img_choice_a" />
                </div>
                </span>
              </label>
              <label class="custom_radio" v-if="data[page].choice_b != null">
                <input
                  type="radio"
                  v-model="selected[page]"
                  @change="
                    onSend({
                      question_id: data[page].id,
                      answer_user: data[page].choice_b,
                      result_tryout_user_id: Result_RTO,
                      a: selected[page].a,
                      b: selected[page].b,
                      c: selected[page].c,
                      d: selected[page].d,
                      e: selected[page].e,
                    })
                  "
                  :value="{
                    question_id: data[page].id,
                    answer_user: data[page].choice_b,
                    result_tryout_user_id: Result_RTO,
                    a: selected[page].a,
                    b: selected[page].b,
                    c: selected[page].c,
                    d: selected[page].d,
                    e: selected[page].e,
                  }"
                  name="payment"
                  class="card-payment"
                />
                <span class="checkmark"></span>
                <span>B) {{ data[page].choice_b }}</span>
                <div v-if="data[page].img_choice_b">
                <img class="img-fluid mb-4" :src="data[page].img_choice_b" />
                </div>
              </label>
              <label class="custom_radio" v-if="data[page].choice_c != null">
                <input
                  type="radio"
                  v-model="selected[page]"
                  @change="
                    onSend({
                      question_id: data[page].id,
                      answer_user: data[page].choice_c,
                      result_tryout_user_id: Result_RTO,
                      a: selected[page].a,
                      b: selected[page].b,
                      c: selected[page].c,
                      d: selected[page].d,
                      e: selected[page].e,
                    })
                  "
                  :value="{
                    question_id: data[page].id,
                    answer_user: data[page].choice_c,
                    result_tryout_user_id: Result_RTO,
                    a: selected[page].a,
                    b: selected[page].b,
                    c: selected[page].c,
                    d: selected[page].d,
                    e: selected[page].e,
                  }"
                  name="payment"
                  class="card-payment"
                />
                <span class="checkmark"></span>
                <span>C) {{ data[page].choice_c }}</span>
                <div v-if="data[page].img_choice_c">
                <img class="img-fluid mb-4" :src="data[page].img_choice_c" />
                </div>
              </label>
              <label class="custom_radio" v-if="data[page].choice_d != null">
                <input
                  type="radio"
                  v-model="selected[page]"
                  @change="
                    onSend({
                      question_id: data[page].id,
                      answer_user: data[page].choice_d,
                      result_tryout_user_id: Result_RTO,
                      a: selected[page].a,
                      b: selected[page].b,
                      c: selected[page].c,
                      d: selected[page].d,
                      e: selected[page].e,
                      a: selected[page].a,
                      b: selected[page].b,
                      c: selected[page].c,
                      d: selected[page].d,
                      e: selected[page].e,
                    })
                  "
                  :value="{
                    question_id: data[page].id,
                    answer_user: data[page].choice_d,
                    result_tryout_user_id: Result_RTO,
                    a: selected[page].a,
                    b: selected[page].b,
                    c: selected[page].c,
                    d: selected[page].d,
                    e: selected[page].e,
                    a: selected[page].a,
                    b: selected[page].b,
                    c: selected[page].c,
                    d: selected[page].d,
                    e: selected[page].e,
                  }"
                  name="payment"
                  class="card-payment"
                />
                <span class="checkmark"></span>
                <span>D) {{ data[page].choice_d }}</span>
                <div v-if="data[page].img_choice_d">
                <img class="img-fluid mb-4" :src="data[page].img_choice_d" />
                </div>
              </label>
              <label class="custom_radio" v-if="data[page].choice_e != null">
       
                <input
                  type="radio"
                 
                  v-model="selected[page]"
                  @change="
                    onSend({
                      question_id: data[page].id,
                      answer_user: data[page].choice_e,
                      result_tryout_user_id: Result_RTO,
                      a: selected[page].a,
                      b: selected[page].b,
                      c: selected[page].c,
                      d: selected[page].d,
                      e: selected[page].e,
                    })
                  "
                  :value="{
                    question_id: data[page].id,
                    answer_user: data[page].choice_e,
                    result_tryout_user_id: Result_RTO,
                    a: selected[page].a,
                    b: selected[page].b,
                    c: selected[page].c,
                    d: selected[page].d,
                    e: selected[page].e,
                  }"
                  name="payment"
                  class="card-payment"
                />
                <span class="checkmark"></span>
                <span>E) {{ data[page].choice_e }}</span>
                <div v-if="data[page].img_choice_e">
                <img class="img-fluid mb-4" :src="data[page].img_choice_e" />
                </div>
              </label>
            </div>

            <div class="content mt-3" v-else>
              <label class="custom_check d-block" v-if="data[page].choice_a != null">
                <input
                  type="checkbox"
                  v-model="selected[page].a"
                  @change="
                    onSend({
                      question_id: data[page].id,
                      answer_user: 'multiple choice',
                      result_tryout_user_id: Result_RTO,
                      a: selected[page].a,
                      b: selected[page].b,
                      c: selected[page].c,
                      d: selected[page].d,
                      e: selected[page].e,
                    })
                  "
                  :value="{
                    question_id: data[page].id,
                    answer_user: 'multiple choice',
                    result_tryout_user_id: Result_RTO,
                    a: selected[page].a,
                    b: selected[page].b,
                    c: selected[page].c,
                    d: selected[page].d,
                    e: selected[page].e,
                  }"
                  name="payment"
                  class="card-payment"
                />
                <span class="checkmark"></span>
                <span>A) {{ data[page].choice_a }}</span>
              </label>
              <label class="custom_check d-block" v-if="data[page].choice_b != null">
                <input
                  type="checkbox"
                  v-model="selected[page].b"
                  @change="
                    onSend({
                      question_id: data[page].id,
                      answer_user: 'multiple choice',
                      result_tryout_user_id: Result_RTO,
                      a: selected[page].a,
                      b: selected[page].b,
                      c: selected[page].c,
                      d: selected[page].d,
                      e: selected[page].e,
                    })
                  "
                  :value="{
                    question_id: data[page].id,
                    answer_user: 'multiple choice',
                    result_tryout_user_id: Result_RTO,
                    a: selected[page].a,
                    b: selected[page].b,
                    c: selected[page].c,
                    d: selected[page].d,
                    e: selected[page].e,
                  }"
                  name="payment"
                  class="card-payment"
                />
                <span class="checkmark"></span>
                <span>B) {{ data[page].choice_b }}</span>
              </label>
              <label class="custom_check d-block" v-if="data[page].choice_c != null">
                <input
                  type="checkbox"
                  v-model="selected[page].c"
                  @change="
                    onSend({
                      question_id: data[page].id,
                      answer_user: 'multiple choice',
                      result_tryout_user_id: Result_RTO,
                      a: selected[page].a,
                      b: selected[page].b,
                      c: selected[page].c,
                      d: selected[page].d,
                      e: selected[page].e,
                    })
                  "
                  :value="{
                    question_id: data[page].id,
                    answer_user: 'multiple choice',
                    result_tryout_user_id: Result_RTO,
                    a: selected[page].a,
                    b: selected[page].b,
                    c: selected[page].c,
                    d: selected[page].d,
                    e: selected[page].e,
                  }"
                  name="payment"
                  class="card-payment"
                />
                <span class="checkmark"></span>
                <span>C) {{ data[page].choice_c }}</span>
              </label>
              <label class="custom_check d-block" v-if="data[page].choice_d != null">
                <input
                  type="checkbox"
                  v-model="selected[page].d"
                  @change="
                    onSend({
                      question_id: data[page].id,
                      answer_user: 'multiple choice',
                      result_tryout_user_id: Result_RTO,
                      a: selected[page].a,
                      b: selected[page].b,
                      c: selected[page].c,
                      d: selected[page].d,
                      e: selected[page].e,
                    })
                  "
                  :value="{
                    question_id: data[page].id,
                    answer_user: 'multiple choice',
                    result_tryout_user_id: Result_RTO,
                    a: selected[page].a,
                    b: selected[page].b,
                    c: selected[page].c,
                    d: selected[page].d,
                    e: selected[page].e,
                  }"
                  name="payment"
                  class="card-payment"
                />
                <span class="checkmark"></span>
                <span>D) {{ data[page].choice_d }}</span>
              </label>
              <label class="custom_check d-block" v-if="data[page].choice_e != null">
                <input
                  type="checkbox"
                  v-model="selected[page].e"
                  @change="
                    onSend({
                      question_id: data[page].id,
                      answer_user: 'multiple choice',
                      result_tryout_user_id: Result_RTO,
                      a: data[page].a,
                      b: data[page].b,
                      c: data[page].c,
                      d: data[page].d,
                      e: data[page].e,
                    })
                  "
                  :value="{
                    question_id: data[page].id,
                    answer_user: 'multiple choice',
                    result_tryout_user_id: Result_RTO,
                    a: data[page].a,
                    b: data[page].b,
                    c: data[page].c,
                    d: data[page].d,
                    e: data[page].e,
                  }"
                  name="payment"
                  class="card-payment"
                />
                <span class="checkmark"></span>
                <span>E) {{ data[page].choice_e }}</span>
              </label>
            </div>
            <div class="mt-3 d-flex gap-3 justify-content-end">
              <button
                v-if="page > 0 && backable"
                @click="page - 1 >= 0 ? page-- : page"
                class="w-max btn btn-outline-primary"
              >
                Soal Sebelumnya
              </button>
              <button
                v-if="page < data.length - 1"
                @click="page < data.length - 1 ? page++ : page"
                class="w-max btn btn-primary"
              >
                Soal Selanjutnya
              </button>
              <button
                v-else
                @click="onSubmit(Result_RTO)"
                class="w-max btn btn-primary"
              >
                Submit
              </button>
            </div>
          </div>
          <div class="col-md-12 col-12 col-xl-3">
            <div class="content">
              <div class="mb-3 text-center">Soal</div>
              <div class="answer_wrapper">
                <div
                  @click="backable ? (page = index - 1) : page"
                  :class="{
                    active:
                      selected[index - 1] && selected[index - 1].answer_user,
                    active_now: page + 1 == index,
                    active_mul:
                      (selected[index - 1] && selected[index - 1].a) ||
                      (selected[index - 1] && selected[index - 1].b) ||
                      (selected[index - 1] && selected[index - 1].c) ||
                      (selected[index - 1] && selected[index - 1].d) ||
                      (selected[index - 1] && selected[index - 1].e),
                  }"
                  class="answer"
                  v-for="index in data.length"
                >
                  {{ index }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import axios from "axios";
import { onMounted, ref, onBeforeUnmount } from "vue";
import { usePilihanGandaStore } from "@/stores/pilihanGandaStore";
import { useRoute, useRouter } from "vue-router";
import swal from "sweetalert2";
import { useAuthStore } from "@/stores/authStore";
let timoutData = ref(null);
let pilihanGandaStore = usePilihanGandaStore();
let data = ref([]);
let page = ref(0);
let selected = ref([]);
let timeState = ref(null);
const route = useRoute();
const Result_RTO = route.params.id;
const router = useRouter();

const onSubmit = async () => {
  stopTimer();
  try {
    await axios.put("/api/update-status/" + Result_RTO, {
      status: "done",
    });

    swal.fire({ icon: "success", text: "Quizz selesai!" });
    router.push({ path: `/tryouts/pilihanGanda/${Result_RTO}/result` });
  } catch (error) {
    console.error("Error while updating status:", error);
    // Tindakan lain yang sesuai untuk menangani kesalahan
  }
};
const dateToTime = (start) => {
  let targetDate = new Date(timeState.value);
  let currentDate = new Date(start);
  let currentTime = Math.floor(currentDate.getTime() / 1000);
  let targetTime = Math.floor(targetDate.getTime() / 1000);
  let timeDiff = targetTime - currentTime;
  return timeDiff;
};

let spend_time = ref(0);
const spendTime = () => {
  pilihanGandaStore.setTime(spend_time.value);
  // spend_time.value = 0;
  return pilihanGandaStore.getTime();
};

const onSend = async (params) => {
  params.time_spend = spendTime();
  params.tryout_id = TRYOUT_ID.value;
  if (params.answer_user == null) {
    ("multiple answer");
  }
  pilihanGandaStore.setData(params);
  // getCurrentAnswer(data.value[page.value].id);
  if (time.value == 0) {
    stopTimer();
    swal.fire({ icon: "success", text: "Waktu Hasbis! Quizz selesai!" });
    try {
      await axios.put("/api/update-status/" + Result_RTO, {
        status: "done",
      });

      router.push({ path: `/tryouts/pilihanGanda/${Result_RTO}/result` });
    } catch (error) {
      console.error("Error while updating status:", error);
      // Tindakan lain yang sesuai untuk menangani kesalahan
    }

    pilihanGandaStore.setData(params);
  }
};

const getCurrentAnswer = async () => {
  await pilihanGandaStore.setDB(Result_RTO);
  pilihanGandaStore.question_anser_user.forEach((stateItem) => {
    const index = selected.value.findIndex(
      (item) => item.question_id == stateItem.question_id
    );
    // console.log('index', index);
    if (index !== -1) {
      selected.value[index].answer_user = stateItem.answer_user;
      selected.value[index].a = stateItem.a;
      selected.value[index].b = stateItem.b;
      selected.value[index].c = stateItem.c;
      selected.value[index].d = stateItem.d;
      selected.value[index].e = stateItem.e;
    }
  });

  // try {
  //   // Assuming pilihanGandaStore.setDB() returns a promise
  //   await pilihanGandaStore.setDB(Result_RTO);
  //   // Assuming pilihanGandaStore.question_anser_user is an array
  //   const updatedSelected = selected.value.map((selectedItem) => {
  //     const matchingStateItem = pilihanGandaStore.question_anser_user.find(
  //       (stateItem) => stateItem.question_id == selectedItem.question_id
  //     );
  //     console.log('mathing', matchingStateItem);
  //     if (matchingStateItem) {
  //       return {
  //         question_id: selectedItem.question_id,
  //         answer_user: matchingStateItem.answer_user,
  //         result_tryout_user_id: selectedItem.result_tryout_user_id,
  //         time_spend: selectedItem.time_spend,
  //         // Fix accessing properties
  //         a: matchingStateItem.a,
  //         b: matchingStateItem.b,
  //         c: matchingStateItem.c,
  //         d: matchingStateItem.d,
  //         e: matchingStateItem.e,
  //       };
  //     } else {
  //       // If no matching state item found, return the original selected item
  //       console.log('default', selectedItem.question_id);
  //       return selectedItem;
  //     }
  //   });
  //   // Update the selected array with the updated values
  //   selected.value = updatedSelected;
  // } catch (error) {
  // }
};

// getCurrentAnswer(data.value[page.value].id);

// Define the timer logic
const timer = ref(null);
const time = ref(null);
const formattedTime = ref(null);

function formatTime(seconds) {
  const hours = Math.floor(seconds / 3600); // Menghitung jumlah jam
  const minutes = Math.floor((seconds % 3600) / 60); // Menghitung jumlah menit
  const remainingSeconds = seconds % 60; // Menghitung jumlah detik
  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`; // Menggabungkan jam, menit, dan detik
}

function startTimer() {
  if (time.value <= 0) {
    formattedTime.value = "Waktu Habis";
    clearInterval(timer.value);
    return;
  }
  timer.value = setInterval(() => {
    time.value--;
    spend_time.value++;
    formattedTime.value = formatTime(time.value);

    if (time.value === 0) {
      clearInterval(timer.value);
      if (time.value === 0) {
        clearInterval(timer.value);
        onSubmit();
        swal.fire({ icon: "success", text: "Quizz selesai!" });
      }
    }
  }, 1000);
}
function stopTimer() {
  clearInterval(timer.value);
}

function timeToSeconds(time) {
  const [minutes, seconds] = time.split(":");
  return parseInt(minutes) * 60 + parseInt(seconds);
}
const checkTimeOut = (status) => {
  if (status == "done") {
    stopTimer();
    swal.fire({ icon: "error", text: "Waktu kamu sudah habis" });
    router.push({ path: `/tryouts/pilihanGanda/${Result_RTO}/result` });
  }
  if (time.value <= 0) {
    swal.fire({ icon: "error", text: "Waktu kamu sudah habis" });
    // router.push({ path: `/tryouts/kecermatan/${Result_RTO}/result` });
  }
};

function ubahFormatTanggal(tanggal) {
  // Buat objek Date dari string input
  let dateObj = new Date(tanggal);

  // Ambil bagian tanggal, bulan, dan tahun
  let tahun = dateObj.getFullYear();
  let bulan = String(dateObj.getMonth() + 1).padStart(2, "0");
  let tanggalPart = String(dateObj.getDate()).padStart(2, "0");

  // Ambil bagian jam dan menit
  let jam = String(dateObj.getHours()).padStart(2, "0");
  let menit = String(dateObj.getMinutes()).padStart(2, "0");

  // Gabungkan kembali dalam format yang diinginkan
  return `${tahun}-${bulan}-${tanggalPart}T${jam}:${menit}`;
}

let TRYOUT_ID = ref(null);
const authStore = useAuthStore();
const backable = ref(false);
const fetchData = async () => {
  try {
    const response = await axios.get(
      `api/result_tryout_users/${Result_RTO}/edit`
    );
    // if (response.data.result.user_id != user) {
    //     router.push({ path: "/home" });
    //     return; 
    // }
    timoutData.value = ubahFormatTanggal(response.data.result.timeout);
    timeState.value = timoutData.value;
    time.value = dateToTime(ubahFormatTanggal(response.data.result.timestart));
    formattedTime.value = formatTime(time.value);
    checkTimeOut(response.data.result.status);
    startTimer();
    TRYOUT_ID.value = response.data.result.tryout_id;
    const res = await axios.get(
      `/api/tryout/${response.data.result.tryout_id}/questions`
    );
    backable.value = res.data.questions[0].tryout.backable ? true : false;
    data.value = res.data.questions;
    for (let index = 0; index < data.value.length; index++) {
      selected.value.push({
        question_id: data.value[index].id,
        answer_user: null,
        result_tryout_user_id: Result_RTO,
        a: false,
        b: false,
        c: false,
        d: false,
        e: false,
      });
    }
    await getCurrentAnswer(); // Assuming you want to wait for getCurrentAnswer here
  } catch (error) {
    console.error("An error occurred:", error);
  }
};

onBeforeUnmount(() => {
  stopTimer();
});
onMounted(() => {
  fetchData();
});
</script>

<style scoped>
.answer_wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  /* background-color: #409940; */
  max-height: 420px !important;
  overflow-y: scroll;
}

.btn-outline-primary {
  border-radius: 20px;
}

.btn-primary {
  border-radius: 20px;
}
.w-max {
  width: max-content;
}
.answer {
  width: 56px;
  height: 56px;
  border-radius: 20px;
  border: solid 1px;
  border-color: #1365df;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.answer:hover {
  background-color: #1365df;
  color: white;
}

.active {
  background-color: #409940;
  border: none;
  color: white;
}

.active_now {
  background-color: #1365df;
  color: white;
}

.active_mul {
  background-color: #409940;
  color: white;
}

.content {
  padding: 30px;
  border-radius: 30px;
  background: rgb(235, 235, 235);
}
.content_header {
  display: flex;
  justify-content: center;
  width: 100%;
}
.timer {
  background: #409940;
  color: white;
  border-radius: 20px;
  width: max-content;
}
.card_test_kecermatan {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Align content vertically center */
  align-items: center;
  background: #1365df;
  color: white;
}

.card_test_kecermatan__content {
  height: 100%; /* Fill up the height of the card */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Align content vertically center */
  align-items: center;
}

.card_test_kecermatan_lost {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Align content vertically center */
  align-items: center;
  background: white;
  color: black;
  transition: all 0.3s ease;
}

.card_test_kecermatan__content_lost {
  height: 100%; /* Fill up the height of the card */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Align content vertically center */
  align-items: center;
}

.card_test_kecermatan_lost:hover {
  background: #1365df;
  color: white;
}

.card_test_body {
  margin-top: 5px; /* Adjust as needed */
}

.cursor-pointer {
  cursor: pointer;
}
</style>
