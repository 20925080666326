import { createRouter, createWebHistory } from "vue-router";
// user authentication
import ResetPassword from "@/views/Resetpassword/resetpassword.vue";
import TOKercermatan from "@/views/tryouts/kecermatan/index.vue";
import TOCampuran from "@/views/tryouts/campuran/index.vue";
import TOKercermatanResult from "@/views/tryouts/kecermatan/result.vue";
import TOTNI from "@/views/tryouts/tni/index.vue";
import TOTNIResult from "@/views/tryouts/tni/result.vue";
import TypeTNI from "@/views/tryouts/type/tni.vue";
import TOPilihanGandaResult from "@/views/tryouts/pilihanGanda/result.vue";
import TOCampuranResult from "@/views/tryouts/campuran/result.vue";
import TOPilihanGanda from "@/views/tryouts/pilihanGanda/index.vue";
import TOVersi from "@/views/tryouts/versi/index.vue";
import TOType from "@/views/tryouts/type/index.vue";
import TOGroup from "@/views/tryouts/group/index.vue";
import TOCategories from "@/views/tryouts/categories/index.vue";
import AboutUs from "@/views/frontend/pages/Pages/AboutUs/aboutus.vue";
import ChangePassword from "@/views/frontend/pages/Pages/ChangePassword/changepassword.vue";
import ChooseSignup from "@/views/frontend/pages/Pages/Authentication/ChooseSignup/choosesignup.vue";
import ComingSoon from "@/views/frontend/pages/Pages/Comingsoon/comingsoon.vue";
import ContactUs from "@/views/frontend/pages/Pages/ContactUs/contact.vue";
import EmailOtp from "@/views/Emailotp/emailotp.vue";
import ErrorFour from "@/views/frontend/pages/Pages/Errorpages/Error404/error404.vue";
import ErrorFive from "@/views/frontend/pages/Pages/Errorpages/Error500/error500.vue";
import Faq from "@/views/frontend/pages/Pages/Faq/faq.vue";
import Login from "@/views/frontend/pages/Pages/Authentication/Login/login.vue";
import Maintenance from "@/views/frontend/pages/Pages/Maintenance/maintenance.vue";
import PasswordRecovery from "@/views/Passwordupdate/passwordrecovery.vue";
import PaymentSettings from "@/views/frontend/pages/Providers/settings/Paymentsettings/paymentsettings.vue";
import PrivacyPolicy from "@/views/frontend/pages/Pages/Privacypolicy/privacypolicy.vue";
import TermsConditions from "@/views/frontend/pages/Pages/termscondition.vue";
import Refund from "@/views/frontend/pages/Pages/refund.vue";
import UserSignup from "@/views/frontend/pages/Pages/Authentication/ChooseSignup/Usersignup/usersignup.vue";
import IndexEight from "@/views/frontend/pages/Index/index.vue";
import Verification from "@/views/frontend/pages/Providers/settings/profile-verification/verification.vue";
import SessionExpired from "@/views/frontend/pages/Pages/session-expired.vue";

import Type from "@/views/admin/Types/index.vue";
import adminTypeCreate from "@/views/admin/Types/create.vue";
import adminCategories from "@/views/admin/Categories/index.vue";
import adminCategoriesCreate from "@/views/admin/Categories/create.vue";
import adminTypeEdit from "@/views/admin/Types/edit.vue";
import adminCategoriesEdit from "@/views/admin/Categories/edit.vue";
import adminVersi from "@/views/admin/Versi/index.vue";
import adminVersiCreate from "@/views/admin/Versi/create.vue";
import adminVersiEdit from "@/views/admin/Versi/edit.vue";
import adminColumns from "@/views/admin/Columns/index.vue";
import adminColumnsCreate from "@/views/admin/Columns/create.vue";
import adminColumnsEdit from "@/views/admin/Columns/edit.vue";
import tryoutsHistory from "@/views/tryouts/history/index.vue";
import tryoutsVouchers from "@/views/tryouts/vouchers/index.vue";
import tryoutsVouchersHistory from "@/views/tryouts/vouchers/history.vue";
import { useAuthStore } from "@/stores/authStore";
import adminGroups from "@/views/admin/Groups/index.vue";
import adminGroupsCreate from "@/views/admin/Groups/create.vue";
import adminGroupsEdit from "@/views/admin/Groups/edit.vue";
import admintryouts from "@/views/admin/Tryouts/index.vue";
import admintryoutsCreate from "@/views/admin/Tryouts/create.vue";
import admintryoutsEdit from "@/views/admin/Tryouts/edit.vue";
import profile from "@/views/profile/index.vue";
import peringkat from "@/views/tryouts/peringkat/index.vue";
import pembahasanTryout from "@/views/tryouts/pembahasan/index.vue";
import tryoutGabungan from "@/views/tryouts/gabungan/index.vue";
import programBimbel from "@/views/tryouts/bimbel/index.vue";
import adminVouchers from "@/views/admin/Vouchers/index.vue";
import adminVouchersCreate from "@/views/admin/Vouchers/create.vue";
import adminVouchersEdit from "@/views/admin/Vouchers/edit.vue";
import adminCourses from "@/views/admin/Courses/index.vue";
import adminCoursesCreate from "@/views/admin/Courses/create.vue";
import adminCoursesEdit from "@/views/admin/Courses/edit.vue";
import admindatausers from "@/views/admin/DataUsers/index.vue";
import admindataadmin from "@/views/admin/DataAdmin/index.vue";
import admindataadminCreate from "@/views/admin/DataAdmin/create.vue";
import admindataadminEdit from "@/views/admin/DataAdmin/edit.vue";
import adminquestions from "@/views/admin/questions/index.vue";
import adminquestionsCreate from "@/views/admin/questions/create.vue";
import adminquestionsEdit from "@/views/admin/questions/edit.vue";
import admintryoutdiscussions from "@/views/admin/TryoutDiscussion/index.vue";
import admintryoutdiscussionsCreate from "@/views/admin/TryoutDiscussion/create.vue";
import admintryoutdiscussionsEdit from "@/views/admin/TryoutDiscussion/edit.vue";
import adminProfile from "@/views/admin/profile/index.vue";
import adminTransaksi from "@/views/admin/Transaksi/index.vue";
import adminEditTransaksi from "@/views/admin/Transaksi/edit.vue";
import changePassword from "@/views/profile/changepass.vue";
import adminHints from "@/views/admin/Hints/index.vue";
import adminHintsCreate from "@/views/admin/Hints/create.vue";
import adminHintsEdit from "@/views/admin/Hints/edit.vue";
const routes = [
  {
    path: "/admin/types",
    name: "admin-types",
    component: Type,
    meta: {
      requiresAuth: true,
      requireAdmin: true,
    },
  },
  {
    path: "/admin/transaksi/:id/edit",
    name: "/admin/transaksi/:id/edit",
    component: adminEditTransaksi,
    meta: {
      requiresAuth: true,
      requireAdmin: true
    }
  },
  {
    path: "/admin/transaksi",
    name: "/admin/transaksi",
    component: adminTransaksi,
    meta: {
      requiresAuth: true,
      requireAdmin: true,
    },
  },
  {
    path: "/about-us",
    name: "about-us",
    component: AboutUs,
  },
  {
    path: "/change-password",
    name: "change-password",
    component: ChangePassword,
    
  },
  {
    path: "/choose-signup",
    name: "choose-signup",
    component: ChooseSignup,
  },
  {
    path: "/coming-soon",
    name: "coming-soon",
    component: ComingSoon,
  },
  {
    path: "/contact-us",
    name: "contact-us",
    component: ContactUs,
  },
  {
    path: "/contact-us",
    name: "contact-us",
    component: ContactUs,
  },

  {
    path: "/email-verify",
    name: "email-otp",
    component: EmailOtp,
  },
  {
    path: "/error-404",
    name: "error-404",
    component: ErrorFour,
  },
  {
    path: "/error-500",
    name: "error-500",
    component: ErrorFive,
  },
  {
    path: "/faq",
    name: "faq",
    component: Faq,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/maintenance",
    name: "maintenance",
    component: Maintenance,
  },
  {
    path: "/password-recovery",
    name: "password-recovery",
    component: PasswordRecovery,
  },
  {
    path: "/payment-settings",
    name: "payment-settings",
    component: PaymentSettings,
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicy,
  },
 
  {
    path: "/refund",
    name: "refund",
    component: Refund,
  },
  {
    path: "/terms-condition",
    name: "terms-condition",
    component: TermsConditions,
  },
  {
    path: "/register",
    name: "register",
    component: UserSignup,
  },
  // {
  //   path: "/",
  //   name: "index",
  //   component: Login,
  // },
  {
    name: "/",
    path: "/",
    component: TOCategories,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/verification",
    name: "verification",
    component: Verification,
  },
  {
    path: "/session-expired",
    name: "session-expired",
    component: SessionExpired,
  },

  
  {
    path: "/:notFound",
    component: ErrorFour,
  },
  {
    path: "/tryouts/kecermatan/:id",
    name: "tryouts/kecermatan",
    component: TOKercermatan,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/tryouts/tni/type",
    name: "/tryouts/tni/type",
    component: TypeTNI,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/tryouts/tni/:id",
    name: "tryouts/tni",
    component: TOTNI,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/tryouts/tni/:test/result",
    component: TOTNIResult,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/tryouts/pilihanGanda/:id",
    name: "tryouts/pilihanGanda",
    component: TOPilihanGanda,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/tryouts/campuran/:id",
    name: "tryouts/campuran",
    component: TOCampuran,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/tryouts/kecermatan/:test/result",
    component: TOKercermatanResult,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/tryouts/pilihanGanda/:test/result",
    component: TOPilihanGandaResult,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/tryouts/campuran/:test/result",
    component: TOCampuranResult,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: "/tryouts/versi",
    path: "/tryouts/versi",
    component: TOVersi,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: "/tryouts/type",
    path: "/tryouts/type",
    component: TOType,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: "index1",
    path: "/index1",
    component: IndexEight,
  },
  {
    name: "/tryouts/group",
    path: "/tryouts/group",
    component: TOGroup,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: "/home",
    path: "/home",
    component: TOCategories,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: "/admin/categories",
    path: "/admin/categories",
    component: adminCategories,
    meta: {
      requiresAuth: true,
      requireAdmin: true,
    },
  },

  {
    name: "/admin/categories/create",
    path: "/admin/categories/create",
    component: adminCategoriesCreate,
    meta: {
      requiresAuth: true,
      requireAdmin: true,
    },
  },

  {
    name: "/admin/type/create",
    path: "/admin/type/create",
    component: adminTypeCreate,
    meta: {
      requiresAuth: true,
      requireAdmin: true,
    },
  },
  {
    name: "/admin/type/:id/edit",
    path: "/admin/type/:id/edit",
    component: adminTypeEdit,
    meta: {
      requiresAuth: true,
      requireAdmin: true,
    },
  },
  {
    name: "/admin/categories/:id/edit",
    path: "/admin/categories/:id/edit",
    component: adminCategoriesEdit,
    meta: {
      requiresAuth: true,
      requireAdmin: true,
    },
  },
  {
    name: "/admin/versi",
    path: "/admin/versi",
    component: adminVersi,
    meta: {
      requiresAuth: true,
      requireAdmin: true,
    },
  },
  {
    name: "/admin/versi/create",
    path: "/admin/versi/create",
    component: adminVersiCreate,
    meta: {
      requiresAuth: true,
      requireAdmin: true,
    },
  },
  {
    name: "/admin/versi/:id/edit",
    path: "/admin/versi/:id/edit",
    component: adminVersiEdit,
    meta: {
      requiresAuth: true,
      requireAdmin: true,
    },
  },
  {
    name: "/admin/columns",
    path: "/admin/columns",
    component: adminColumns,
    meta: {
      requiresAuth: true,
      requireAdmin: true,
    },
  },
  {
    name: "/admin/columns/create",
    path: "/admin/columns/create",
    component: adminColumnsCreate,
    meta: {
      requiresAuth: true,
      requireAdmin: true,
    },
  },
  {
    name: "/admin/columns/:id/edit",
    path: "/admin/columns/:id/edit",
    component: adminColumnsEdit,
    meta: {
      requiresAuth: true,
      requireAdmin: true,
    },
  },
  {
    name: "/history",
    path: "/history",
    component: tryoutsHistory,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: "/vouchers",
    path: "/vouchers",
    component: tryoutsVouchers,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: "/riwayat-transaksi",
    path: "/riwayat-transaksi",
    component: tryoutsVouchersHistory,
    meta: {
      requiresAuth: true,
    },
  },
  {
    meta: {
      requiresAuth: true,
      requireAdmin: true,
    },
    name: "/admin/groups",
    path: "/admin/groups",
    component: adminGroups,
  },

  {
    meta: {
      requiresAuth: true,
      requireAdmin: true,
    },
    name: "/admin/groups/create",
    path: "/admin/groups/create",
    component: adminGroupsCreate,
  },
  {
    meta: {
      requiresAuth: true,
      requireAdmin: true,
    },
    name: "/admin/groups/:id/edit",
    path: "/admin/groups/:id/edit",
    component: adminGroupsEdit,
  },
  {
    meta: {
      requiresAuth: true,
      requireAdmin: true,
    },
    name: "/admin/tryouts",
    path: "/admin/tryouts",
    component: admintryouts,
  },
  {
    meta: {
      requiresAuth: true,
      requireAdmin: true,
    },
    name: "/admin/tryouts/create",
    path: "/admin/tryouts/create",
    component: admintryoutsCreate,
  },
  {
    meta: {
      requiresAuth: true,
      requireAdmin: true,
    },
    name: "/admin/tryouts/:id/edit",
    path: "/admin/tryouts/:id/edit",
    component: admintryoutsEdit,
  },
  {
    meta: {
      requiresAuth: true,
    },
    name: "/profile",
    path: "/profile",
    component: profile,
  },
  {
    meta: {
      requiresAuth: true,
    },
    name: "/peringkat",
    path: "/peringkat",
    component: peringkat,
  },
  {
    meta: {
      requiresAuth: true,
    },
    name: "/pembahasan-tryout",
    path: "/pembahasan-tryout",
    component: pembahasanTryout,
  },
  {
    meta: {
      requiresAuth: true,
    },
    name: "/tryout-gabungan",
    path: "/tryout-gabungan",
    component: tryoutGabungan,
  },
  {
    meta: {
      requiresAuth: true,
    },
    name: "/program-bimbel",
    path: "/program-bimbel",
    component: programBimbel,
  },
  {
    meta: {
      requiresAuth: true,
      requireAdmin: true,
    },
    name: "/admin/vouchers",
    path: "/admin/vouchers",
    component: adminVouchers,
  },
  {
    meta: {
      requiresAuth: true,
      requireAdmin: true,
    },
    name: "/admin/vouchers/create",
    path: "/admin/vouchers/create",
    component: adminVouchersCreate,
  },
  {
    meta: {
      requiresAuth: true,
      requireAdmin: true,
    },
    name: "/admin/vouchers/:id/edit",
    path: "/admin/vouchers/:id/edit",
    component: adminVouchersEdit,
  },
  {
    meta: {
      requiresAuth: true,
      requireAdmin: true,
    },
    name: "/admin/courses",
    path: "/admin/courses",
    component: adminCourses,
  },
  {
    meta: {
      requiresAuth: true,
      requireAdmin: true,
    },
    name: "/admin/courses/create",
    path: "/admin/courses/create",
    component: adminCoursesCreate,
  },
  {
    meta: {
      requiresAuth: true,
      requireAdmin: true,
    },
    name: "/admin/courses/:id/edit",
    path: "/admin/courses/:id/edit",
    component: adminCoursesEdit,
  },
  {
    meta: {
      requiresAuth: true,
      requireAdmin: true,
    },
    name: "/admin/datausers",
    path: "/admin/datausers",
    component: admindatausers,
  },
  {
    meta: {
      requiresAuth: true,
      requireAdmin: true,
    },
    name: "/admin/dataadmin",
    path: "/admin/dataadmin",
    component: admindataadmin,
  },
  {
    meta: {
      requiresAuth: true,
      requireAdmin: true,
    },
    name: "/admin/dataadmin/create",
    path: "/admin/dataadmin/create",
    component: admindataadminCreate,
  },
  {
    meta: {
      requiresAuth: true,
      requireAdmin: true,
    },
    name: "/admin/dataadmin/:id/edit",
    path: "/admin/dataadmin/:id/edit",
    component: admindataadminEdit,
  },
  {
    meta: {
      requiresAuth: true,
      requireAdmin: true,
    },
    name: "/admin/questions/tryouts/:id",
    path: "/admin/questions/tryouts/:id",
    component: adminquestions,
  },
  {
    meta: {
      requiresAuth: true,
      requireAdmin: true,
    },
    name: "/admin/questions/tryouts/:id/create",
    path: "/admin/questions/tryouts/:id/create",
    component: adminquestionsCreate,
  },
  {
    meta: {
      requiresAuth: true,
      requireAdmin: true,
    },
    name: "/admin/questions/tryouts/:id/edit/:question",
    path: "/admin/questions/tryouts/:id/edit/:question",
    component: adminquestionsEdit,
  },
  {
    meta: {
      requiresAuth: true,
      requireAdmin: true,
    },
    name: "/admin/TryoutDiscussion",
    path: "/admin/TryoutDiscussion",
    component: admintryoutdiscussions,
  },
  {
    meta: {
      requiresAuth: true,
      requireAdmin: true,
    },
    name: "/admin/TryoutDiscussion/create",
    path: "/admin/TryoutDiscussion/create",
    component: admintryoutdiscussionsCreate,
  },
  {
    meta: {
      requiresAuth: true,
      requireAdmin: true,
    },
    name: "/admin/TryoutDiscussion/:id/edit",
    path: "/admin/TryoutDiscussion/:id/edit",
    component: admintryoutdiscussionsEdit,
  },
  {
    meta: {
      requiresAuth: true,
      requireAdmin: true,
    },
    name: "/admin/profile",
    path: "/admin/profile",
    component: adminProfile,
  },
  {
    meta: {
      requiresAuth: true,
      requireAdmin: false,
    },
    path: '/views/profile/:id/changepass',
    name: '/views/profile/:id/changepass',
    component: changePassword,
  },
  {
    path: "/reset-password",
    name: "reset-passeord",
    component: ResetPassword,
  },
  {
    name: "/admin/hints",
    path: "/admin/hints",
    component: adminHints,
    meta: {
      requiresAuth: true,
      requireAdmin: true,
    },
  },
  {
    name: "/admin/hints/create",
    path: "/admin/hints/create",
    component: adminHintsCreate,
    meta: {
      requiresAuth: true,
      requireAdmin: true,
    },
  },
  {
    name: "/admin/hints/:id/edit",
    path: "/admin/hints/:id/edit",
    component: adminHintsEdit,
    meta: {
      requiresAuth: true,
      requireAdmin: true,
    },
  },
];

export const router = createRouter({
  history: createWebHistory("/"),
  linkActiveClass: "active",
  routes,
});

router.beforeEach((to, from, next) => {
  window.scrollTo({ top: 0, behavior: "smooth" });
  const isAuthenticated = useAuthStore().isLoggedIn;

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (isAuthenticated) {
      if (to.matched.some((record) => record.meta.requireAdmin)) {
        if (useAuthStore().user.role === "admin") {
          next();
        } else {
          next("/home");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      } else {
        next();
      }
    } else {
      next("/login");
    }
  } else {
    if (isAuthenticated) {
      if (useAuthStore().user.role === "admin") {
        next("/admin/categories");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        next("/home");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } else {
      next();
    }
  }
});
