<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>
    <!-- Content -->
    <div class="page-wrapper">
      <div class="content">
        <b-form @submit.prevent="submitForm">
          <div class="row">
            <div class="col-lg-7 col-sm-12 m-auto">
              <div class="content-page-header">
                <h5 class="mb-2">Add Groups</h5>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Name</label>
                    <input
                      v-model="data.name"
                      type="text"
                      class="form-control"
                    />
                    <div v-if="errors.name" class="text-danger">
                      {{ errors.name[0] }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Type</label>
                    <vue-select
                      v-model="data.type_id"
                      :options="type"
                      placeholder="Type"
                    />
                    <div v-if="errors.type_id" class="text-danger">
                      {{ errors.type_id[0] }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="btn-path">
                    <router-link to="/admin/groups" class="btn btn-cancel me-3"
                      >Cancel</router-link
                    >
                    <b-button type="submit" variant="primary"
                      >Add Group</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import swal from "sweetalert2";
import axios from "axios";
import { useLoading } from "vue-loading-overlay";
import { useRouter } from "vue-router";

let errors = ref({});
const $loading = useLoading({});
const router = useRouter();

let type = ref();
let data = ref({
  type: null,
});

const submitForm = async () => {
  const loader = $loading.show({});
  const formData = new FormData();
  if (data.value.type_id) {
    formData.append("type_id", data.value.type_id);
  }
  if (data.value.name) {
    formData.append("name", data.value.name);
  }
  try {
    const response = await axios.post("api/groups", formData);
    loader.hide();
    swal.fire({ icon: "success", text: response.data.message });
    router.push("/admin/groups");
  } catch (error) {
    loader.hide();
    console.error("Error response:", error.response);
    if (error.response && error.response.data) {
      console.error("Error data:", error.response.data);
      errors.value = error.response.data;
    } else {
      console.error("Unknown error:", error);
    }
  }
};
function transformArray(data) {
  return data.map((item) => {
    return {
      id: item.id,
      text: item.name,
    };
  });
}

const fetchData = async () => {
  try {
    const response = await axios.get("api/types");
    type.value = response.data.types;
    type.value = transformArray(type.value);
    type.value.unshift({ id: null, text: "Pilih Type" });
  } catch (error) {
    console.error("Error while fetching types:", error);
  }
};
onMounted(() => {
  fetchData();
});
</script>
