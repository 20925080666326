<template>
  <div class="row">
    <div class="col-12">
      <div class="tab-sets">
        <div class="tab-contents-sets">
          <ul>
            <li>
              <router-link to="/admin/services">All Services</router-link>
            </li>

            <li>
              <router-link to="/admin/active-services">Active</router-link>
            </li>

            <li>
              <router-link to="/admin/pending-services">Pending</router-link>
            </li>

            <li>
              <router-link to="/admin/inactive-services">Inactive</router-link>
            </li>

            <li>
              <router-link to="/admin/deleted-services">Deleted</router-link>
            </li>
          </ul>
        </div>

        <div class="tab-contents-count">
          <h6>Showing 8-10 of 84 results</h6>
        </div>
      </div>
    </div>
  </div>
</template>
