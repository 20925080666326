<template>
  <div class="provider-body">
    <div class="main-wrapper">
      <dashboardnavbar />

      <div class="page-wrapper">
        <sidebar />
        <div class="container">
          <div class="content p-5">
            <h5 class="my-4">Program Bimbel</h5>
            <div class="row">
              <div
                class="col-md-6 col-lg-4 d-flex"
                v-for="(item, index) in data"
              >
                <CardBimbel
                  :key="index"
                  :title="item.title"
                  :image="item.url"
                  :desc="item.description"
                  :price="item.voucher.amount"
                  badge="Popular"
                  label="Beli Sekarang"
                  @button-clicked="handleButtonClick(item.voucher.id)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useAuthStore } from "@/stores/authStore";
import CardBimbel from "./card/index.vue";
import axios from "axios";
import { onMounted, ref } from "vue";
import swal from "sweetalert2";
import { usePaymentStore } from "@/stores/paymentStore";
import { useRouter } from "vue-router";
const router = useRouter();
const store = usePaymentStore();
let data = ref([]);
const fetchData = async () => {
  try {
    const res = await axios.get("/api/courses");
    data.value = res.data.courses;
    console.log(data.value);
  } catch (err) {
    console.error("Error while fetching group data:", err);
    // Tindakan lain yang sesuai untuk menangani kesalahan
  }
};

const handleButtonClick = async (id_voucher) => {
  try {
    const payment = {
      payment_details: [
        {
          voucher_id: id_voucher,
          qty: 1,
        },
      ],
    };
    const { data } = await store.payment(payment);
    if (data.status === 201) {
      window.snap.pay(data.snap_token, {
        onSuccess: async (result) => {
          try {
            const res = await store.callbackPayment(result);
            swal.fire({
              icon: "success",
              text: "Pembelian Berhasil !!",
              html: `Selikan Melakukan Konfirmasi Melalui <br> <br> <a class="btn btn-outline-primary" href="https://wa.me/085721347799?tekt=Hai,%20saya%20mau%20bertanya">Wa Support</a>`,
            });

            router.push("riwayat-transaksi");
          } catch (error) {
            console.error("Error while processing payment callback:", error);
          }
        },
        onPending: async (result) => {
          try {
            await store.callbackPayment(result);
            router.push("riwayat-transaksi");
          } catch (error) {
            console.error("Error while processing payment callback:", error);
          }
        },
        onError: async (result) => {
          try {
            await store.callbackPayment(result);
            swal.fire({
              icon: "success",
              text: "Pembelian Gagal !!",
            });
          } catch (error) {
            console.error("Error while processing payment callback:", error);
          }
        },
        onClose: async () => {
          try {
            await store.deletePayment(data.data.id);
          } catch (error) {
            console.error("Error while deleting payment:", error);
          }
        },
      });
    }
  } catch (error) {
    console.log(error);
  }
};

const generateSnap = () => {
  let recaptchaScript = document.createElement("script");
  recaptchaScript.setAttribute("src", process.env.VUE_APP_MIDTRANS_SNAP_URL);
  recaptchaScript.setAttribute(
    "data-client-key",
    process.env.VUE_APP_MIDTRANS_CLIENT_KEY
  );
  document.head.appendChild(recaptchaScript);
};

onMounted(() => {
  fetchData();
  generateSnap();
});
</script>

<style scoped>
details[open] summary ~ * {
  animation: sweep 0.2s ease-in-out;
}

details[close] summary ~ * {
  animation: sweep 0.2s ease-in-out;
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-left: -10px;
  }
  100% {
    opacity: 1;
    margin-left: 0px;
  }
}

.tryouts__group {
  padding: 20px;
  border-radius: 10px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.tryouts__group_content {
  margin-top: 20px;
  border-radius: 20px;
}

.tryouts__group_content_list {
  cursor: pointer;
  color: black;
  padding: 10px 30px 10px 30px;
  border-top: 1px solid #22222b44;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: whitesmoke;
}

.tryouts__group_content_list:hover {
  background: #1365df;
  color: white;
}

.tryout__group_content_list_access_content {
  width: 100px;
  border-radius: 5px;
  text-align: center;
  padding: 10px;
}

.free {
  background: #ed7f409c;
  color: white;
}

.premium {
  background: #40994091;
  color: white;
}

.vip {
  background: #1365df;
  color: white;
}
</style>
