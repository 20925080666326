<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <div class="content">
        <div class="content-page-header px-3 content-page-headersplit mb-0">
          <h5>Versi</h5>
          <div class="list-btn">
            <router-link to="/admin/versi/create" class="btn btn-primary">
              <i class="fa fa-plus me-2"></i>Add Versi
            </router-link>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-resposnive table-div">
              <div class="table datatable">
                <a-table :columns="columns" :data-source="data">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.title === 'Image'">
                      <img class="img img-fluid" :src="record.image" alt="" />
                    </template>
                    <template v-if="column.title === 'Icon'">
                      <img class="img img-fluid" :src="record.icon" alt="" />
                    </template>
                    <template v-if="column.title === 'Action'">
                      <div class="d-flex flex justify-content-center gap-2">
                        <router-link
                          class="btn delete-table me-2"
                          :to="`/admin/versi/${record.id}/edit`"
                        >
                          <i class="feather feather-edit"></i>
                        </router-link>
                        <button
                          @click="onDelete(record.id, record.image, record.icon)"
                          class="btn delete-table"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#delete"
                        >
                          <i class="feather feather-trash-2"></i>
                        </button>
                      </div>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Confirm
    message="Are you sure you want to delete this versi?"
    title="Delete"
    buttonText="delete"
    id="delete"
    :url="URL"
    :imageURL="imgFirebaseDel"
    :iconURL="iconFirebaseDel"
    @submitted="fetchData()"
  />
</template>

<script setup>
import { reactive, computed, ref } from "vue";
import axios from "axios";
import Confirm from "@/components/model/Confirm/index.vue";
const API = process.env.VUE_APP_API_URL;
const URL = ref(null);
const ID = ref();
const BASEURL = ref(process.env.VUE_APP_API_URL + "/api/versi/");
const imgFirebaseDel = ref(null);
const iconFirebaseDel = ref(null);
const onDelete = (id, img, icon) => {
  ID.value = id;
  imgFirebaseDel.value = img;
  iconFirebaseDel.value = icon;
  URL.value = BASEURL.value + ID.value;
};
const columns = reactive([
{
    title: "No",
    dataIndex: "index",
    sorter: {
      compare: (a, b) => a.index - b.index,
    },
  },
  {
    title: "Image",
    dataIndex: "image",
    sorter: {
      compare: (a, b) => {
        a = a.image.toLowerCase();
        b = b.image.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Icon",
    dataIndex: "icon",
    sorter: {
      compare: (a, b) => {
        a = a.image.toLowerCase();
        b = b.image.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    sorter: {
      compare: (a, b) => {
        a = a.name.toLowerCase();
        b = b.name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Description",
    dataIndex: "description",
    sorter: {
      compare: (a, b) => {
        a = a.image.toLowerCase();
        b = b.image.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Category",
    dataIndex: "category",
    sorter: {
      compare: (a, b) => {
        a = a.category.toLowerCase();
        b = b.category.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "id",
    sorter: true,
    class: "text-center",
  },
]);

const data = ref([]);

const fetchData = async () => {
  try {
    const response = await axios.get("/api/versi");
    data.value = response.data.versi;
    data.value = data.value.map((category,index) => ({
      ...category,
      category: category.category.name,
      index: index + 1,
    }));
    console.log(data.value);
  } catch (error) {
    console.error("Error fetching versi data:", error);
  }
};

fetchData();
</script>

<style>
.img {
  width: 140px;
  height: 140px;
  object-fit: cover;
}
</style>
