<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <div class="content">
        <div class="content-page-header px-3 content-page-headersplit mb-0">
          <h5>Hints</h5>
          <div class="list-btn">
            <router-link to="/admin/hints/create" class="btn btn-primary">
              <i class="fa fa-plus me-2"></i>Add Hints
            </router-link>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-resposnive table-div">
              <div class="table datatable">
                <a-table :columns="columns" :data-source="data">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.title === 'Image'">
                      <img class="img img-fluid" :src="record.image" alt="" />
                    </template>
                    <template v-if="column.title === 'Description'">
                      <span v-if="record.description.length > 50">
                        <div
                          v-html="record.description.substring(0, 50) + '...'"
                        ></div>
                      </span>
                      <span v-else>
                        <div v-html="record.description"></div>
                      </span>
                    </template>
                    <template v-if="column.title === 'Action'">
                      <div class="d-flex flex justify-content-center gap-2">
                        <router-link
                          class="btn delete-table me-2"
                          :to="`/admin/hints/${record.id}/edit`"
                        >
                          <i class="feather feather-edit"></i>
                        </router-link>
                        <button
                          @click="onDelete(record.id)"
                          class="btn delete-table"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#delete"
                        >
                          <i class="feather feather-trash-2"></i>
                        </button>
                      </div>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Confirm
    message="Are you sure you want to delete this column?"
    title="Delete"
    buttonText="delete"
    id="delete"
    :url="URL"
    @submitted="fetchData()"
  />
</template>

<script setup>
import { reactive, computed, ref } from "vue";
import axios from "axios";
import Confirm from "@/components/model/Confirm/index.vue";
const URL = ref(null);
const ID = ref();
const BASEURL = ref(process.env.VUE_APP_API_URL + "/api/hints/");
const onDelete = (id) => {
  ID.value = id;
  URL.value = BASEURL.value + ID.value;
};
const columns = reactive([
  {
    title: "No",
    dataIndex: "index",
    sorter: {
      compare: (a, b) => a.index - b.index,
    },
  },
  {
    title: "Image",
    dataIndex: "image",
    sorter: {
      compare: (a, b) => {
        a = a.image.toLowerCase();
        b = b.image.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    sorter: {
      compare: (a, b) => {
        a = a.description.toLowerCase();
        b = b.description.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Time",
    dataIndex: "time",
    sorter: {
      compare: (a, b) => {
        a = a.time.toLowerCase();
        b = b.time.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Column",
    dataIndex: "column",
    sorter: {
      compare: (a, b) => {
        a = a.column.toLowerCase();
        b = b.column.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "id",
    sorter: true,
    class: "text-center",
  },
]);

const data = ref([]);

const fetchData = async () => {
  try {
    const response = await axios.get("/api/hints");
    data.value = response.data.hints.map((hint, index) => ({
      ...hint,
      column: hint.column ? `${hint.column.name}` : "",
      index: index + 1,
    }));
  } catch (error) {
    console.error("Error fetching hints:", error);
    // Handle error here, misalnya menampilkan pesan kesalahan kepada pengguna
  }
};

fetchData();
</script>

<style>
.img {
  width: 140px;
  height: 140px;
  object-fit: cover;
}
</style>
