<template>
  <div class="modal fade" :id="props.id">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ props.title }}</h5>

          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>

        <div class="modal-body pt-0">
          <p class="text-muted mb-0">{{ props.message }}</p>

          <div class="d-flex gap-2 justify-content-end mt-4">
            <button
              id="close"
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <form @submit.prevent="onSumbit()">
              <button type="submit" class="btn btn-primary">
                {{ props.buttonText }}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits } from "vue";
import axios from "axios";
import swal from "sweetalert2";
import { useLoading } from "vue-loading-overlay";
let errors = ref({});
import { deleteFileByUrl } from "@/utils/fileUpload";
const emit = defineEmits(["submitted"]);
const $loading = useLoading({});
const props = defineProps({
  id: { type: String, required: true },
  message: { type: String, required: true },
  title: { type: String, required: true },
  buttonText: { type: String, required: true },
  url: { type: String, required: true },
  imageURL: { type: String, required: true },
  iconURL: { type: String, required: true },
});

const deleteFile = async () => {
  const loader = $loading.show({});
  if (props.imageURL) {
    await deleteFileByUrl(props.imageURL);
  }
  if (props.iconURL) {
    await deleteFileByUrl(props.iconURL);
  }
  loader.hide();
};

const closeModal = () => {
  document.getElementById("close").click();
};

const onSumbit = () => {
  const loader = $loading.show({});
  axios
    .delete(props.url)
    .then((res) => {
      loader.hide();
      console.log(res);
      closeModal();
      deleteFile();
      emit("submitted");
    })
    .catch((err) => {
      loader.hide();
      console.log(err);
    });
};
</script>
