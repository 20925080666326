<template>
    <div class="main-wrapper">
      <layout></layout>
      <adminsidebar></adminsidebar>
  
      <div class="page-wrapper">
        <div class="content">
          <b-form @submit.prevent="submitForm">
            <div class="row">
              <div class="col-lg-7 col-sm-12 m-auto">
                <div class="content-page-header">
                  <h5 class="mb-2">Add Pembahasan Tryout</h5>
                  <!-- <div class="form-group mb-0">
                        <p class="contentpage">You are editing "English" version</p>
                      </div> -->
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label>Title</label>
                      <input
                        v-model="data.title"
                        type="text"
                        class="form-control"
                      />
                      <div v-if="errors.title" class="text-danger">
                        {{ errors.title[0] }}
                      </div>
                    </div>
                  </div>
  
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label>URL</label>
                      <input
                        v-model="data.url"
                        type="text"
                        class="form-control"
                      />
                      <div v-if="errors.url" class="text-danger">
                        {{ errors.url[0] }}
                      </div>
                    </div>
                  </div>
  
                  <div class="col-lg-12">
                    <div class="btn-path">
                      <router-link to="/admin/TryoutDiscussion" class="btn btn-cancel me-3"
                        >Cancel</router-link
                      >
                      <b-button type="submit" variant="primary">
                        Add Pembahasan Tryout</b-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref } from "vue";
  import swal from "sweetalert2";
  import axios from "axios";
  
  import { useLoading } from "vue-loading-overlay";
  import { useRouter } from "vue-router";
  let errors = ref({});
  const $loading = useLoading({});
  const router = useRouter();
  
  let data = ref({});
  
  const submitForm = async () => {
    const loader = $loading.show({});
    const formData = new FormData();
    if (data.value.title) {
      formData.append("title", data.value.title);
    }
    if (data.value.url) {
      formData.append("url", data.value.url);
    }
    try {
      const response = await axios.post("api/tryout_discussions", formData);
      loader.hide();
      swal.fire({ icon: "success", text: response.data.message });
      router.push("/admin/TryoutDiscussion");
    } catch (error) {
      loader.hide();
      errors.value = error.response.data;
    }
  };
  </script>
  