<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper">
      <div class="content">
        <b-form @submit.prevent="updateForm" enctype="multipart/form-data">
          <div class="row">
            <div class="col-lg-7 col-sm-12 m-auto">
              <div class="content-page-header">
                <h5 class="mb-2">Edit Admin</h5>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Name</label>
                    <input
                      v-model="data.name"
                      type="text"
                      class="form-control"
                    />
                    <div v-if="errors.name" class="text-danger">
                      {{ errors.name[0] }}
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Email</label>
                    <input
                      v-model="data.email"
                      type="text"
                      class="form-control"
                    />
                    <div v-if="errors.email" class="text-danger">
                      {{ errors.email[0] }}
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Phone Number</label>
                    <input
                      v-model="data.phone_number"
                      type="text"
                      class="form-control"
                    />
                    <div v-if="errors.phone_number" class="text-danger">
                      {{ errors.phone_number[0] }}
                    </div>
                  </div>
                </div>

                <!-- Password fields (conditional) -->
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Old Password (Opsional)</label>
                    <input
                      v-model="data.old_password"
                      type="password"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <label>New Password (Opsional)</label>
                    <input
                      v-model="data.new_password"
                      type="password"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <label>Confirm New Password (Opsional)</label>
                    <input
                      v-model="data.password_confirmation"
                      type="password"
                      class="form-control"
                    />
                  </div>
                </div>

                <!-- Submit Button -->
                <div class="col-lg-12 mt-3">
                  <div class="btn-path">
                    <router-link
                      to="/admin/dataadmin"
                      class="btn btn-cancel me-3"
                      >Cancel</router-link
                    >
                    <b-button type="submit" variant="primary"
                      >Edit Admin</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import swal from "sweetalert2";
import axios from "axios";
import { useLoading } from "vue-loading-overlay";
import { useRoute, useRouter } from "vue-router";

let errors = ref({});
const $loading = useLoading({});
const router = useRouter();
const route = useRoute();
let data = ref({});

const updateForm = async () => {
  const loader = $loading.show({});
  try {
    const formData = {
      name: data.value.name,
      email: data.value.email,
      phone_number: data.value.phone_number,
    };

    if (
      data.value.old_password &&
      data.value.new_password &&
      data.value.password_confirmation
    ) {
      formData.old_password = data.value.old_password;
      formData.new_password = data.value.new_password;
      formData.password_confirmation = data.value.password_confirmation;
    }

    const response = await axios.put(
      `api/users/${route.params.id}/updateProfile`,
      formData
    );
    loader.hide();
    swal.fire({ icon: "success", text: response.data.message });
    router.push("/admin/dataadmin");
  } catch (error) {
    loader.hide();
    errors.value = error.response.data;
  }
};

const fetchData = async () => {
  try {
    const response = await axios.get(`api/users/${route.params.id}/edit`);
    data.value = response.data.user;
  } catch (error) {
    errors.value = error.response.data;
  }
};

onMounted(() => {
  fetchData();
});
</script>
