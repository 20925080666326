<template>
  <div class="provider-body">
    <div class="main-wrapper">
      <dashboardnavbar />

      <div class="page-wrapper">
        <sidebar />

        <div class="content">
          <div class="container-fluid">
            <nav
              class="navbar px-3 d-flex align-items-center navbar-light bg-light"
            >
              <h6 class="text-center">Riwayat Transaksi</h6>
              <div class="d-flex">
                <input
                  v-model="searchTerm"
                  class="form-control mr-sm-2"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                />
                <b-button
                  @click="filterPayments"
                  class="btn btn-outline-success my-2 my-sm-0"
                  type="submit"
                >
                  Search
                </b-button>
              </div>
            </nav>

            <section class="table-responsive mt-3">
              <table class="table mb-0 custom-table">
                <thead class="thead-light">
                  <tr>
                    <th>#</th>
                    <th>No Transaksi</th>
                    <th>Total Day</th>
                    <th>Amount</th>
                    <th>Payment Method</th>
                    <th>Payment Channel</th>
                    <th>Virtual Account</th>
                    <!-- <th>Updated At</th> -->
                    <th>Status</th>
                    <td></td>
                    <th>Created At</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in filteredPayments">
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.no_transaction }}</td>
                    <td>{{ item.total_day }}</td>
                    <td class="text-light-success">
                      Rp. {{ item.total_amount.toLocaleString('en-US') }}
                    </td>
                    <td class="text-body">{{ item.payment_method }}</td>
                    <td class="text-body">{{ item.payment_channel }}</td>
                    <td class="text-body">{{ item.note }}</td>
                    <!-- <td class="text-body">
                      {{ formatDateTime(item.updated_at) }}
                    </td> -->
                    <td>
                      <span
                        :class="
                          item.status == 'success'
                            ? 'badge-success'
                            : 'badge-danger'
                        "
                        >{{ item.status }}</span
                      >
                    </td>
                    <td>
                      <b-button v-if="item.status == 'pending'" @click="onSubmit(item.no_transaction)"
                        >Check Status</b-button
                      >
                    </td>
                    <td class="text-body">
                      {{ formatDateTime(item.created_at) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import axios from "axios";
import { useAuthStore } from "@/stores/authStore";
let payments = ref([]);
const authStore = useAuthStore();
// Function to filter data based on category id
const fetchData = async () => {
  try {
    const res = await axios.get(`/api/payments`);
    payments.value = res.data.payments;
    console.log(payments.value);
    filterPayments();
  } catch (error) {
    console.error("Error while fetching payments data:", error);
    // Tindakan lain yang sesuai untuk menangani kesalahan
  }
};

let filteredPayments = ref([]);
let searchTerm = ref("");

// Function to filter payments based on transaction number
const filterPayments = () => {
  filteredPayments.value = payments.value.filter((payment) =>
    payment.no_transaction.includes(searchTerm.value)
  );
};

function formatDateTime(dateTimeString) {
  // const dateTime = new Date(dateTimeString);

  // const dateOptions = { year: "numeric", month: "long", day: "numeric" };
  // const timeOptions = { hour: "numeric", minute: "numeric", second: "numeric" };

  // const formattedDate = dateTime.toLocaleDateString("en-US", dateOptions);
  // const formattedTime = dateTime.toLocaleTimeString("en-US", timeOptions);

  // return `${formattedDate} - ${formattedTime}`;

  // Convert the string to a Date object
  var dateObject = new Date(dateTimeString);

  // Format the date and time separately
  var formattedDate = dateObject.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
  var formattedTime = dateObject.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });

  // Concatenate formatted date and time
  return formattedDate + ' ' + formattedTime;
}

const onSubmit = async (id) => {
  try {
    const res = await axios.get(`/api/payments/check-status/${id}`);
    try {
      const response = await authStore.me();
      try {
        authStore.setUser(response.data);
      } catch (error) {
        // Handle any errors that might occur when setting the user
        console.error("Error setting user:", error);
      }
    } catch (error) {
      console.error("Error while checking payment status:", error);
      // Tindakan lain yang sesuai untuk menangani kesalahan
    }
    fetchData();
  } catch (error) {
    console.error("Error while checking payment status:", error);
    // Tindakan lain yang sesuai untuk menangani kesalahan
  }
};
onMounted(() => {
  fetchData();
});
</script>

<style scoped>
.card_info {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
}

.card_info img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(100%);
  transition: all 0.3s ease-in-out;
}

.card_info::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(76, 64, 237, 0.349);
}

.card_info:hover img {
  filter: grayscale(0);
}

.card_info__content {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Align content vertically center */
  align-items: center;
  text-align: center;
  padding: 20px;
}

.card_info__content h5 {
  color: white;
  font-weight: bold;
  font-size: 2rem;
}
</style>
