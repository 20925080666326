<template>
  <div class="provider-body">
    <div class="main-wrapper">
      <dashboardnavbar />

      <div class="page-wrapper">
        <sidebar />
        <div class="container p-3">
          <div class="row gap-2">
            <div class="col-lg-8">
              <div
                class="table-resposnive mt-5"
                v-if="FINAL_SCORE_DETAIL.detail"
              >
                <h3>Hasil Tryouts</h3>

                <div class="table-div mt-3" style="overflow-x: auto">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>Jawaban Benar</th>
                        <th>Jawaban Salah</th>
                        <th>Tidak Terjawab</th>
                        <th>Score</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="FINAL_SCORE_DETAIL.detail['total_ketahanan']">
                        <td>{{ FINAL_SCORE_DETAIL["true_answer"] ? FINAL_SCORE_DETAIL["true_answer"].toFixed(0) : 0 }}</td>
                        <td>{{ FINAL_SCORE_DETAIL["false_answer"] ? FINAL_SCORE_DETAIL["false_answer"].toFixed(0) : 0 }}</td>
                        <td>{{ FINAL_SCORE_DETAIL["not_answer"] ? FINAL_SCORE_DETAIL["not_answer"].toFixed(0) : 0 }}</td>
                        <td><strong>{{ FINAL_SCORE_DETAIL["final_score"] ? FINAL_SCORE_DETAIL["final_score"].toFixed(0) : 0 }}</strong></td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="table-div mt-3" style="overflow-x: auto">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>Aspek Penilaian</th>
                        <th>Kategori</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="FINAL_SCORE_DETAIL.detail['total_ketahanan']">
                        <td>Ketahanan</td>
                        <td>
                          {{
                            FINAL_SCORE_DETAIL.detail["total_ketahanan"]
                              .kategori
                          }}
                        </td>
                      </tr>
                      <tr v-else>
                        <td colspan="4">Loading...</td>
                      </tr>
                      <tr v-if="FINAL_SCORE_DETAIL.detail['total_kecepatan']">
                        <td>Kecepatan</td>
                        <td>
                          {{
                            FINAL_SCORE_DETAIL.detail["total_kecepatan"]
                              .kategori
                          }}
                        </td>
                      </tr>
                      <tr v-else>
                        <td colspan="4">Loading...</td>
                      </tr>
                      <tr v-if="FINAL_SCORE_DETAIL.detail['total_ketelitian']">
                        <td>Ketelitian</td>
                        <td>
                          {{
                            FINAL_SCORE_DETAIL.detail["total_ketelitian"]
                              .kategori
                          }}
                        </td>
                      </tr>
                      <tr v-else>
                        <td colspan="4">Loading...</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <br />

              </div>
            </div>

            <div class="col-lg-3 table-resposnive">
              <h3 class="mt-5">Leaderboard</h3>
              <div class="leadearboard">
                <ul>
                  <li
                    class="leadearboard__wrapper"
                    v-for="(item, index) in Leaderboard"
                  >
                    <div class="leadearboard__name">
                      {{ index + 1 }}. Nama : {{ item.user.name }}
                    </div>
                    <div class="leadearboard__score">
                      Final Score :
                      <span v-if="item.final_score">{{
                        item.final_score
                      }}</span>
                      <span v-else>-</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-8">
              <!-- Grafik -->
              <h3>Grafik Ketahanan</h3>
              <div class="chart table-resposnive">
                <apexchart
                  type="area"
                  :options="chartOptions"
                  :series="chartData"
                ></apexchart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref } from "vue";
import { useKecermatanStore } from "@/stores/kecermatanStore";
import axios from "axios";
import { useRoute, useRouter } from "vue-router";
import { useAuthStore } from "@/stores/authStore";
const route = useRoute();
const Result_RTO = route.params.test;
const kecermatanStore = useKecermatanStore();
let FINAL_SCORE_DETAIL = ref({});
const chartOptions = {
  chart: {
    type: "bar",
    height: 350,
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "55%",
      endingShape: "rounded",
    },
  },
  dataLabels: {
    enabled: true,
  },
  xaxis: {
    categories: [], // Kolom nama
  },
  yaxis: {
    title: {
      text: "Jumlah Pertanyaan Terjawab",
    },
  },
  tooltip: {
    y: {
      formatter: function (val) {
        return val;
      },
    },
  },
};
const chartData = ref([{ name: "Total Pertanyaan Dikerjakan", data: [] }]);
let dataKetahanan = ref([]);
const setData = () => {
  const data = dataKetahanan.value;

  data.forEach((item) => {
    chartOptions.xaxis.categories.push(item.column_name);
    chartData.value[0].data.push(item.answered_count);
  });
};
let tryout_id = ref(null);
let Leaderboard = ref([]);

function sortByRank(rankings) {
  // Mengubah objek menjadi array agar dapat diurutkan
  const rankingsArray = Object.values(rankings);
  rankingsArray.sort((a, b) => {
    return a.rank - b.rank;
  });

  return rankingsArray;
}
const authStore = useAuthStore();
const user = authStore.user.id;
const router = useRouter();
const fetchData = async () => {
  try {
    kecermatanStore.clearState();
    const response = await axios.get(`/api/tryouts/${Result_RTO}/results`);
    // if (response.data.result_tryout_user.user_id != user) {
    //   router.push({ path: "/home" });
    //   return;
    // }
    FINAL_SCORE_DETAIL.value = response.data.finalscore;
    dataKetahanan.value = response.data.graph_data;
    if (response.data.question_answer_user[0]) {
      try {
        tryout_id.value = response.data.question_answer_user[0].tryout_id;
        const res = await axios.get(`/api/${tryout_id.value}/rangking`);
        Leaderboard.value = res.data;
        Leaderboard.value = sortByRank(Leaderboard.value.rankings);
      } catch (error) {
        console.error("Error while fetching leaderboard data:", error);
      }
    }
    setData();
  } catch (error) {
    console.error("Error fetching result data:", error);
  }
};

fetchData();
</script>

<style scoped>
.leadearboard__wrapper {
  padding: 10px;
  display: block;
  margin-bottom: 10px;
  background: #f3f3f3;
  border-radius: 5px;
}

.leadearboard {
  height: 400px;
  overflow-y: scroll;
}
</style>
