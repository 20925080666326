<template>
  <div class="main-wrapper">
    <layouts></layouts>

    <div class="page-wrapper">
      <breadcrumb :title="title" :text="text" :text1="text1" />

      <faqcontent />
    </div>
    <truelyfooter />
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "Frequently Asked Questions",
      text: "Home",
      text1: "Frequently Asked Questions",
    };
  },
};
</script>
