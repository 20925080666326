<template>
  <div class="content">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-lg-6 mx-auto">
          <div class="login-wrap">
            <div class="login-header">
              <h3>User Signup</h3>
            </div>
            <!-- Login Form -->
            <form @submit.prevent="submitForm">
              <div class="form-group">
                <label class="col-form-label">Name</label>
                <input v-model="data.name" type="text" class="form-control" placeholder="Enter your name" />
                <div v-if="errors.name" class="text-danger">
                  {{ errors.name[0] }}
                </div>
              </div>
              <div class="form-group">
                <label class="col-form-label">Phone number</label>
                <input v-model="data.phone_number" type="text" class="form-control" placeholder="Enter your phone number" />
                <div v-if="errors.phone_number" class="text-danger">
                  {{ errors.phone_number[0] }}
                </div>
              </div>
              <div class="form-group">
                <label class="col-form-label">E-mail</label>
                <input
                  type="email"
                  class="form-control"
                  v-model="data.email"
                  placeholder="example@email.com"
                />
                <div v-if="errors.email" class="text-danger">
                  {{ errors.email[0] }}
                </div>
              </div>
              <div class="form-group">
                <label class="col-form-label d-block"
                  >Password<span class="brief-bio float-end"
                    >Must be 8 Characters at Least</span
                  ></label
                >
                <div class="pass-group">
                  <input
                    v-if="showPassword"
                    type="text"
                    class="form-control pass-input"
                    v-model="data.password"
                    placeholder="**********"
                  />
                  <input
                    v-else
                    type="password"
                    class="form-control pass-input"
                    placeholder="**********"
                    v-model="data.password"
                  />
                  <span
                    class="toggle-password"
                    @click="toggleShow"
                    :class="{
                      'feather-eye': showPassword,
                      'feather-eye-off': !showPassword,
                    }"
                  ></span>
                </div>
                <div v-if="errors.password" class="text-danger">
                  {{ errors.password[0] }}
                </div>
              </div>

              <div class="form-group">
                <label class="col-form-label d-block"
                  >Password Confirmation</label
                >
                <div class="pass-group">
                  <input
                    v-if="showPassword"
                    type="text"
                    class="form-control pass-input"
                    v-model="data.password_confirmation"
                    placeholder="**********"
                  />
                  <input
                    v-else
                    type="password"
                    class="form-control pass-input"
                    placeholder="**********"
                    v-model="data.password_confirmation"
                  />
                  <span
                    class="toggle-password"
                    @click="toggleShow"
                    :class="{
                      'feather-eye': showPassword,
                      'feather-eye-off': !showPassword,
                    }"
                  ></span>
                </div>
              </div>
             
              <b-button variant="primary w-100 login-btn" type="submit">Signup</b-button>
           
              <p class="no-acc">
                Already have an account <router-link to="login">Sign in</router-link>
              </p>
            </form>
            <!-- /Login Form -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/stores/authStore';
import swal from 'sweetalert2'; 
import {useLoading} from 'vue-loading-overlay'
    
const $loading = useLoading({
});
  
const authStore = useAuthStore();
// console.log(authStore.user);
const showPassword = ref(false);
const data = ref({ name: '', email: '', password: '', password_confirmation: '', phone_number: '' });
const router = useRouter();
let errors = ref({ name: [], email: [], password: [], password_confirmation: [], phone_number: [] });
const toggleShow = () => {
  showPassword.value = !showPassword.value;
};

const submitForm = async () => {
  errors.value = {};
  const loader = $loading.show({});
  try {
    const response = await authStore.register(data.value);
    swal.fire({ icon: 'success', text: response.data.message });
    data.value = {};
    loader.hide();
    router.push('/login');
  } catch (error) {
    if (error.response.data.errors) {
      errors.value = error.response.data.errors
    } else {
      error.response.data
    }
    loader.hide()
  }
};

</script>
