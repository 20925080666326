<template>
    <!-- Header -->
    <header class="header">
        <div class="container">
            <nav class="navbar navbar-expand-lg header-nav">
                <div class="navbar-header">
                    <router-link to="/" class="navbar-brand logo">
                        <img src="@/assets/img/logo.svg" class="img-fluid" alt="Logo">
                    </router-link>
                    <router-link to="/" class="navbar-brand logo-small">
                        <img src="@/assets/img/logo-small.png" class="img-fluid" alt="Logo">
                    </router-link>
                </div>
                <div class="main-menu-wrapper">
                    <div class="menu-header">
                        <router-link to="/" class="menu-logo">
                            <img src="@/assets/img/logo.svg" class="img-fluid" alt="Logo">
                        </router-link>
                        <a id="menu_close" class="menu-close" href="javascript:void(0);"> <i class="fas fa-times"></i></a>
                    </div>

                    <ul class="main-nav">
                        <li class="has-submenu" :class="IndexMenu ? 'active' : 'notactive'">
                            <a href="javascript:void(0);">Home <i class="fas fa-chevron-down"></i></a>
                            <ul class="submenu">
                                <li :class="currentPath == 'index' ? 'active' : 'notactive'"><router-link to="/">Home
                                        1</router-link></li>
                                <li :class="currentPath == 'index-2' ? 'active' : 'notactive'"><router-link
                                        to="/index-2">Home 2</router-link></li>
                            </ul>
                        </li>
                        <li class="has-submenu" :class="ServicesMenu ? 'active' : 'notactive'">
                            <a href="javascript:void(0);">Services <i class="fas fa-chevron-down"></i></a>
                            <ul class="submenu">
                                <li :class="currentPath == 'service-grid' ? 'active' : 'notactive'"><router-link
                                        to="/service-grid">Service Grid</router-link></li>
                                <li :class="currentPath == 'service-list' ? 'active' : 'notactive'"><router-link
                                        to="/service-list">Service List</router-link></li>
                                <li class="has-submenu" :class="ServiceDetailsMenu ? 'active' : 'notactive'">
                                    <a href="javascript:void(0);">Service Details</a>
                                    <ul class="submenu">
                                        <li :class="currentPath == 'service-details' ? 'active' : 'notactive'">
                                            <router-link to="service-details">Service Details 1</router-link>
                                        </li>
                                        <li :class="currentPath == 'service-details2' ? 'active' : 'notactive'">
                                            <router-link to="service-details2">Service Details 2</router-link>
                                        </li>
                                    </ul>
                                </li>
                                <li :class="currentPath == 'search' ? 'active' : 'notactive'"><router-link
                                        to="/search">Search</router-link></li>
                                <li class="has-submenu">
                                    <a href="javascript:void(0);">Providers</a>
                                    <ul class="submenu">
                                        <li :class="currentPath == 'providers' ? 'active' : 'notactive'"><router-link
                                                to="/providers">Providers List</router-link></li>
                                        <li :class="currentPath == 'provider-details' ? 'active' : 'notactive'"><router-link
                                                to="/provider-details">Providers Details</router-link></li>
                                    </ul>
                                </li>
                                <li :class="currentPath == 'create-service' ? 'active' : 'notactive'"><router-link
                                        @click="redirectReloadCreate" to="/create-service">Create Service</router-link></li>
                            </ul>
                        </li>
                        <li class="has-submenu" :class="CustomersMenu ? 'active' : 'notactive'">
                            <a href="javascript:void(0);">Customers <i class="fas fa-chevron-down"></i></a>
                            <ul class="submenu">
                                <li :class="currentPath == 'customer-dashboard' ? 'active' : 'notactive'"><router-link
                                        to="/customer-dashboard">Dashboard</router-link></li>
                                <li :class="currentPath == 'customer-booking' ? 'active' : 'notactive'"><router-link
                                        to="/customer-booking" @click="redirectReloadCustomer">Booking</router-link></li>
                                <li :class="currentPath == 'customer-favourite' ? 'active' : 'notactive'"><router-link
                                        to="/customer-favourite">Favorites</router-link></li>
                                <li :class="currentPath == 'customer-wallet' ? 'active' : 'notactive'"><router-link
                                        to="/customer-wallet">Wallet</router-link></li>
                                <li :class="currentPath == 'customer-reviews' ? 'active' : 'notactive'"><router-link
                                        to="/customer-reviews">Reviews</router-link></li>
                                <li :class="currentPath == 'customer-chat' ? 'active' : 'notactive'"><router-link
                                        to="/customer-chat">Chat</router-link></li>
                                <li :class="currentPath == 'customer-profile' ? 'active' : 'notactive'"><router-link
                                        to="/customer-profile">Settings</router-link></li>
                            </ul>
                        </li>
                        <li class="has-submenu" :class="ProvidersMenu ? 'active' : 'notactive'">
                            <router-link to="">Providers <i class="fas fa-chevron-down"></i></router-link>
                            <ul class="submenu">
                                <li :class="currentPath == 'provider-dashboard' ? 'active' : 'notactive'"><router-link
                                        to="/provider-dashboard">Dashboard</router-link></li>
                                <li :class="currentPath == 'provider-services' ? 'active' : 'notactive'"><router-link
                                        to="/provider-services">My Services</router-link></li>
                                <li :class="currentPath == 'provider-booking' ? 'active' : 'notactive'"><router-link
                                        to="/provider-booking">Booking</router-link></li>
                                <li :class="currentPath == 'provider-payout' ? 'active' : 'notactive'"><router-link
                                        to="/provider-payout">Payout</router-link></li>
                                <li class="has-submenu">
                                    <a href="javascript:void(0);">Settings</a>
                                    <ul class="submenu">
                                        <li
                                            :class="currentPath == 'provider-appointment-settings' ? 'active' : 'notactive'">
                                            <router-link to="/provider-appointment-settings">Appointment
                                                Settings</router-link>
                                        </li>
                                        <li :class="currentPath == 'provider-profile-settings' ? 'active' : 'notactive'">
                                            <router-link to="/provider-profile-settings">Account Settings</router-link>
                                        </li>
                                        <li :class="currentPath == 'provider-social-profile' ? 'active' : 'notactive'">
                                            <router-link to="/provider-social-profile">Social Profiles</router-link>
                                        </li>
                                        <li :class="currentPath == 'provider-security-settings' ? 'active' : 'notactive'">
                                            <router-link to="/provider-security-settings">Security</router-link>
                                        </li>
                                        <li :class="currentPath == 'provider-plan' ? 'active' : 'notactive'"><router-link
                                                to="/provider-plan">Plan & Billings</router-link></li>
                                        <li :class="currentPath == 'provider-notifcations' ? 'active' : 'notactive'">
                                            <router-link to="/provider-notifcations">Notifications</router-link>
                                        </li>
                                        <li :class="currentPath == 'provider-connected-apps' ? 'active' : 'notactive'">
                                            <router-link to="/provider-connected-apps">Connected Apps</router-link>
                                        </li>
                                        <li><a href="javascript:;" data-bs-toggle="modal"
                                                data-bs-target="#del-account">Delete
                                                Account</a></li>
                                    </ul>
                                </li>
                                <li :class="currentPath == 'provider-availability' ? 'active' : 'notactive'"><router-link
                                        to="/provider-availability">Availability</router-link></li>
                                <li :class="currentPath == 'provider-holiday' ? 'active' : 'notactive'"><router-link
                                        to="/provider-holiday">Holidays & Leave</router-link></li>
                                <li :class="currentPath == 'provider-coupons' ? 'active' : 'notactive'"><router-link
                                        to="/provider-coupons">Coupons</router-link></li>
                                <li :class="currentPath == 'provider-offers' ? 'active' : 'notactive'"><router-link
                                        to="/provider-offers">Offers</router-link></li>
                                <li :class="currentPath == 'provider-reviews' ? 'active' : 'notactive'"><router-link
                                        to="/provider-reviews">Reviews</router-link></li>
                                <li :class="currentPath == 'provider-earnings' ? 'active' : 'notactive'"><router-link
                                        to="/provider-earnings">Earnings</router-link></li>
                                <li :class="currentPath == 'provider-chat' ? 'active' : 'notactive'"><router-link
                                        to="/provider-chat">Chat</router-link></li>
                            </ul>
                        </li>
                        <li class="has-submenu" :class="PagesMenu ? 'active' : 'notactive'">
                            <a href="javascript:void(0);">Pages <i class="fas fa-chevron-down"></i></a>
                            <ul class="submenu">
                                <li :class="currentPath == 'about-us' ? 'active' : 'notactive'"><router-link
                                        to="/about-us">About</router-link></li>
                                <li :class="currentPath == 'contact-us' ? 'active' : 'notactive'"><router-link
                                        to="/contact-us">Contact Us</router-link></li>
                                <li :class="currentPath == 'how-it-works' ? 'active' : 'notactive'"><router-link
                                        to="/how-it-works">How It Works</router-link></li>
                                <li class="has-submenu">
                                    <a href="javascript:void(0);">Error Page</a>
                                    <ul class="submenu">
                                        <li :class="currentPath == 'error-404' ? 'active' : 'notactive'"><router-link
                                                to="/error-404">404 Error</router-link></li>
                                        <li :class="currentPath == 'error-500' ? 'active' : 'notactive'"><router-link
                                                to="/error-500">500 Error</router-link></li>
                                    </ul>
                                </li>
                                <li class="has-submenu">
                                    <a href="javascript:void(0);">Authentication</a>
                                    <ul class="submenu">
                                        <li :class="currentPath == 'choose-signup' ? 'active' : 'notactive'"><router-link
                                                to="/choose-signup">Signup Choose </router-link></li>
                                        <li :class="currentPath == 'user-signup' ? 'active' : 'notactive'"><router-link
                                                to="/user-signup">Customer Signup</router-link></li>
                                        <li :class="currentPath == 'provider-signup' ? 'active' : 'notactive'"><router-link
                                                to="/provider-signup">Provider Signup</router-link></li>
                                        <li :class="currentPath == 'login' ? 'active' : 'notactive'"><router-link
                                                to="/login">Login</router-link></li>
                                        <li :class="currentPath == 'reset-password' ? 'active' : 'notactive'"><router-link
                                                to="/reset-password">Reset Password</router-link></li>
                                        <li :class="currentPath == 'password-recovery' ? 'active' : 'notactive'">
                                            <router-link to="/password-recovery">Password Update</router-link>
                                        </li>
                                        <li :class="currentPath == 'phone-otp' ? 'active' : 'notactive'"><router-link
                                                to="/phone-otp">Phone OTP</router-link></li>
                                        <li :class="currentPath == 'email-otp' ? 'active' : 'notactive'"><router-link
                                                to="/email-otp">Email OTP</router-link></li>
                                        <li :class="currentPath == 'free-trial' ? 'active' : 'notactive'"><router-link
                                                to="/free-trial">Free Trial</router-link></li>
                                    </ul>
                                </li>
                                <li class="has-submenu">
                                    <a href="javascript:void(0);">Booking</a>
                                    <ul class="submenu">
                                        <li :class="currentPath == 'booking' ? 'active' : 'notactive'"><router-link
                                                to="/booking">Booking</router-link></li>
                                        <li :class="currentPath == 'booking-payment' ? 'active' : 'notactive'"><router-link
                                                to="/booking-payment">Booking Checkout</router-link></li>
                                        <li :class="currentPath == 'booking-done' ? 'active' : 'notactive'"><router-link
                                                to="/booking-done">Booking Success</router-link></li>
                                    </ul>
                                </li>
                                <li :class="currentPath == 'categories' ? 'active' : 'notactive'"><router-link
                                        to="/categories">Categories</router-link></li>
                                <li :class="currentPath == 'pricing' ? 'active' : 'notactive'"><router-link
                                        to="/pricing">Pricing Plan</router-link></li>
                                <li :class="currentPath == 'faq' ? 'active' : 'notactive'"><router-link
                                        to="/faq">FAQ</router-link></li>
                                <li :class="currentPath == 'maintenance' ? 'active' : 'notactive'"><router-link
                                        to="/maintenance">Maintenance</router-link></li>
                                <li :class="currentPath == 'coming-soon' ? 'active' : 'notactive'"><router-link
                                        to="/coming-soon">Coming Soon</router-link></li>
                                <li :class="currentPath == 'privacy-policy' ? 'active' : 'notactive'"><router-link
                                        to="/privacy-policy">Privacy Policy</router-link></li>
                                <li :class="currentPath == 'terms-condition' ? 'active' : 'notactive'"><router-link
                                        to="/terms-condition">Terms & Conditions</router-link></li>
                                <li :class="currentPath == 'session-expired' ? 'active' : 'notactive'"><router-link
                                        to="/session-expired">Session Expired</router-link></li>
                            </ul>
                        </li>
                        <li class="has-submenu" :class="BlogMenu ? 'active' : 'notactive'">
                            <router-link to="/blog-grid">Blog <i class="fas fa-chevron-down"></i></router-link>
                            <ul class="submenu">
                                <li :class="currentPath == 'blog-grid' ? 'active' : 'notactive'"><router-link
                                        to="/blog-grid">Blog Grid</router-link></li>
                                <li :class="currentPath == 'blog-list' ? 'active' : 'notactive'"><router-link
                                        to="/blog-list">Blog List</router-link></li>
                                <li :class="currentPath == 'blog-details' ? 'active' : 'notactive'"><router-link
                                        to="/blog-details">Blog Details</router-link></li>
                            </ul>
                        </li>
                        <li class="login-link">
                            <router-link to="/choose-signup">Register</router-link>
                        </li>
                        <li class="login-link">
                            <router-link to="/login">Login</router-link>
                        </li>
                    </ul>
                </div>
                <ul class="nav header-navbar-rht log-rht">
                    <li class="nav-item">
                        <router-link class="nav-link" to="/faq">FAQ</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/login">Login</router-link>
                    </li>
                    <li class="nav-item dropdown flag-nav">
                        <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="javascript:;" role="button"
                            aria-expanded="false">
                            <img src="@/assets/img/flag.png" alt=""> En
                        </a>
                        <div class="dropdown-menu dropdown-menu-right" style="">
                            <a href="javascript:void(0);" class="dropdown-item">
                                <img src="@/assets/img/flags/us.png" alt="" height="16"> En
                            </a>
                            <a href="javascript:void(0);" class="dropdown-item">
                                <img src="@/assets/img/flags/fr.png" alt="" height="16"> French
                            </a>
                            <a href="javascript:void(0);" class="dropdown-item">
                                <img src="@/assets/img/flags/de.png" alt="" height="16"> German
                            </a>
                        </div>
                    </li>
                </ul>
            </nav>
        </div>
    </header>
    <!-- /Header -->
</template>


<script>
export default {
    data() {
        return {

        }
    },
    computed: {
        currentPath() {
            return this.$route.name
        },
        IndexMenu() {
            return this.$route.name == 'index' || this.$route.name == 'index-2';
        },
        ServicesMenu() {
            return this.$route.name == 'choose-signup' || this.$route.name == 'service-list' || this.$route.name == 'search'
                || this.$route.name == 'providers' || this.$route.name == 'provider-details' || this.$route.name == 'create-service';
        },
        ServiceDetailsMenu() {
            return (
                this.$route.name == "service-details" ||
                this.$route.name == "service-details2"
            );
        },
        CustomersMenu() {
            return this.$route.name == 'customer-dashboard' || this.$route.name == 'customer-booking' || this.$route.name == 'customer-favourite'
                || this.$route.name == 'customer-wallet' || this.$route.name == 'customer-reviews' || this.$route.name == 'customer-chat' || this.$route.name == 'customer-profile';
        },
        ProvidersMenu() {
            return this.$route.name == 'provider-dashboard' || this.$route.name == 'provider-services' || this.$route.name == 'provider-booking' || this.$route.name == 'provider-payout'
                || this.$route.name == 'provider-appointment-settings' || this.$route.name == 'provider-profile-settings' || this.$route.name == 'provider-social-profile'
                || this.$route.name == 'provider-security-setting' || this.$route.name == 'provider-plan' || this.$route.name == 'provider-notifcation' || this.$route.name == 'provider-connected-apps'
                || this.$route.name == 'provider-availability' || this.$route.name == 'provider-holiday' || this.$route.name == 'provider-coupons' || this.$route.name == "provider-offers"
                || this.$route.name == 'provider-reviews' || this.$route.name == 'provider-earnings' || this.$route.name == 'provider-chat';
        },
        PagesMenu() {
            return this.$route.name == 'about-us' || this.$route.name == 'contact-us' || this.$route.name == 'how-it-works' || this.$route.name == 'error-404' || this.$route.name == 'error-500'
                || this.$route.name == 'choose-signup' || this.$route.name == 'user-signup' || this.$route.name == 'provider-signup' || this.$route.name == 'login' || this.$route.name == 'reset-password'
                || this.$route.name == 'password-recovery' || this.$route.name == 'phone-otp' || this.$route.name == 'email-otp' || this.$route.name == 'free-trial' || this.$route.name == 'booking'
                || this.$route.name == 'booking-payment' || this.$route.name == 'booking-done' || this.$route.name == 'categories' || this.$route.name == 'pricing' || this.$route.name == 'faq'
                || this.$route.name == 'maintenance' || this.$route.name == 'coming-soon' || this.$route.name == 'privacy-policy' || this.$route.name == 'terms-condition' || this.$route.name == 'session-expired'
        },
        BlogMenu() {
            return this.$route.name == 'blog-grid' || this.$route.name == 'blog-list' || this.$route.name == 'blog-details'
        }
    },
    methods: {
        redirectReloadCreate() {
            this.$router
                .push({ path: '/create-service' })
                .then(() => { this.$router.go() })
        },
        redirectReloadCustomer() {
            this.$router.push({ path: "/customer-booking" }).then(() => {
                this.$router.go();
            });
        },
    },
}
</script>