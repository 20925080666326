<template>
  <div class="col-lg-3 theiaStickySidebar mb-3">
    <div class="stickysidebar">
      <div class="settings-widget">
        <div class="settings-header">
          <div class="settings-img">
            <img src="@/assets/img/profiles/avatar-02.jpg" alt="user" />
          </div>
          <h6>John Smith</h6>
          <p>Member Since Sep 2023</p>
        </div>
        <div class="settings-menu">
          <ul>
            <li :class="currentPath == 'customer-dashboard' ? 'active' : 'notactive'">
              <router-link to="customer-dashboard">
                <i class="feather-grid"></i> <span>Dashboard</span>
              </router-link>
            </li>
            <li>
              <router-link
                to="customer-booking"
                :class="
                  currentPath == 'customer-booking' ||
                  currentPath == 'customer-booking-calendar'
                    ? 'active'
                    : 'notactive'
                "
              >
                <i class="feather-smartphone"></i> <span>Bookings</span>
              </router-link>
            </li>
            <li :class="currentPath == 'customer-favourite' ? 'active' : 'notactive'">
              <router-link to="customer-favourite">
                <i class="feather-heart"></i> <span>Favorites</span>
              </router-link>
            </li>
            <li :class="currentPath == 'customer-wallet' ? 'active' : 'notactive'">
              <router-link to="customer-wallet">
                <i class="feather-credit-card"></i> <span>Wallet</span>
              </router-link>
            </li>
            <li :class="currentPath == 'customer-reviews' ? 'active' : 'notactive'">
              <router-link to="customer-reviews">
                <i class="fas fa-star"></i> <span>Reviews</span>
              </router-link>
            </li>
            <li :class="currentPath == 'customer-chat' ? 'active' : 'notactive'">
              <router-link to="customer-chat">
                <i class="feather-message-circle"></i> <span>Chat</span>
              </router-link>
            </li>
            <li :class="currentPath == 'customer-profile' ? 'active' : 'notactive'">
              <router-link to="customer-profile">
                <i class="feather-settings"></i> <span>Settings</span>
              </router-link>
              <ul class="setting-submenu">
                <li :class="currentPath == 'customer-profile' ? 'active' : 'notactive'">
                  <router-link to="customer-profile">Account</router-link>
                </li>
                <li :class="currentPath == 'security-settings' ? 'active' : 'notactive'">
                  <router-link to="security-settings">Security</router-link>
                </li>
                <li
                  :class="
                    currentPath == 'customer-notifications' ? 'active' : 'notactive'
                  "
                >
                  <router-link to="customer-notifications">Notifications</router-link>
                </li>
                <li :class="currentPath == 'connected-apps' ? 'active' : 'notactive'">
                  <router-link to="connected-apps">Connected Apps</router-link>
                </li>
                <li>
                  <a
                    href="javascript:;"
                    data-bs-toggle="modal"
                    data-bs-target="#del-account"
                    >Delete Account</a
                  >
                </li>
              </ul>
            </li>
            <li>
              <router-link to="/">
                <i class="feather-log-out"></i> <span>Logout</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    currentPath() {
      return this.$route.name;
    },
  },
  data() {
    return {
      activeClass: "active",
    };
    //  isactive : true
  },
};
</script>
