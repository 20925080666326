<template>
  <div class="provider-body">
    <div class="main-wrapper">
      <dashboardnavbar />

      <div class="page-wrapper">
        <sidebar />

        <div class="container" v-if="data[page]">
          <div class="row content justify-content-center">
            <div class="col-lg-7">
              <div class="d-flex justify-content-center timer-up">
                <div class="mb-4 py-2 px-5 timer">
                  Sisa Waktu <br />
                  <span class="timer_content">{{ formattedTime }}</span>
                </div>
              </div>
              <div class="mb-4 py-2 px-5 timer mx-auto">
                {{ data[page].column.name }}
              </div>
              <div class="row justify-content-center gap-2">
                <div
                  class="col-auto"
                  v-for="(item, index) in data[page].questions"
                >
                  <div class="card_test_kecermatan">
                    <div class="card_test_kecermatan__content">
                      <div class="card_test_body question">
                        {{ item }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row justify-content-center">
                <div class="row row justify-content-center">
                  <div @click="
                      onSend({
                        question_id: data[page].id,
                        answer_user: 1,
                        result_tryout_user_id: Result_RTO,
                      })
                    " class="answer_calc col-3 d-flex justify-content-center align-items-center">1</div>
                  <div @click="
                      onSend({
                        question_id: data[page].id,
                        answer_user: 2,
                        result_tryout_user_id: Result_RTO,
                      })
                    " class="answer_calc col-3 d-flex justify-content-center align-items-center">2</div>
                  <div @click="
                      onSend({
                        question_id: data[page].id,
                        answer_user: 3,
                        result_tryout_user_id: Result_RTO,
                      })
                    " class="answer_calc col-3 d-flex justify-content-center align-items-center">3</div>
                </div>
                <div class="row row justify-content-center">
                  <div @click="
                      onSend({
                        question_id: data[page].id,
                        answer_user: 4,
                        result_tryout_user_id: Result_RTO,
                      })
                    " class="answer_calc col-3 d-flex justify-content-center align-items-center">4</div>
                  <div @click="
                      onSend({
                        question_id: data[page].id,
                        answer_user: 5,
                        result_tryout_user_id: Result_RTO,
                      })
                    " class="answer_calc col-3 d-flex justify-content-center align-items-center">5</div>
                  <div @click="
                      onSend({
                        question_id: data[page].id,
                        answer_user: 6,
                        result_tryout_user_id: Result_RTO,
                      })
                    " class="answer_calc col-3 d-flex justify-content-center align-items-center">6</div>
                </div>
                
                <div class="row justify-content-center">
                  <div @click="
                      onSend({
                        question_id: data[page].id,
                        answer_user: 7,
                        result_tryout_user_id: Result_RTO,
                      })
                    " class="answer_calc col-3 d-flex justify-content-center align-items-center">7</div>
                  <div @click="
                      onSend({
                        question_id: data[page].id,
                        answer_user: 8,
                        result_tryout_user_id: Result_RTO,
                      })
                    " class="answer_calc col-3 d-flex justify-content-center align-items-center">8</div>
                  <div @click="
                      onSend({
                        question_id: data[page].id,
                        answer_user: 9,
                        result_tryout_user_id: Result_RTO,
                      })
                    " class="answer_calc col-3 d-flex justify-content-center align-items-center">9</div>
                </div>
                
                <div class="row row justify-content-center">
                  <div class="col-3 d-flex justify-content-center align-items-center"></div>
                  <div @click="
                      onSend({
                        question_id: data[page].id,
                        answer_user: 0,
                        result_tryout_user_id: Result_RTO,
                      })
                    " class="answer_calc col-3 d-flex justify-content-center align-items-center">0</div>
                  <div class="col-3 d-flex justify-content-center align-items-center"></div>
                </div>
              </div>
            </div>
            <div class="col-auto">
              <div class="mb-4 py-2 px-5 timer timer-down">
                Sisa Waktu <br />
                <span class="timer_content">{{ formattedTime }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import swal from "sweetalert2";
import { onBeforeMount, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { tryouts } from "@/utils/kecermatan";
import { useKecermatanStore } from "@/stores/kecermatanStore";
import { useRoute, useRouter } from "vue-router";
import { timeout } from "@/utils/kecermatan/timeout";
import axios from "axios";
import { useAuthStore } from "@/stores/authStore";
let timoutData = ref(null);
let kecermatanStore = useKecermatanStore();
let data = ref([]);
let page = ref(0);
let selected = ref([]);
let timeState = ref(null);
const route = useRoute();
const Result_RTO = route.params.id;

const dateToTime = (start) => {
  let targetDate = new Date(timeState.value);
  let currentDate = new Date(start);
  let currentTime = Math.floor(currentDate.getTime() / 1000);
  let targetTime = Math.floor(targetDate.getTime() / 1000);
  let timeDiff = targetTime - currentTime;
  return timeDiff;
};

let spend_time = ref(0);
const spendTime = () => {
  kecermatanStore.setTime(spend_time.value);
  spend_time.value = 0;
  return kecermatanStore.getTime();
};

const router = useRouter();

const onSend = (params) => {
  // const formatedTimerSeconds = timeToSeconds(formattedTime.value);
  params.time_spend = spendTime();
  params.tryout_id = TRYOUT_ID.value;
  kecermatanStore.setData(params);
  if (time.value == 0) {
    stopTimer();
    kecermatanStore.setData(params);
  } else if (page.value < data.value.length - 1) {
    page.value++;
  } else {
    stopTimer();
  }
};

const getCurrentAnswer = () => {
  kecermatanStore.question_anser_user.forEach((stateItem) => {
    const index = selected.value.findIndex((item) => item.id === stateItem.id);
    if (index !== -1) {
      selected.value[index].answer = stateItem.answer;
    }
  });
};

// Define the timer logic
const timer = ref(null);
let time = ref(null);
let formattedTime = ref(null);

function formatTime(seconds) {
  const hours = Math.floor(seconds / 3600); // Menghitung jumlah jam
  const minutes = Math.floor((seconds % 3600) / 60); // Menghitung jumlah menit
  const remainingSeconds = seconds % 60; // Menghitung jumlah detik
  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`; // Menggabungkan jam, menit, dan detik
}

function startTimer() {
  if (time.value <= 0) {
    formattedTime.value = "Waktu Habis";
    clearInterval(timer.value);
    return;
  }

  timer.value = setInterval(() => {
    if (time.value <= 0) {
      clearInterval(timer.value);
      stopTimer();
    } else {
      time.value--;
      spend_time.value++;
      formattedTime.value = formatTime(time.value);
    }
  }, 1000);
}
let doneIndex = ref([]);
async function stopTimer() {
  if (data.value[page.value]) {
    let currentColumnId = data.value[page.value].column.id;
    const currentIndex = page.value;

    // Check if the current column is not the last one
    if (currentIndex < data.value.length - 1) {
      // Check if the next column is different and not included in doneIndex
      let nextColumnId = data.value[currentIndex + 1].column.id;
      if (
        nextColumnId !== currentColumnId &&
        doneIndex.value.indexOf(currentIndex + 1) === -1
      ) {
        clearInterval(timer.value);
        doneIndex.value.push(currentIndex);
        page.value = currentIndex + 1; // Move to the next column
      } else {
        // If the conditions are not met, move to the next page without advancing the column
        page.value++;
        stopTimer();
      }
    } else {
      // If the current column is the last one, proceed to the else block
      clearInterval(timer.value);
      try {
        await axios.put("/api/update-status/" + Result_RTO, {
          status: "done",
        });

        swal.fire({ icon: "success", text: "Quizz selesai!" });
        router.push({ path: `/tryouts/kecermatan/${Result_RTO}/result` });
      } catch (err) {
        console.error("Error while updating status:", err);
        // Tindakan lain yang sesuai untuk menangani kesalahan
      }
    }
  } else {
    clearInterval(timer.value);
    try {
      await axios.put("/api/update-status/" + Result_RTO, {
        status: "done",
      });

      swal.fire({ icon: "success", text: "Quizz selesai!" });
      router.push({ path: `/tryouts/kecermatan/${Result_RTO}/result` });
    } catch (err) {
      console.error("Error while updating status:", err);
      // Tindakan lain yang sesuai untuk menangani kesalahan
    }
  }
}

const checkTimeOut = (status) => {
  if (status == "done") {
    stopTimer();
    swal.fire({ icon: "error", text: "Waktu kamu sudah habis" });
    router.push({ path: `/tryouts/kecermatan/${Result_RTO}/result` });
    return;
  }
  if (time.value <= 0) {
    stopTimer();
    swal.fire({ icon: "error", text: "Waktu kamu sudah habis" });
    router.push({ path: `/tryouts/kecermatan/${Result_RTO}/result` });
    return;
  }
};

function ubahFormatTanggal(tanggal) {
  // Buat objek Date dari string input
  let dateObj = new Date(tanggal);

  // Ambil bagian tanggal, bulan, dan tahun
  let tahun = dateObj.getFullYear();
  let bulan = String(dateObj.getMonth() + 1).padStart(2, "0");
  let tanggalPart = String(dateObj.getDate()).padStart(2, "0");

  // Ambil bagian jam dan menit
  let jam = String(dateObj.getHours()).padStart(2, "0");
  let menit = String(dateObj.getMinutes()).padStart(2, "0");

  // Gabungkan kembali dalam format yang diinginkan
  return `${tahun}-${bulan}-${tanggalPart}T${jam}:${menit}`;
}

const authStore = useAuthStore();
const user = authStore.user.id;
let TRYOUT_ID = ref(null);
const fetchData = async () => {
  try {
    const response = await axios.get(
      `api/result_tryout_users/${Result_RTO}/edit`
    );
    // if (response.data.result.user_id != user) {
    //     router.push({ path: "/home" });
    //     return;
    // }
    timoutData.value = ubahFormatTanggal(response.data.result.timeout);
    timeState.value = timoutData.value;
    time.value = dateToTime(ubahFormatTanggal(response.data.result.timestart));
    formattedTime.value = formatTime(time.value);
    checkTimeOut(response.data.result.status);
    startTimer();
    TRYOUT_ID.value = response.data.result.tryout_id;

    try {
      const res = await axios.get(
        `/api/tryout/${response.data.result.tryout_id}/questions`
      );
      data.value = res.data.questions;
    } catch (error) {
      console.error("Error fetching tryout questions:", error);
      // Tindakan lain yang sesuai untuk menangani kesalahan
    }
  } catch (err) {
    console.error("Error fetching result data:", err);
    // Tindakan lain yang sesuai untuk menangani kesalahan
  }
};

watch(
  () => page.value,
  async (newValue, oldValue) => {
    if (data.value[newValue].column.name != data.value[oldValue].column.name) {
      try {
        const response = await axios.get(
          `api/result_tryout_users/${Result_RTO}/edit`
        );
        timoutData.value = ubahFormatTanggal(response.data.result.timeout);
        timeState.value = timoutData.value;
        time.value = dateToTime(
          ubahFormatTanggal(response.data.result.timestart)
        );
        formattedTime.value = formatTime(time.value);
        clearInterval(timer.value);
        checkTimeOut();
        startTimer();
      } catch (error) {
        console.error("Error fetching result data:", error);
        // Tindakan lain yang sesuai untuk menangani kesalahan
      }
    }
  }
);

onBeforeUnmount(() => {
  clearInterval(timer.value);
});
onMounted(() => {
  fetchData();
});
</script>

<style scoped>

.answer_calc{
  background: #d4d4d4;
  color: rgb(0, 0, 0);
  border-radius: 10px;
  margin: 12px;
  font-size: 30px;
  cursor: pointer;
}
.content {
  margin-top: 20px;
  border-radius: 30px;
  /* background: rgb(235, 235, 235); */
}
.content_header {
  display: flex;
  justify-content: center;
  width: 100%;
}

.timer_content {
  color: #1365df;
  font-weight: bold;
  font-size: 30px;
}

.timer {
  color: rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: max-content;
  border-radius: 20px;
}
.card_test_kecermatan {
  width: 100%;
  height: 120px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Align content vertically center */
  align-items: center;
  color: rgb(0, 0, 0);
}

.card_test_kecermatan__content {
  height: 100%; /* Fill up the height of the card */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Align content vertically center */
  align-items: center;
}

.card_test_kecermatan_lost {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  border: black 1px solid;
  font-size: 36px;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Align content vertically center */
  align-items: center;
  background: white;
  color: black;
  transition: all 0.3s ease;
}

@media screen and (max-width: 319px) {
  .card_test_kecermatan_lost {
    width: 60px;
    height: 50px;
    font-size: 30px;
  }
}

.card_test_kecermatan_lost_answer {
  width: 100%;
  height: 50px;
  padding-left: 10px;
  border-radius: 10px;
  border: black 1px solid;
  font-size: 16px;
  display: flex;
  align-items: center;
  background: white;
  color: black;
  transition: all 0.3s ease;
}

.card_test_kecermatan__content_lost {
  height: 100%; /* Fill up the height of the card */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Align content vertically center */
  align-items: center;
}

.cursor-pointer {
  cursor: pointer;
}

.question {
  font-size: 50px;
  width: max-content;
  font-weight: bold;
}

@media screen and (max-width: 992px) {
  .timer-down {
    display: none !important;
  }
}

@media screen and (min-width: 993px) {
  .timer-up {
    display: none !important;
  }
}
</style>
