<template>
    <div class="main-wrapper">
        <layouts></layouts>

        <div class="page-wrapper">
            <indexprofessional /> 

            <indexfeatureservice />

            <indexoffering />

            <indexplaning />

            <indexblog />

            <truelyfooter />

            <scroll />
        </div>
    </div>
</template>
