<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper">
      <div class="content">
        <b-form @submit.prevent="submitForm">
          <div class="row">
            <div class="col-lg-7 col-sm-12 m-auto">
              <div class="content-page-header">
                <h5 class="mb-2">Add Column</h5>
                <!-- <div class="form-group mb-0">
                    <p class="contentpage">You are editing "English" version</p>
                  </div> -->
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Name</label>
                    <input
                      v-model="data.name"
                      type="text"
                      class="form-control"
                    />
                    <div v-if="errors.name" class="text-danger">
                      {{ errors.name[0] }}
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Time</label>
                    <input
                      v-model="data.time"
                      type="text"
                      class="form-control"
                    />
                    <div v-if="errors.name" class="text-danger">
                      {{ errors.time[0] }}
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="btn-path">
                    <router-link
                      to="/admin/categories"
                      class="btn btn-cancel me-3"
                      >Cancel</router-link
                    >
                    <b-button type="submit" variant="primary">
                      Add Column</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import swal from "sweetalert2";
import axios from "axios";

import { useLoading } from "vue-loading-overlay";
import { useRouter } from "vue-router";
let errors = ref({});
const $loading = useLoading({});
const router = useRouter();

let data = ref({});

const submitForm = async () => {
  const loader = $loading.show({});
  const formData = new FormData();
  if (data.value.name) {
    formData.append("name", data.value.name);
  }
  if (data.value.time) {
    formData.append("time", data.value.time);
  }
  try {
    const response = await axios.post("api/columns", formData);
    loader.hide();
    swal.fire({ icon: "success", text: response.data.message });
    router.push("/admin/columns");
  } catch (error) {
    loader.hide();
    errors.value = error.response.data;
  }
};
</script>
