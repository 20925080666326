<template>
  <div class="modal fade custom-modal" id="add-review">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content doctor-profile">
        <div class="modal-header border-bottom-0 justify-content-between">
          <h5 class="modal-title">Write A Review</h5>
          <button
            type="button"
            class="close-btn"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <b-form action="javascript:;">
            <div class="write-review">
              <div class="review-add">
                <div class="rev-img">
                  <img src="@/assets/img/services/service-19.jpg" alt="" />
                </div>
                <div class="rev-info">
                  <h6>Computer Services</h6>
                  <p>Newyork, USA</p>
                </div>
              </div>
              <div class="form-group form-info">
                <label class="col-form-label">Rate The Service</label>
                <div class="rating rating-select mb-0">
                  <a href="javascript:void(0);"
                    ><i class="fas fa-star filled me-1"></i
                  ></a>
                  <a href="javascript:void(0);"
                    ><i class="fas fa-star filled me-1"></i
                  ></a>
                  <a href="javascript:void(0);"
                    ><i class="fas fa-star filled me-1"></i
                  ></a>
                  <a href="javascript:void(0);"
                    ><i class="fas fa-star filled me-1"></i
                  ></a>
                  <a href="javascript:void(0);"
                    ><i class="fas fa-star filled me-1"></i
                  ></a>
                </div>
              </div>
              <div class="form-group form-info">
                <label class="col-form-label">Write your Review</label>
                <textarea
                  class="form-control"
                  rows="4"
                  placeholder="Please write your review"
                ></textarea>
              </div>
              <div class="modal-submit text-end">
                <b-button type="submit" variant="primary">Send</b-button>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>
