<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <div class="content">
        <div class="content-page-header px-3 content-page-headersplit mb-0">
          <div class="container">
          <div class="content p-5">
            <div class="col-lg-9">
              <h6 class="user-title">General Information</h6>
              <div class="general-info">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-form-label">Name</label>
                      <input
                        v-model="user.name"
                        disabled
                        type="text"
                        class="form-control"
                        placeholder="Enter Your Name"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-form-label">Email </label>
                      <input
                        disabled
                        v-model="user.email"
                        type="email"
                        class="form-control"
                        placeholder="Enter Email Address"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-form-label">Mobile Number</label>
                      <input
                        v-model="user.phone_number"
                        disabled
                        type="text"
                        class="form-control"
                        placeholder="Enter Mobile Number"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-form-label">Paket</label>
                      <input
                        v-model="user.type"
                        disabled
                        type="text"
                        class="form-control"
                        placeholder="Enter Mobile Number"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-form-label">Masa Berlaku Paket Premium</label>
                      <input
           
                        disabled
                        type="text"
                        class="form-control"
                        placeholder="Tidak Ada Paket Premium Aktif"
                        :value="user.expired ? formatDateTime(user.expired) : ''"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
       
      </div>
    </div>
  </div>
 
</template>


<script setup>
import { useAuthStore } from "@/stores/authStore";

const authStore = useAuthStore();
const user = authStore.user;

function formatDateTime(dateTimeString) {
  // const dateTime = new Date(dateTimeString);

  // const dateOptions = { year: "numeric", month: "long", day: "numeric" };
  // const timeOptions = { hour: "numeric", minute: "numeric", second: "numeric" };

  // const formattedDate = dateTime.toLocaleDateString("en-US", dateOptions);
  // const formattedTime = dateTime.toLocaleTimeString("en-US", timeOptions);

  // return `${formattedDate} - ${formattedTime}`;

  // Convert the string to a Date object
  var dateObject = new Date(dateTimeString);

  // Format the date and time separately
  var formattedDate = dateObject.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
  var formattedTime = dateObject.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });

  // Concatenate formatted date and time
  return formattedDate + ' ' + formattedTime;
}

</script>

<style scoped>
.free {
  background: #ed7f409c;
  color: white;
}

.premium {
  background: #40994091;
  color: white;
}

.vip {
  background: #1365df;
  color: white;
}
</style>
