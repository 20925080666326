<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <div class="content">
        <div class="content-page-header px-3 content-page-headersplit mb-0">
          <h5>Data Admin</h5>
          <div class="list-btn">
            <router-link to="/admin/dataadmin/create" class="btn btn-primary">
              <i class="fa fa-plus me-2"></i>Add Admin
            </router-link>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-resposnive table-div">
              <div class="table datatable">
                <a-table :columns="columns" :data-source="data">
                  <template #bodyCell="{ column, record }">
                    <span v-if="column.key === 'email_verified_at' && record[column.key] === null">
                      Email Belum Terverifikasi
                    </span>
                    <template v-if="column.title === 'Action'">
                      <div class="d-flex flex justify-content-center gap-2">
                        <router-link
                          class="btn delete-table me-2"
                          :to="`/admin/dataadmin/${record.id}/edit`"
                        >
                          <i class="feather feather-edit"></i>
                        </router-link>
                        <button
                          @click="onDelete(record.id)"
                          class="btn delete-table"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#delete"
                        >
                          <i class="feather feather-trash-2"></i>
                        </button>
                      </div>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Confirm
    message="Are you sure you want to delete this column?"
    title="Delete"
    buttonText="delete"
    id="delete"
    :url="URL"
    @submitted="fetchData()"
  />
</template>

<script setup>
import { reactive, computed, ref } from "vue";
import axios from "axios";
import Confirm from "@/components/model/Confirm/index.vue";
const URL = ref(null);
const ID = ref();
const BASEURL = ref(process.env.VUE_APP_API_URL + "/api/users/");

const onDelete = (id) => {
  ID.value = id;
  URL.value = BASEURL.value + ID.value;
};
const columns = reactive([
{
    title: "No",
    dataIndex: "index",
    sorter: {
      compare: (a, b) => a.index - b.index,
    },
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    sorter: {
      compare: (a, b) => {
        a = a.name.toLowerCase();
        b = b.name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    sorter: {
      compare: (a, b) => {
        a = a.email.toLowerCase();
        b = b.email.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Phone Number",
    dataIndex: "phone_number",
    key: "phone_number",
    sorter: {
      compare: (a, b) => {
        a = a.phone_number.toLowerCase();
        b = b.phone_number.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Email Verified",
    dataIndex: "email_verified_at",
    key: "email_verified_at",
    sorter: {
      compare: (a, b) => {
        a = a.email_verified_at.toLowerCase();
        b = b.email_verified_at.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "id",
    sorter: true,
    class: "text-center",
  },
]);

const data = ref([]);

const fetchData = async () => {
  try {
    const response = await axios.get("/api/data-admin");
    data.value = response.data.users.map((category, index) => ({
      ...category,
      index: index + 1,
    }));
  } catch (error) {
    // Tangani kesalahan jika ada
    console.error("Error while fetching admin data:", error);
    // Lakukan tindakan lain sesuai kebutuhan, seperti menampilkan pesan kesalahan kepada pengguna
  }
};

fetchData();
</script>
