<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper">
      <div class="content">
        <b-form @submit.prevent="updateForm" enctype="multipart/form-data">
          <div class="row">
            <div class="col-lg-7 col-sm-12 m-auto">
              <div class="content-page-header">
                <h5 class="mb-2">Edit Program Bimbel</h5>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Title</label>
                    <input
                      v-model="data.title"
                      type="text"
                      class="form-control"
                    />
                    <div v-if="errors.title" class="text-danger">
                      {{ errors.title[0] }}
                    </div>
                  </div>
                </div>

                <div class="mb-4">
                  <label class="form-label">Image</label>
                  <div class="form-uploads">
                    <div class="form-uploads-path">
                      <img
                        v-if="preview.url"
                        :src="preview.url"
                        alt="img"
                      />
                      <img
                        v-else-if="data.url && !preview.url"
                        :src="data.url"
                        alt="img"
                      />
                      <img
                        v-else
                        src="@/assets/admin_img/icons/upload.svg"
                        alt="img"
                      />
                      <div class="file-browse">
                        <h6>Pilih Gambar</h6>
                        <div class="file-browse-path">
                          <input @change="handleMainImageUpload" type="file" />
                          <a href="javascript:void(0);"> Browse</a>
                        </div>
                      </div>
                      <h5>Supported formates: JPEG, PNG</h5>
                    </div>
                  </div>
                  <div v-if="errors.url" class="text-danger">
                    {{ errors.url[0] }}
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Description</label>
                    <summernoteEditor
                      v-model="data.description"
                      type="text"
                      class="form-control"
                      required
                    />
                    <div v-if="errors.description" class="text-danger">
                      {{ errors.description[0] }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Price Tag</label>
                    <vue-select
                      v-model="data.voucher_id"
                      :options="voucher"
                      placeholder="Voucher"
                    />
                    <div v-if="errors.voucher_id" class="text-danger">
                      {{ errors.voucher_id[0] }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="btn-path">
                    <router-link to="/admin/courses" class="btn btn-cancel me-3"
                      >Cancel</router-link
                    >
                    <b-button type="submit" variant="primary">
                      Edit Program Bimbel</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import swal from "sweetalert2";
import axios from "axios";
import { useLoading } from "vue-loading-overlay";
import { deleteFileByUrl } from "@/utils/fileUpload";
import { uploadFile } from "@/utils/fileUpload";
import { useRoute, useRouter } from "vue-router";
let errors = ref({});
const $loading = useLoading({});
const router = useRouter();
const route = useRoute();
let voucher = ref();
let data = ref({
  voucher: null,
});
let preview = ref({
  url: null,
});
let urlFirebase = ref(null);
const handleMainImageUpload = (event) => {
  preview.value.url = URL.createObjectURL(event.target.files[0]);
  urlFirebase.value = event.target.files[0];
};
const updateForm = async () => {
  const loader = $loading.show({});
  const formData = new FormData();
  formData.append("title", data.value.title);
  if (urlFirebase.value) {
    try {
      await deleteFileByUrl(data.value.url);
    } catch (error) {
      console.error("Error while deleting file:", error);
    }
    try {
      const response = await uploadFile(urlFirebase.value);
      console.log(response);
      formData.append("url", response);
    } catch (error) {
      console.error("Error while uploading file:", error);
    }
  } else if (data.value.url) {
    console.log(data.value.url);
    formData.append("url", data.value.url);
  }
  formData.append("description", data.value.description);
  formData.append("voucher_id", data.value.voucher_id);
  formData.append("_method", "put");
  try {
    const response = await axios.post(
      `api/courses/${route.params.id}`,
      formData
    );
    loader.hide();
    swal.fire({ icon: "success", text: response.data.message });
    router.push("/admin/courses");
  } catch (error) {
    loader.hide();
    errors.value = error.response.data;
  }
};
function transformArray(data) {
  return data.map((item) => {
    return {
      id: item.id,
      text: `${item.name} - Rp.${item.amount}`,
    };
  });
}

const fetchData = async () => {
  try {
    const response = await axios.get("api/vouchers-bimbel");
    voucher.value = response.data.vouchers;
    voucher.value = transformArray(voucher.value);
    voucher.value.unshift({ id: null, text: "Pilih Voucher" });
  } catch (error) {
    console.error("Error fetching vouchers data:", error);
  }
  
  try {
    const response = await axios.get(`api/courses/${route.params.id}/edit`);
    data.value = response.data.course;
  } catch (error) {
    errors.value = error.response.data;
  }
};

onMounted(() => {
  fetchData();
});
</script>
