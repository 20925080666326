<template>
  <!-- Change Password  -->
  <div class="modal fade custom-modal" id="change-password">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content doctor-profile">
        <div class="modal-header border-bottom-0 justify-content-between">
          <h5 class="modal-title">Change Password</h5>
          <button
            type="button"
            class="close-btn"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <form @submit.prevent="submitFormOne">
            <div class="form-group">
              <label class="col-form-label">Current Password</label>
              <div class="pass-group">
                <input
                  v-if="showPassword"
                  type="text"
                  class="form-control pass-input"
                  v-model="password"
                  placeholder="**********"
                />
                <input
                  v-else
                  type="password"
                  class="form-control pass-input"
                  placeholder="**********"
                  v-model="password"
                />
                <span
                  class="toggle-password"
                  @click="toggleShow"
                  :class="{
                    'feather-eye': showPassword,
                    'feather-eye-off': !showPassword,
                  }"
                ></span>
              </div>
            </div>
            <div class="form-group">
              <label class="col-form-label">New Password</label>
              <div class="pass-group" id="passwordInput">
                <input
                  v-if="showPassword1"
                  type="text"
                  class="form-control pass-input"
                  v-model="password1"
                  placeholder="**********"
                />
                <input
                  v-else
                  type="password"
                  class="form-control pass-input"
                  placeholder="**********"
                  v-model="password1"
                />
                <span
                  class="toggle-password"
                  @click="toggleShow1"
                  :class="{
                    'feather-eye': showPassword1,
                    'feather-eye-off': !showPassword1,
                  }"
                ></span>
              </div>
              <div class="password-strength" id="passwordStrength">
                <span id="poor"></span>
                <span id="weak"></span>
                <span id="strong"></span>
                <span id="heavy"></span>
              </div>
              <div id="passwordInfo">
                Use 8 or more characters with a mix of letters, numbers & symbols.
              </div>
            </div>
            <div class="form-group">
              <label class="col-form-label">Confirm New Password</label>
              <div class="pass-group">
                <input
                  v-if="showPassword2"
                  type="text"
                  class="form-control pass-input"
                  v-model="password2"
                  placeholder="**********"
                />
                <input
                  v-else
                  type="password"
                  class="form-control pass-input"
                  placeholder="**********"
                  v-model="password2"
                />
                <span
                  class="toggle-password"
                  @click="toggleShow2"
                  :class="{
                    'feather-eye': showPassword2,
                    'feather-eye-off': !showPassword2,
                  }"
                ></span>
              </div>
            </div>
            <div class="acc-submit">
              <a href="javascript:;" class="btn btn-secondary">Cancel</a>
              <button class="btn btn-primary" type="submit">Update Password</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Change Phone  -->

  <!-- Change Email -->
  <div class="modal fade custom-modal" id="change-email">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content doctor-profile">
        <div class="modal-header border-bottom-0 justify-content-between">
          <h5 class="modal-title">Change Email</h5>
          <button
            type="button"
            class="close-btn"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <form @submit.prevent="submitFormTwo">
            <div class="wallet-add">
              <div class="form-group">
                <label class="col-form-label">Current Email Address</label>
                <input
                  type="email"
                  class="form-control"
                  placeholder="Enter Email Address"
                />
              </div>
              <div class="form-group">
                <label class="col-form-label"
                  >New Email Address <span class="text-danger">*</span></label
                >
                <input
                  type="email"
                  class="form-control"
                  placeholder="Enter Email Address"
                />
              </div>
              <div class="form-group">
                <label class="col-form-label">Confirm New Password</label>
                <div class="pass-group">
                  <input
                    type="password"
                    class="form-control pass-input"
                    placeholder="*************"
                  />
                  <span class="toggle-password feather-eye-off"></span>
                </div>
                <p class="brief-bio mb-0 mt-2">
                  <i class="feather-alert-circle"></i> New Email Address Only Updated Once
                  You Verified
                </p>
              </div>
              <div class="modal-submit text-end">
                <a
                  href="javascript:;"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                  >Cancel</a
                >
                <button class="btn btn-primary">Change Email</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Change Email -->

  <!-- Change Phone  -->
  <div class="modal fade custom-modal" id="change-phone">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content doctor-profile">
        <div class="modal-header border-bottom-0 justify-content-between">
          <h5 class="modal-title">Change Phone Number</h5>
          <button
            type="button"
            class="close-btn"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <form @submit.prevent="submitFormTwo">
            <div class="wallet-add">
              <div class="form-group">
                <label class="col-form-label">Current Phone Number</label>
                <input
                  class="form-control form-control-lg group_formcontrol"
                  id="phone"
                  name="phone"
                  type="text"
                />
              </div>
              <div class="form-group">
                <label class="col-form-label"
                  >New Phone Number <span class="text-danger">*</span></label
                >
                <input
                  class="form-control form-control-lg group_formcontrol"
                  id="phone1"
                  name="phone"
                  type="text"
                />
                <p class="brief-bio mb-0 mt-2">
                  <i class="feather-alert-circle"></i> New Phone Number Only Updated Once
                  You Verified
                </p>
              </div>
              <div class="form-group">
                <label class="col-form-label">Confirm New Password</label>
                <div class="pass-group">
                  <input
                    type="password"
                    class="form-control pass-input"
                    placeholder="*************"
                  />
                  <span class="toggle-password feather-eye-off"></span>
                </div>
              </div>
              <div class="modal-submit text-end">
                <a
                  href="javascript:;"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                  >Cancel</a
                >
                <button class="btn btn-primary">Change Number</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Change Phone  -->

  <!-- Delete Account -->
  <div class="modal fade custom-modal" id="del-account">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-bottom-0 justify-content-between">
          <h5 class="modal-title">Delete Account ?</h5>
          <button
            type="button"
            class="close-btn"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <div class="write-review">
            <form @submit.prevent="submitForm">
              <p>
                Are you sure you want to delete this account? To delete your account, Type
                your password.
              </p>
              <div class="form-group">
                <label class="col-form-label">Password</label>
                <div class="pass-group">
                  <input
                    type="password"
                    class="form-control pass-input"
                    placeholder="*************"
                  />
                  <span class="toggle-password feather-eye-off"></span>
                </div>
              </div>
              <div class="modal-submit text-end">
                <a
                  href="javascript:;"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                  >Cancel</a
                >
                <button type="submit" class="btn btn-danger">Delete Account</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Account -->
</template>

<script>
export default {
  data() {
    return {
      showPassword: false,
      password: null,
      showPassword1: false,
      password1: null,
      showPassword2: false,
      password2: null,
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
    buttonLabel() {
      return this.showPassword1 ? "Hide" : "Show";
    },
    buttonLabel() {
      return this.showPassword2 ? "Hide" : "Show";
    },
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    toggleShow1() {
      this.showPassword1 = !this.showPassword1;
    },
    toggleShow2() {
      this.showPassword2 = !this.showPassword2;
    },
    submitForm() {
      this.$router.push("/login");
    },
    submitFormOne() {
      this.$router.push("/success");
    },
    submitFormTwo() {
      this.$router.push("/provider-security-settings");
    },
  },
  mounted() {
    if (document.getElementById("passwordInput")) {
      let passwordInput = document
        .getElementById("passwordInput")
        .querySelector('input[type="password"]');
      let passwordStrength = document.getElementById("passwordStrength");
      let passwordInfo = document.getElementById("passwordInfo");

      let poor = passwordStrength.getElementsByClassName("poor")[0];
      let weak = passwordStrength.getElementsByClassName("weak")[0];
      let strong = passwordStrength.getElementsByClassName("strong")[0];
      let heavy = passwordStrength.getElementsByClassName("heavy")[0];

      let poorRegExp = /[a-z]/;
      let weakRegExp = /(?=.*?[0-9])/;
      let strongRegExp = /(?=.*?[#?!@$%^&*-])/;

      let whitespaceRegExp = /^$|\s+/;

      passwordInput.addEventListener("input", function () {
        let passwordValue = passwordInput.value;
        let passwordLength = passwordValue.length;
        let poorPassword = passwordValue.match(poorRegExp);
        let weakPassword = passwordValue.match(weakRegExp);
        let strongPassword = passwordValue.match(strongRegExp);
        let whitespace = passwordValue.match(whitespaceRegExp);

        if (passwordValue !== "") {
          passwordStrength.style.display = "block";
          passwordStrength.style.display = "flex";
          passwordInfo.style.display = "block";
          passwordInfo.style.color = "black";

          if (whitespace) {
            passwordInfo.textContent = "whitespaces are not allowed";
          } else {
            poorPasswordStrength(
              passwordLength,
              poorPassword,
              weakPassword,
              strongPassword
            );
            weakPasswordStrength(
              passwordLength,
              poorPassword,
              weakPassword,
              strongPassword
            );
            strongPasswordStrength(
              passwordLength,
              poorPassword,
              weakPassword,
              strongPassword
            );
            heavyPasswordStrength(
              passwordLength,
              poorPassword,
              weakPassword,
              strongPassword
            );
          }
        } else {
          passwordInfo.style.display = "none";
          removeClass(passwordStrength, "poor-active");
          removeClass(passwordStrength, "avg-active");
          removeClass(passwordStrength, "strong-active");
          removeClass(passwordStrength, "heavy-active");
        }
      });

      function poorPasswordStrength(
        passwordLength,
        poorPassword,
        weakPassword,
        strongPassword
      ) {
        if (passwordLength < 8) {
          addClass(poor, "active");
          addClass(passwordStrength, "poor-active");
          removeClass(passwordStrength, "avg-active");
          removeClass(passwordStrength, "strong-active");
          removeClass(passwordStrength, "heavy-active");
          passwordInfo.style.display = "block";
          passwordInfo.style.color = "#FF0000";
          passwordInfo.innerHTML = "Weak. Must contain at least 8 characters";
        }
      }

      function weakPasswordStrength(
        passwordLength,
        poorPassword,
        weakPassword,
        strongPassword
      ) {
        if (passwordLength >= 8 && (poorPassword || weakPassword || strongPassword)) {
          addClass(weak, "active");
          removeClass(passwordStrength, "poor-active");
          addClass(passwordStrength, "avg-active");
          removeClass(passwordStrength, "strong-active");
          removeClass(passwordStrength, "heavy-active");
          passwordInfo.style.display = "block";
          passwordInfo.style.color = "#FFB54A";
          passwordInfo.innerHTML = "Average. Must contain at least 1 letter or number";
        } else {
          removeClass(weak, "active");
        }
      }

      function strongPasswordStrength(
        passwordLength,
        poorPassword,
        weakPassword,
        strongPassword
      ) {
        if (passwordLength >= 8 && poorPassword && (weakPassword || strongPassword)) {
          addClass(strong, "active");
          removeClass(passwordStrength, "avg-active");
          removeClass(passwordStrength, "poor-active");
          addClass(passwordStrength, "strong-active");
          removeClass(passwordStrength, "heavy-active");
          passwordInfo.innerHTML = "Almost. Must contain a special symbol";
          passwordInfo.style.color = "#1D9CFD";
        } else {
          removeClass(strong, "active");
        }
      }

      function heavyPasswordStrength(
        passwordLength,
        poorPassword,
        weakPassword,
        strongPassword
      ) {
        if (passwordLength >= 8 && poorPassword && weakPassword && strongPassword) {
          addClass(heavy, "active");
          removeClass(passwordStrength, "poor-active");
          removeClass(passwordStrength, "avg-active");
          removeClass(passwordStrength, "strong-active");
          addClass(passwordStrength, "heavy-active");
          passwordInfo.innerHTML = "Awesome! You have a secure password.";
          passwordInfo.style.color = "#159F46";
        } else {
          removeClass(heavy, "active");
        }
      }

      function addClass(element, className) {
        if (element && !element.classList.contains(className)) {
          element.classList.add(className);
        }
      }

      function removeClass(element, className) {
        if (element && element.classList.contains(className)) {
          element.classList.remove(className);
        }
      }
    }
  },
};
</script>
