<template>
    <div class="service-widget">
      <div class="service-img">
        <v-lazy-image
          :src="props.image"
          :src-placeholder="maintenanceImage"
          alt="loading"
          class="img-fluid"
        ></v-lazy-image>
        <div class="fav-item">
          <span class="item-cat">{{ props.badge }}</span>
        </div>
      </div>
      <div class="service-content">
        <h3 class="title">
          {{ props.title }}
        </h3>
        <p v-html="props.desc"></p>
        <div class="serv-info">
          <b>{{ formatPrice(props.price) }}</b>
          <b-button @click="handleButtonClick" class="btn btn-book">{{ props.label }}</b-button>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { defineProps, defineEmits } from "vue";
  import VLazyImage from "v-lazy-image";
  import maintenanceImage from "@/assets/img/placeholder.svg";
  
  const props = defineProps({
    title: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    price: {
      type: String,
      required: true,
    },
    badge: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    desc: {
      type: String,
      required: true,
    },
  });
  
  const emit = defineEmits();
  
  // Function to format price to IDR
  const formatPrice = (price) => {
    if (!price) return "";
    const formatter = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    });
    return formatter.format(Number(price));
  };
  
  // Emit an event when the button is clicked
  const handleButtonClick = () => {
    emit("button-clicked");
  };
  </script>
  
  <style lang="scss" scoped></style>
  