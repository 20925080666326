<template>
  <!-- Footer -->
  <footer class="footer">
    <!-- Footer Top -->
    <div class="footer-top">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <!-- Footer Widget -->
            <div class="footer-widget">
              <div class="footer-logo">
                <router-link to="/"
                  ><img src="@/assets/img/edu.png" alt="logo"
                /></router-link>
              </div>
              <div class="footer-content">
                <p>
                  Examedu adalah sebuah aplikasi simulasi CAT (Computer Assisted Test) online yang lengkap dan menyediakan bank soal yang komprehensif untuk berbagai keperluan, termasuk tes untuk Polri, TNI, dan berbagai instansi pemerintahan lainnya.
                </p>
              </div>
            </div>
            <!-- /Footer Widget -->
          </div>
          <div class="col-lg-3 col-md-6">
            <!-- Footer Widget -->
            <div class="footer-widget footer-contact">
              <h2 class="footer-title">Hubungi Kami</h2>
              <div class="footer-contact-info">
                <div class="footer-address">
                  <p>
                    <span><i class="feather-map-pin"></i></span> Infiniti Office, Indonesia Stock Exchange Tower 1 Level 3, Unit 304, Jl. Jendral Sudirman Kav 52-53, RT 05/ RW 03, Desa/Kelurahan Senayan, Kec. Kebayoran Baru, Kota Adm. Jakarta Selatan, Provinsi DKI Jakarta Kode Pos: 12190
                  </p>
                </div>
                <p>
                  <span><i class="feather-phone"></i></span> (021) 5890 5002
                </p>
                <p>
                  <span><i class="feather-mail"></i></span> examedu.id@gmail.com
                </p>
                <p class="mb-0">
                  <span><i class="feather-globe"></i></span> www.examedu.com
                </p>
              </div>
            </div>
            <!-- /Footer Widget -->
          </div>
          <div class="col-lg-2 col-md-6">
            <!-- Footer Widget -->
            <div class="footer-widget footer-menu">
              <h2 class="footer-title">Tautan langsung</h2>
              <ul>
                <li>
                  <router-link to="/privacy-policy">Privacy Policy</router-link>
                </li>
                <li>
                  <router-link to="/terms-condition">Syarat & Ketentuan</router-link>
                </li>
                <li>
                  <router-link to="/refund">Kebijakan Pengembalian</router-link>
                </li>
              </ul>
            </div>
            <!-- /Footer Widget -->
          </div>
          <div class="col-lg-3 col-md-6">
            <!-- Footer Widget -->
            <div class="footer-widget">
              <div>
                <h2 class="footer-title">Metode Pembayaran</h2>
                <img style="width: 100%" src="@/assets/img/payment.png" alt="logo">
              </div>
            </div>
            <!-- /Footer Widget -->
          </div>
        </div>
      </div>
    </div>
    <!-- /Footer Top -->

    <!-- Footer Bottom -->
    <div class="footer-bottom">
      <div class="container">
        <!-- Copyright -->
        <div class="copyright">
          <div class="row align-items-center">
            <div class="col-md-4">
              <div class="copyright-text">
                <p class="mb-0">Copyright &copy; 2024. All Rights Reserved.</p>
              </div>
            </div>
            <div class="col-md-4">
              <!-- Copyright Menu -->
              <div class="copyright-menu">
                <ul class="policy-menu">
                  <li>
                    <router-link to="/privacy-policy">Privacy Policy</router-link>
                  </li>
                  <li>
                    <router-link to="/terms-condition">Terms & Conditions</router-link>
                  </li>
                </ul>
              </div>
              <!-- /Copyright Menu -->
            </div>
          </div>
        </div>
        <!-- /Copyright -->
      </div>
    </div>
    <!-- /Footer Bottom -->
  </footer>
  <!-- /Footer -->
</template>

<script>
export default {
  data() {
    return {
      lang: ["English", "France", "Spanish"],
      Dollars: ["US Dollars", "INR", "Kuwait"],
    };
  },
};
</script>

