<template>
  <div class="provider-body">
    <div class="main-wrapper">
      <dashboardnavbar />

      <div class="page-wrapper">
        <sidebar />
        <div class="container">
          <div class="content p-5">
            <h6>Riwayat Tryouts</h6>
            <div
              class="tryouts__group_content_list mt-3"
              v-for="(item, index) in data.final_scores"
              @click="onDetail(item)"
              :key="index"
            >
              <div class="tryout__group_content_list_name">
                <h6>
                  {{ item.result_tryout_user.tryout.name }}
                  <span class="status">
                    {{ item.result_tryout_user.status }}</span
                  >
                </h6>
                <div>
                  Jawaban benar :{{ item.true_answer }} <br />
                  Total Soal : {{ item.total_questions }}
                </div>
              </div>
              <div class="tryout__group_content_list_access">
                {{ formatDateTime(item.result_tryout_user.created_at) }}
              </div>
            </div>
            <div style="margin-bottom: 100px">
              <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-center">
                  <li class="page-item">
                    <a :class="page == 1 ? 'disabled' : ''" class="page-link" @click="page > 1 ? page-- : ''"
                      >Prev</a
                    >
                  </li>

                  <li
                    v-if="data.pagination"
                    :class="page == i ? 'active' : ''"
                    class="page-item"
                    v-for="i in data.pagination && data.pagination.lastPage
                      ? data.pagination.lastPage
                      : 0"
                    :key="i"
                  >
                    <a class="page-link" @click="page = i">{{ i }}</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" @click="page = page + 1">Next</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import axios from "axios";
import { useRouter } from "vue-router";
let data = ref({});
let page = ref(1);
const fetchData = async () => {
  try {
    const response = await axios.get(`/api/history?page=${page.value}`);
    data.value = response.data;
  } catch (error) {
  }
};


watch(page, () => {
  fetchData();
  window.scrollTo(0, 0);
});

function formatDateTime(dateTimeString) {
  const dateTime = new Date(dateTimeString);

  const dateOptions = { year: "numeric", month: "long", day: "numeric" };
  const timeOptions = { hour: "numeric", minute: "numeric", second: "numeric" };

  const formattedDate = dateTime.toLocaleDateString("en-US", dateOptions);
  const formattedTime = dateTime.toLocaleTimeString("en-US", timeOptions);

  return `${formattedDate} - ${formattedTime}`;
}

onMounted(() => {
  fetchData();
});

const router = useRouter();
const onDetail = (item) => {
  const genre = item.result_tryout_user.tryout.genre;
  const ID = item.result_tryout_user.id;
  if (genre == "number") {
    router.push({
      path: "/tryouts/kecermatan/" + ID + "/result",
    });
  }else{
    router.push({
      path: "/tryouts/pilihanGanda/" + ID + "/result",
    });
  }
};
</script>

<style scoped>
.page-item {
  overflow: hidden !important;
}
details[open] summary ~ * {
  animation: sweep 0.2s ease-in-out;
}

details[close] summary ~ * {
  animation: sweep 0.2s ease-in-out;
}
.cursor-disabled {
  cursor: not-allowed;
}
@keyframes sweep {
  0% {
    opacity: 0;
    margin-left: -10px;
  }
  100% {
    opacity: 1;
    margin-left: 0px;
  }
}

.tryouts__group {
  padding: 20px;
  border-radius: 10px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.tryouts__group_content {
  margin-top: 20px;
  border-radius: 20px;
}

.tryouts__group_content_list {
  cursor: pointer;
  color: black;
  padding: 10px 30px 10px 30px;
  border-top: 1px solid #22222b44;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: whitesmoke;
}

.tryouts__group_content_list:hover h6 {
  color: white;
}

.status {
  color: orange;
}

.tryouts__group_content_list:hover {
  background: #0167f6;
  color: white;
}

.tryout__group_content_list_access_content {
  width: 100px;
  border-radius: 5px;
  text-align: center;
  padding: 10px;
}

.free {
  background: #ed7f409c;
  color: white;
}

.premium {
  background: #40994091;
  color: white;
}

.vip {
  background: #0167f6;
  color: white;
}
</style>
