<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <div class="content">
        <div class="content-page-header px-3 content-page-headersplit mb-0">
          <h5>Groups</h5>
          <div class="list-btn">
            <router-link to="/admin/groups/create" class="btn btn-primary">
              <i class="fa fa-plus me-2"></i>Add Group
            </router-link>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-resposnive table-div">
              <div class="table datatable">
                <a-table :columns="columns" :data-source="data">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.title === 'Action'">
                      <div class="d-flex flex justify-content-center gap-2">
                        <router-link
                          class="btn delete-table me-2"
                          :to="`/admin/groups/${record.id}/edit`"
                        >
                          <i class="feather feather-edit"></i>
                        </router-link>
                        <button
                          @click="onDelete(record.id)"
                          class="btn delete-table"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#delete"
                        >
                          <i class="feather feather-trash-2"></i>
                        </button>
                      </div>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Confirm
    message="Are you sure you want to delete this group?"
    title="Delete"
    buttonText="delete"
    id="delete"
    :url="URL"
    @submitted="fetchData()"
  />
</template>

<script setup>
import { reactive, computed, ref } from "vue";
import axios from "axios";
import Confirm from "@/components/model/Confirm/index.vue";

const API = process.env.VUE_APP_API_URL;
const URL = ref(null);
const ID = ref();
const BASEURL = ref(process.env.VUE_APP_API_URL + "/api/groups/");

const onDelete = (id) => {
  ID.value = id;
  URL.value = BASEURL.value + ID.value;
};

const columns = ref([
  {
    title: "No",
    dataIndex: "index",
    sorter: {
      compare: (a, b) => a.index - b.index,
    },
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    sorter: {
      compare: (a, b) => {
        a = a.name.toLowerCase();
        b = b.name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Type",
    dataIndex: "type",
    sorter: {
      compare: (a, b) => {
        a = a.type.toLowerCase();
        b = b.type.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "id",
    class: "text-center",
  },
]);

const data = ref([]);

const fetchData = async () => {
  try {
    const response = await axios.get("/api/groups");
    data.value = response.data.groups;
    console.log(data.value);
    data.value = data.value.map((type,index) => ({
      ...type,
      type: type.type
        ? `${type.type.versi.category.name} - ${type.type.versi.name} - ${type.type.name}`
        : "",
        index: index + 1,
    }));
  } catch (error) {
    // Tangani kesalahan jika ada
    console.error("Error while fetching groups data:", error);
    // Lakukan tindakan lain sesuai kebutuhan, seperti menampilkan pesan kesalahan kepada pengguna
  }
};
fetchData();
</script>

<style>
.img {
  width: 140px;
  height: 140px;
  object-fit: cover;
}
</style>
