<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper">
      <div class="content">
        <b-form @submit.prevent="submitForm">
          <div class="row">
            <div class="col-lg-7 col-sm-12 m-auto">
              <div class="content-page-header">
                <h5 class="mb-2">Add Admin</h5>
                <!-- <div class="form-group mb-0">
                      <p class="contentpage">You are editing "English" version</p>
                    </div> -->
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Name</label>
                    <input
                      v-model="data.name"
                      type="text"
                      class="form-control"
                    />
                    <div v-if="errors.name" class="text-danger">
                      {{ errors.name[0] }}
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Email</label>
                    <input
                      v-model="data.email"
                      type="text"
                      class="form-control"
                    />
                    <div v-if="errors.email" class="text-danger">
                      {{ errors.email[0] }}
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Password</label>
                    <input
                      v-model="data.password"
                      type="password"
                      class="form-control"
                    />
                    <div v-if="errors.password" class="text-danger">
                      {{ errors.password[0] }}
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Password Confirmation</label>
                    <input
                      v-model="data.password_confirmation"
                      type="password"
                      class="form-control"
                    />
                    <div
                      v-if="errors.password_confirmation"
                      class="text-danger"
                    >
                      {{ errors.password_confirmation[0] }}
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Phone Number</label>
                    <input
                      v-model="data.phone_number"
                      type="text"
                      class="form-control"
                    />
                    <div v-if="errors.phone_number" class="text-danger">
                      {{ errors.phone_number[0] }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="btn-path">
                    <router-link
                      to="/admin/dataadmin"
                      class="btn btn-cancel me-3"
                      >Cancel</router-link
                    >
                    <b-button type="submit" variant="primary">
                      Add Admin</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import swal from "sweetalert2";
import axios from "axios";

import { useLoading } from "vue-loading-overlay";
import { useRouter } from "vue-router";
let errors = ref({});
const $loading = useLoading({});
const router = useRouter();

let data = ref({
  name: "",
  email: "",
  password: "",
  password_confirmation: "",
  phone_number: "",
});

const submitForm = async () => {
  const loader = $loading.show({});
  const formData = new FormData();
  if (data.value.name) {
    formData.append("name", data.value.name);
  }
  if (data.value.email) {
    formData.append("email", data.value.email);
  }
  if (data.value.password) {
    formData.append("password", data.value.password);
  }
  if (data.value.password_confirmation) {
    formData.append("password_confirmation", data.value.password_confirmation);
  }
  if (data.value.phone_number) {
    formData.append("phone_number", data.value.phone_number);
  }
  try {
    const response = await axios.post("api/users-admin", formData);
    loader.hide();
    swal.fire({ icon: "success", text: response.data.message });
    router.push("/admin/dataadmin");
  } catch (error) {
    loader.hide();
    errors.value = error.response.data;
  }
};
</script>
