<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <div class="content">
        <div class="content-page-header px-3 content-page-headersplit mb-0">
          <h5>Data Users</h5>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-responsive table-div">
              <div class="table datatable">
                <a-table :columns="columns" :data-source="data">
                  <template #bodyCell="{ column, record }">
                    <span v-if="column.key === 'email_verified_at' && record[column.key] === null">
                      Email Belum Terverifikasi
                    </span>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Confirm
    message="Are you sure you want to delete this column?"
    title="Delete"
    buttonText="delete"
    id="delete"
    :url="URL"
    @submitted="fetchData()"
  />
</template>

<script setup>
import { reactive, computed, ref } from "vue";
import axios from "axios";
import Confirm from "@/components/model/Confirm/index.vue";
const URL = ref(null);
const ID = ref();
const BASEURL = ref(process.env.VUE_APP_API_URL + "/api/data-user/");
const onDelete = (id) => {
  ID.value = id;
  URL.value = BASEURL.value + ID.value;
};
const columns = reactive([
  {
    title: "No",
    dataIndex: "index",
    sorter: {
      compare: (a, b) => a.index - b.index,
    },
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    sorter: {
      compare: (a, b) => {
        a = a.name.toLowerCase();
        b = b.name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    sorter: {
      compare: (a, b) => {
        a = a.email.toLowerCase();
        b = b.email.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Phone Number",
    dataIndex: "phone_number",
    key: "phone_number",
    sorter: {
      compare: (a, b) => {
        a = a.phone_number.toLowerCase();
        b = b.phone_number.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Email Verified",
    dataIndex: "email_verified_at",
    key: "email_verified_at",
    sorter: {
      compare: (a, b) => {
        a = a.email_verified_at.toLowerCase();
        b = b.email_verified_at.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    sorter: {
      compare: (a, b) => {
        a = a.type.toLowerCase();
        b = b.type.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Score",
    dataIndex: "score",
    key: "score",
    sorter: {
      compare: (a, b) => {
        a = a.score.toLowerCase();
        b = b.score.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Expired",
    dataIndex: "expired",
    key: "expired",
    sorter: {
      compare: (a, b) => {
        a = a.expired.toLowerCase();
        b = b.expired.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
]);

const data = ref([]);

const fetchData = async () => {
  try {
    const response = await axios.get("/api/data-user");
    data.value = response.data.users.map((category, index) => ({
      ...category,
      index: index + 1,
    }));
  } catch (error) {
    // Handle error if any
    console.error("Error while fetching user data:", error);
    // Perform other actions as needed, such as showing error message to the user
  }
};

fetchData();
</script>
