<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <div class="content">
        <div class="row">
          <div class="col-12">
            <div class="table-resposnive table-div">
              <div class="table">
                <a-table
                  :pagination="false"
                  :columns="columnsTryout"
                  :data-source="tryoutData"
                >
                </a-table>
              </div>
            </div>
          </div>
        </div>
        <div
          class="content-page-header px-3 content-page-headersplit gap-2 mt-3 mb-0"
        >
          <section><h5>Soal Dan Jawaban Tryouts</h5></section>
          <div class="d-flex gap-2">
            <input
              v-model="searchTerm"
              class="form-control mr-sm-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
            <b-button
              @click="filterDatas"
              class="btn btn-primary my-2 my-sm-0"
              type="submit"
            >
              Search
            </b-button>
          </div>
          <div class="d-flex flex justify-content-end px-3">
            <router-link
              :to="`/admin/questions/tryouts/${tryout_id}/create`"
              class="btn btn-primary"
            >
              <i class="fa fa-plus me-2"></i>Tambah Soal Baru
            </router-link>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-resposnive table-div">
              <div class="table datatable">
                <a-table :columns="columns" :data-source="filteredData">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.title === 'Image'">
                      <img
                        v-if="record.image"
                        class="img img-fluid"
                        :src="record.image"
                        alt=""
                        style="max-width: 300px"
                      />
                      <div v-else>Tidak Ada Gambar</div>
                    </template>
                    <!-- <template v-if="column.title === 'icon'">
                      <img class="img img-fluid" :src="record.icon" alt="" />
                    </template> -->
                    <template v-if="column.title === 'Image Choice A'">
                      <img
                        v-if="record.img_choice_a"
                        class="img img-fluid"
                        :src="record.img_choice_a"
                        alt=""
                        style="max-width: 300px"
                      />
                      <div v-else>Tidak Ada Gambar</div>
                    </template>
                    <template v-if="column.title === 'Image Choice B'">
                      <img
                        v-if="record.img_choice_b"
                        class="img img-fluid"
                        :src="record.img_choice_b"
                        alt=""
                        style="max-width: 300px"
                      />
                      <div v-else>Tidak Ada Gambar</div>
                    </template>
                    <template v-if="column.title === 'Image Choice C'">
                      <img
                        v-if="record.img_choice_c"
                        class="img img-fluid"
                        :src="record.img_choice_c"
                        alt=""
                        style="max-width: 300px"
                      />
                      <div v-else>Tidak Ada Gambar</div>
                    </template>
                    <template v-if="column.title === 'Image Choice D'">
                      <img
                        v-if="record.img_choice_d"
                        class="img img-fluid"
                        :src="record.img_choice_d"
                        alt=""
                        style="max-width: 300px"
                      />
                      <div v-else>Tidak Ada Gambar</div>
                    </template>
                    <template v-if="column.title === 'Image Choice E'">
                      <img
                        v-if="record.img_choice_e"
                        class="img img-fluid"
                        :src="record.img_choice_e"
                        alt=""
                        style="max-width: 300px"
                      />
                      <div v-else>Tidak Ada Gambar</div>
                    </template>
                    <template v-if="column.title === 'Action'">
                      <div class="d-flex flex justify-content-center gap-2">
                        <router-link
                          class="btn delete-table me-2"
                          :to="`/admin/questions/tryouts/${route.params.id}/edit/${record.id}`"
                        >
                          <i class="feather feather-edit"></i>
                        </router-link>
                        <button
                          @click="onDelete(record.id, record.image, record.img_choice_a, record.img_choice_b, record.img_choice_c, record.img_choice_d, record.img_choice_e)"
                          class="btn delete-table"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#delete"
                        >
                          <i class="feather feather-trash-2"></i>
                        </button>
                      </div>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Confirm
    message="Are you sure you want to delete this question?"
    title="Delete"
    buttonText="delete"
    id="delete"
    :url="URL"
    :imageURL="imgFirebaseDel"
    @submitted="fetchData()"
  />
</template>

<script setup>
import { reactive, ref } from "vue";
import axios from "axios";
import Confirm from "@/components/model/Confirm/index.vue";
import { useRoute } from "vue-router";
const URL = ref(null);
const ID = ref();
const BASEURL = ref(process.env.VUE_APP_API_URL + "/api/questions/");
const imgFirebaseDel = ref(null);
const img_choice_aFirebaseDel = ref(null);
const img_choice_bFirebaseDel = ref(null);
const img_choice_cFirebaseDel = ref(null);
const img_choice_dFirebaseDel = ref(null);
const img_choice_eFirebaseDel = ref(null);
const onDelete = (id, img, img_choice_a,img_choice_b,img_choice_c,img_choice_d,img_choice_e) => {
  ID.value = id;
  imgFirebaseDel.value = img;
  img_choice_aFirebaseDel.value = img_choice_a;
  img_choice_bFirebaseDel.value = img_choice_b;
  img_choice_cFirebaseDel.value = img_choice_c;
  img_choice_dFirebaseDel.value = img_choice_d;
  img_choice_eFirebaseDel.value = img_choice_e;
  URL.value = BASEURL.value + ID.value;
};

const route = useRoute();

const tryout_id = ref(route.params.id);
let tryoutData = ref([]);

const columnsTryout = reactive([
  {
    title: "Tryouts",
    dataIndex: "name",
    sorter: {
      compare: (a, b) => {
        a = a.name.toLowerCase();
        b = b.name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
]);

const columns = reactive([
{
    title: "No",
    dataIndex: "index",
    sorter: {
      compare: (a, b) => a.index - b.index,
    },
  },
  {
    title: "Image",
    dataIndex: "image",
    sorter: {
      compare: (a, b) => {
        a = a.image.toLowerCase();
        b = b.image.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Questions",
    dataIndex: "questions",
    sorter: {
      compare: (a, b) => {
        a = a.questions.toLowerCase();
        b = b.questions.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Point",
    dataIndex: "point",
    sorter: {
      compare: (a, b) => {
        a = a.point.toLowerCase();
        b = b.point.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Answer",
    dataIndex: "answer",
    key: "answer",
    sorter: {
      compare: (a, b) => {
        a = a.answer.toLowerCase();
        b = b.answer.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Choice A",
    dataIndex: "choice_a",
    key: "choice_a",
    sorter: {
      compare: (a, b) => {
        a = a.choice_a.toLowerCase();
        b = b.choice_a.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Image Choice A",
    dataIndex: "img_choice_a",
    sorter: {
      compare: (a, b) => {
        a = a.image.toLowerCase();
        b = b.image.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Choice B",
    dataIndex: "choice_b",
    key: "choice_b",
    sorter: {
      compare: (a, b) => {
        a = a.choice_b.toLowerCase();
        b = b.choice_b.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Image Choice B",
    dataIndex: "img_choice_b",
    sorter: {
      compare: (a, b) => {
        a = a.image.toLowerCase();
        b = b.image.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Choice C",
    dataIndex: "choice_c",
    key: "choice_c",
    sorter: {
      compare: (a, b) => {
        a = a.choice_c.toLowerCase();
        b = b.choice_c.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  
  {
    title: "Image Choice C",
    dataIndex: "img_choice_c",
    sorter: {
      compare: (a, b) => {
        a = a.image.toLowerCase();
        b = b.image.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Choice D",
    dataIndex: "choice_d",
    key: "choice_d",
    sorter: {
      compare: (a, b) => {
        a = a.choice_d.toLowerCase();
        b = b.choice_d.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Image Choice D",
    dataIndex: "img_choice_d",
    sorter: {
      compare: (a, b) => {
        a = a.image.toLowerCase();
        b = b.image.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Choice E",
    dataIndex: "choice_e",
    key: "choice_e",
    sorter: {
      compare: (a, b) => {
        a = a.choice_e.toLowerCase();
        b = b.choice_e.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Image Choice E",
    dataIndex: "img_choice_e",
    sorter: {
      compare: (a, b) => {
        a = a.image.toLowerCase();
        b = b.image.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Column",
    dataIndex: "column",
    sorter: {
      compare: (a, b) => {
        a = a.column.toLowerCase();
        b = b.column.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Type",
    dataIndex: "type",
    sorter: {
      compare: (a, b) => {
        a = a.type.toLowerCase();
        b = b.type.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "id",
    sorter: true,
    class: "text-center",
  },
]);

const data = ref([]);

let filteredData = ref([]);
let searchTerm = ref("");

// Function to filter payments based on transaction number
const filterDatas = () => {
  filteredData.value = data.value.filter((data) =>
    data.questions.includes(searchTerm.value)
  );
};

const fetchData = async () => {
  try {
    const response = await axios.get(
      "/api/questions/tryout/" + route.params.id
    );
    data.value = response.data.questions.map((column,index) => ({
      ...column,
      column: column.column.name,
      index: index + 1,
    }));
    console.log(data.value);
    filterDatas();
  } catch (error) {
    console.error("Error while fetching questions:", error);
  }

  try {
    const response = await axios.get(`api/tryouts/${route.params.id}/edit`);
    tryoutData.value[0] = response.data.tryout;
  } catch (error) {
    console.error("Error while fetching tryout data:", error);
  }
};

fetchData();
</script>

<style>
.img {
  width: 140px;
  height: 140px;
  object-fit: cover;
}
</style>
