<template>
  <div class="breadcrumb-bar">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h2 class="breadcrumb-title mb-0">{{ title }}</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>
