<template>
  <div class="provider-body">
    <div class="main-wrapper">
      <dashboardnavbar />

      <div class="page-wrapper">
        <sidebar />
        <div
          class="container-fluid mx-auto justify-content-center row pt-3 gap-4 mt-2"
        >
          <div class="col-md-12 d-flex justify-content-center">
            <div class="col-auto p-3 timer">Timer : {{ formattedTime }}</div>
          </div>
          <div class="col-md-12 col-12 col-xl-3"></div>
        </div>
        <div
          v-if="data[page]"
          class="container-fluid mx-auto justify-content-center row p-3 gap-4"
        >
          <div
            class="col-md-12 col-xl-8"
            v-if="data[page].column.hints.length > 0"
          >
            <div class="content mb-3">Petunjuk :</div>
            <div v-for="index in data[page].column.hints">
              <section v-if="index.time">
                <div v-if="hint" class="content mb-2">
                  <div v-html="index.description"></div>
                  <div v-if="index.image">
                    <img class="img-fluid mb-4" :src="index.image" />
                  </div>
                </div>
              </section>
              <section v-else class="content mb-2">
                <div v-html="index.description"></div>
                <div v-if="index.image">
                  <img class="img-fluid mb-4" :src="index.image" />
                </div>
              </section>
            </div>
            <div class="mt-3 d-flex justify-content-end" style="width: 100%">
              <button
                v-if="hint"
                @click="onClickHint()"
                class="w-max btn btn-primary"
              >
                Mulai
              </button>
            </div>
          </div>
          <!-- <div v-else class="col-md-12 col-xl-8">
            <div class="content">Tidak Ada Petunjuk</div>
          </div> -->
          <!-- <div class="col-md-12 col-12 col-xl-3" v-if="!hint">
            <div class="soal-mobile content">
              <div class="text-center">Soal</div>
              <div class="answer_wrapper">
                <div
                  @click="backable ? (page = index - 1) : page"
                  :class="{
                    active:
                      selected[index - 1] && selected[index - 1].answer_user,
                    active_now: page + 1 == index,
                    active_mul:
                      (selected[index - 1] && selected[index - 1].a) ||
                      (selected[index - 1] && selected[index - 1].b) ||
                      (selected[index - 1] && selected[index - 1].c) ||
                      (selected[index - 1] && selected[index - 1].d) ||
                      (selected[index - 1] && selected[index - 1].e),
                  }"
                  class="answer"
                  v-for="index in data.length"
                >
                  {{ index }}
                </div>
              </div>
            </div>
          </div> -->
        </div>
        <div
          v-if="data[page] && hint == false"
          class="container-fluid mx-auto justify-content-center row p-3 gap-4"
        >
          <div class="col-md-12 col-xl-8">
            <div class="content mb-3">Soal No : {{ page + 1 }}</div>
            <div v-if="data[page].type == 'image'" class="content mb-3">
              <div class="row gap-2 justify-content-center">
                <div class="col-auto">
                  <div
                    v-if="data[page].img_choice_a && data[page].type == 'image'"
                  >
                    <img class="type_img mb-4" :src="data[page].img_choice_a" />
                    <center>A</center>
                  </div>
                </div>
                <div class="col-auto">
                  <div
                    v-if="data[page].img_choice_b && data[page].type == 'image'"
                  >
                    <img class="type_img mb-4" :src="data[page].img_choice_b" />
                    <center>B</center>
                  </div>
                </div>
                <div class="col-auto">
                  <div
                    v-if="data[page].img_choice_c && data[page].type == 'image'"
                  >
                    <img class="type_img mb-4" :src="data[page].img_choice_c" />
                    <center>C</center>
                  </div>
                </div>
                <div class="col-auto">
                  <div
                    v-if="data[page].img_choice_d && data[page].type == 'image'"
                  >
                    <img class="type_img mb-4" :src="data[page].img_choice_d" />
                    <center>D</center>
                  </div>
                </div>
                <div class="col-auto">
                  <div
                    v-if="data[page].img_choice_e && data[page].type == 'image'"
                  >
                    <img class="type_img mb-4" :src="data[page].img_choice_e" />
                    <center>E</center>
                  </div>
                </div>
              </div>
            </div>
            <div class="content">
              <div v-html="data[page].questions"></div>
              <div
                v-if="
                  (data[page].image && data[page].type == 'image') ||
                  data[page].type == 'single' ||
                  data[page].type == 'multiple'
                "
              >
                <img
                  v-if="data[page].image"
                  class="img-fluid type_img"
                  :src="data[page].image"
                />
              </div>
            </div>
            <div
              class="content mt-3"
              v-if="data[page].type == 'single' || data[page].type == 'image'"
            >
              <label class="custom_radio" v-if="data[page].choice_a">
                <input
                  type="radio"
                  v-model="selected[page]"
                  @change="
                    onSend({
                      question_id: data[page].id,
                      answer_user: data[page].choice_a,
                      result_tryout_user_id: Result_RTO,
                      a: selected[page].a,
                      b: selected[page].b,
                      c: selected[page].c,
                      d: selected[page].d,
                      e: selected[page].e,
                    })
                  "
                  :value="{
                    question_id: data[page].id,
                    answer_user: data[page].choice_a,
                    result_tryout_user_id: Result_RTO,
                    a: selected[page].a,
                    b: selected[page].b,
                    c: selected[page].c,
                    d: selected[page].d,
                    e: selected[page].e,
                  }"
                  name="payment"
                  class="card-payment"
                />
                <span class="checkmark"></span>
                <span>A) {{ data[page].choice_a }}</span>
                <span>
                  <div
                    v-if="data[page].img_choice_a && data[page].type != 'image'"
                  >
                    <img
                      class="img-fluid mb-4"
                      :src="data[page].img_choice_a"
                    />
                  </div>
                </span>
              </label>
              <label class="custom_radio" v-if="data[page].choice_b != null">
                <input
                  type="radio"
                  v-model="selected[page]"
                  @change="
                    onSend({
                      question_id: data[page].id,
                      answer_user: data[page].choice_b,
                      result_tryout_user_id: Result_RTO,
                      a: selected[page].a,
                      b: selected[page].b,
                      c: selected[page].c,
                      d: selected[page].d,
                      e: selected[page].e,
                    })
                  "
                  :value="{
                    question_id: data[page].id,
                    answer_user: data[page].choice_b,
                    result_tryout_user_id: Result_RTO,
                    a: selected[page].a,
                    b: selected[page].b,
                    c: selected[page].c,
                    d: selected[page].d,
                    e: selected[page].e,
                  }"
                  name="payment"
                  class="card-payment"
                />
                <span class="checkmark"></span>
                <span>B) {{ data[page].choice_b }}</span>
                <div
                  v-if="data[page].img_choice_b && data[page].type != 'image'"
                >
                  <img class="img-fluid mb-4" :src="data[page].img_choice_b" />
                </div>
              </label>
              <label class="custom_radio" v-if="data[page].choice_c != null">
                <input
                  type="radio"
                  v-model="selected[page]"
                  @change="
                    onSend({
                      question_id: data[page].id,
                      answer_user: data[page].choice_c,
                      result_tryout_user_id: Result_RTO,
                      a: selected[page].a,
                      b: selected[page].b,
                      c: selected[page].c,
                      d: selected[page].d,
                      e: selected[page].e,
                    })
                  "
                  :value="{
                    question_id: data[page].id,
                    answer_user: data[page].choice_c,
                    result_tryout_user_id: Result_RTO,
                    a: selected[page].a,
                    b: selected[page].b,
                    c: selected[page].c,
                    d: selected[page].d,
                    e: selected[page].e,
                  }"
                  name="payment"
                  class="card-payment"
                />
                <span class="checkmark"></span>
                <span>C) {{ data[page].choice_c }}</span>
                <div
                  v-if="data[page].img_choice_c && data[page].type != 'image'"
                >
                  <img class="img-fluid mb-4" :src="data[page].img_choice_c" />
                </div>
              </label>
              <label class="custom_radio" v-if="data[page].choice_d != null">
                <input
                  type="radio"
                  v-model="selected[page]"
                  @change="
                    onSend({
                      question_id: data[page].id,
                      answer_user: data[page].choice_d,
                      result_tryout_user_id: Result_RTO,
                      a: selected[page].a,
                      b: selected[page].b,
                      c: selected[page].c,
                      d: selected[page].d,
                      e: selected[page].e,
                      a: selected[page].a,
                      b: selected[page].b,
                      c: selected[page].c,
                      d: selected[page].d,
                      e: selected[page].e,
                    })
                  "
                  :value="{
                    question_id: data[page].id,
                    answer_user: data[page].choice_d,
                    result_tryout_user_id: Result_RTO,
                    a: selected[page].a,
                    b: selected[page].b,
                    c: selected[page].c,
                    d: selected[page].d,
                    e: selected[page].e,
                    a: selected[page].a,
                    b: selected[page].b,
                    c: selected[page].c,
                    d: selected[page].d,
                    e: selected[page].e,
                  }"
                  name="payment"
                  class="card-payment"
                />
                <span class="checkmark"></span>
                <span>D) {{ data[page].choice_d }}</span>
                <div
                  v-if="data[page].img_choice_d && data[page].type != 'image'"
                >
                  <img class="img-fluid mb-4" :src="data[page].img_choice_d" />
                </div>
              </label>
              <label class="custom_radio" v-if="data[page].choice_e != null">
                <input
                  type="radio"
                  v-model="selected[page]"
                  @change="
                    onSend({
                      question_id: data[page].id,
                      answer_user: data[page].choice_e,
                      result_tryout_user_id: Result_RTO,
                      a: selected[page].a,
                      b: selected[page].b,
                      c: selected[page].c,
                      d: selected[page].d,
                      e: selected[page].e,
                    })
                  "
                  :value="{
                    question_id: data[page].id,
                    answer_user: data[page].choice_e,
                    result_tryout_user_id: Result_RTO,
                    a: selected[page].a,
                    b: selected[page].b,
                    c: selected[page].c,
                    d: selected[page].d,
                    e: selected[page].e,
                  }"
                  name="payment"
                  class="card-payment"
                />
                <span class="checkmark"></span>
                <span>E) {{ data[page].choice_e }}</span>
                <div
                  v-if="data[page].img_choice_e && data[page].type != 'image'"
                >
                  <img class="img-fluid mb-4" :src="data[page].img_choice_e" />
                </div>
              </label>
            </div>

            <div class="content mt-3" v-else-if="data[page].type == 'campuran'">
              <div class="row justify-content-center">
                <div
                  class="col-auto"
                  v-for="(number, index) in answerDeret"
                  :key="index"
                >
                  <label class="custom_check d-block">
                    <input
                      type="checkbox"
                      v-model="number.chosen"
                      class="card-payment"
                    />
                    <span class="checkmark"></span>
                    <span>{{ number.number }}</span>
                  </label>
                </div>
              </div>
            </div>

            <div class="content mt-3" v-else>
              <label
                class="custom_check d-block"
                v-if="data[page].choice_a != null"
              >
                <input
                  type="checkbox"
                  v-model="selected[page].a"
                  @change="
                    onSend({
                      question_id: data[page].id,
                      answer_user: 'multiple choice',
                      result_tryout_user_id: Result_RTO,
                      a: selected[page].a,
                      b: selected[page].b,
                      c: selected[page].c,
                      d: selected[page].d,
                      e: selected[page].e,
                    })
                  "
                  :value="{
                    question_id: data[page].id,
                    answer_user: 'multiple choice',
                    result_tryout_user_id: Result_RTO,
                    a: selected[page].a,
                    b: selected[page].b,
                    c: selected[page].c,
                    d: selected[page].d,
                    e: selected[page].e,
                  }"
                  name="payment"
                  class="card-payment"
                />
                <span class="checkmark"></span>
                <span>A) {{ data[page].choice_a }}</span>
              </label>
              <label
                class="custom_check d-block"
                v-if="data[page].choice_b != null"
              >
                <input
                  type="checkbox"
                  v-model="selected[page].b"
                  @change="
                    onSend({
                      question_id: data[page].id,
                      answer_user: 'multiple choice',
                      result_tryout_user_id: Result_RTO,
                      a: selected[page].a,
                      b: selected[page].b,
                      c: selected[page].c,
                      d: selected[page].d,
                      e: selected[page].e,
                    })
                  "
                  :value="{
                    question_id: data[page].id,
                    answer_user: 'multiple choice',
                    result_tryout_user_id: Result_RTO,
                    a: selected[page].a,
                    b: selected[page].b,
                    c: selected[page].c,
                    d: selected[page].d,
                    e: selected[page].e,
                  }"
                  name="payment"
                  class="card-payment"
                />
                <span class="checkmark"></span>
                <span>B) {{ data[page].choice_b }}</span>
              </label>
              <label
                class="custom_check d-block"
                v-if="data[page].choice_c != null"
              >
                <input
                  type="checkbox"
                  v-model="selected[page].c"
                  @change="
                    onSend({
                      question_id: data[page].id,
                      answer_user: 'multiple choice',
                      result_tryout_user_id: Result_RTO,
                      a: selected[page].a,
                      b: selected[page].b,
                      c: selected[page].c,
                      d: selected[page].d,
                      e: selected[page].e,
                    })
                  "
                  :value="{
                    question_id: data[page].id,
                    answer_user: 'multiple choice',
                    result_tryout_user_id: Result_RTO,
                    a: selected[page].a,
                    b: selected[page].b,
                    c: selected[page].c,
                    d: selected[page].d,
                    e: selected[page].e,
                  }"
                  name="payment"
                  class="card-payment"
                />
                <span class="checkmark"></span>
                <span>C) {{ data[page].choice_c }}</span>
              </label>
              <label
                class="custom_check d-block"
                v-if="data[page].choice_d != null"
              >
                <input
                  type="checkbox"
                  v-model="selected[page].d"
                  @change="
                    onSend({
                      question_id: data[page].id,
                      answer_user: 'multiple choice',
                      result_tryout_user_id: Result_RTO,
                      a: selected[page].a,
                      b: selected[page].b,
                      c: selected[page].c,
                      d: selected[page].d,
                      e: selected[page].e,
                    })
                  "
                  :value="{
                    question_id: data[page].id,
                    answer_user: 'multiple choice',
                    result_tryout_user_id: Result_RTO,
                    a: selected[page].a,
                    b: selected[page].b,
                    c: selected[page].c,
                    d: selected[page].d,
                    e: selected[page].e,
                  }"
                  name="payment"
                  class="card-payment"
                />
                <span class="checkmark"></span>
                <span>D) {{ data[page].choice_d }}</span>
              </label>
              <label
                class="custom_check d-block"
                v-if="data[page].choice_e != null"
              >
                <input
                  type="checkbox"
                  v-model="selected[page].e"
                  @change="
                    onSend({
                      question_id: data[page].id,
                      answer_user: 'multiple choice',
                      result_tryout_user_id: Result_RTO,
                      a: data[page].a,
                      b: data[page].b,
                      c: data[page].c,
                      d: data[page].d,
                      e: data[page].e,
                    })
                  "
                  :value="{
                    question_id: data[page].id,
                    answer_user: 'multiple choice',
                    result_tryout_user_id: Result_RTO,
                    a: data[page].a,
                    b: data[page].b,
                    c: data[page].c,
                    d: data[page].d,
                    e: data[page].e,
                  }"
                  name="payment"
                  class="card-payment"
                />
                <span class="checkmark"></span>
                <span>E) {{ data[page].choice_e }}</span>
              </label>
            </div>
            <div class="mt-3 d-flex gap-3 justify-content-end">
              <button
                v-if="page > 0 && backable"
                @click="page - 1 >= 0 ? page-- : page"
                class="w-max btn btn-outline-primary"
              >
                Soal Sebelumnya
              </button>
              <button
                v-if="page < data.length - 1 && data[page].type == 'campuran'"
                @click="onContinueCampuran"
                class="w-max btn btn-primary"
              >
                Soal Selanjutnya
              </button>
              <button
                v-else-if="page < data.length - 1"
                @click="page < data.length - 1 ? page++ : page"
                class="w-max btn btn-primary"
              >
                Soal Selanjutnya
              </button>
              <button
                v-else
                @click="onSubmit(Result_RTO)"
                class="w-max btn btn-primary"
              >
                Submit
              </button>
            </div>
          </div>
          <!-- <div class="col-md-12 col-12 col-xl-3" v-if="!hint">
            <div class="soal-desktop content">
              <div class="mb-3 text-center">Soal</div>
              <div class="answer_wrapper">
                <div
                  @click="backable ? (page = index - 1) : page"
                  :class="{
                    active:
                      selected[index - 1] && selected[index - 1].answer_user,
                    active_now: page + 1 == index,
                    active_mul:
                      (selected[index - 1] && selected[index - 1].a) ||
                      (selected[index - 1] && selected[index - 1].b) ||
                      (selected[index - 1] && selected[index - 1].c) ||
                      (selected[index - 1] && selected[index - 1].d) ||
                      (selected[index - 1] && selected[index - 1].e),
                  }"
                  class="answer"
                  v-for="index in data.length"
                >
                  {{ index }}
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import axios from "axios";
import { onMounted, ref, onBeforeUnmount, watch, computed } from "vue";
import { usePilihanGandaStore } from "@/stores/pilihanGandaStore";
import { useRoute, useRouter } from "vue-router";
import swal from "sweetalert2";
import { useAuthStore } from "@/stores/authStore";
let timoutData = ref(null);
let pilihanGandaStore = usePilihanGandaStore();
let data = ref([]);
let page = ref(0);
let selected = ref([]);
let timeState = ref(null);
const route = useRoute();
const Result_RTO = route.params.id;
const router = useRouter();

let answerDeret = ref([
  {
    number: 1,
    chosen: false,
  },
  {
    number: 2,
    chosen: false,
  },
  {
    number: 3,
    chosen: false,
  },
  {
    number: 4,
    chosen: false,
  },
  {
    number: 5,
    chosen: false,
  },
  {
    number: 6,
    chosen: false,
  },
  {
    number: 7,
    chosen: false,
  },
  {
    number: 8,
    chosen: false,
  },
  {
    number: 9,
    chosen: false,
  },
  {
    number: 0,
    chosen: false,
  },
]);

let chosenNumbers = computed(() => {
  return answerDeret.value.filter((item) => item.chosen);
});
let TRYOUT_ID = ref(null);

let onContinueCampuran = () => {
  let newData = {
    question_id: data.value[page.value].id,
    answer_user: chosenNumbers.value.map((item) => item.number).join(""),
    result_tryout_user_id: Result_RTO,
    a: false,
    b: false,
    c: false,
    d: false,
    e: false,
    tryout_id: TRYOUT_ID.value,
    time_spend: spendTime(),
  };

  try {
    pilihanGandaStore.setData(newData);
    page.value < data.value.length - 1 ? page.value++ : page.value;
  } catch (error) {
    console.error("Error while submit answer:", error);
  }
};

const onSubmit = async () => {
  clearInterval(timer.value);
  if (data.value[page.value].type == "campuran") {
    let newData = {
      question_id: data.value[page.value].id,
      answer_user: chosenNumbers.value.map((item) => item.number).join(""),
      result_tryout_user_id: Result_RTO,
      a: false,
      b: false,
      c: false,
      d: false,
      e: false,
      tryout_id: TRYOUT_ID.value,
      time_spend: spendTime(),
    };
    try {
      pilihanGandaStore.setData(newData);
    } catch (error) {
      console.error("Error while submit answer:", error);
    }
  }
  try {
    await axios.put("/api/update-status/" + Result_RTO, {
      status: "done",
    });

    swal.fire({ icon: "success", text: "Quizz selesai!" });
    router.push({ path: `/tryouts/campuran/${Result_RTO}/result` });
  } catch (error) {
    console.error("Error while updating status:", error);
    // Tindakan lain yang sesuai untuk menangani kesalahan
  }
};
const dateToTime = (start) => {
  let targetDate = new Date(timeState.value);
  let currentDate = new Date(start);
  let currentTime = Math.floor(currentDate.getTime() / 1000);
  let targetTime = Math.floor(targetDate.getTime() / 1000);
  let timeDiff = targetTime - currentTime;
  return timeDiff;
};

let spend_time = ref(0);
const spendTime = () => {
  pilihanGandaStore.setTime(spend_time.value);
  // spend_time.value = 0;
  return pilihanGandaStore.getTime();
};

const onSend = async (params) => {
  params.time_spend = spendTime();
  params.tryout_id = TRYOUT_ID.value;
  if (params.answer_user == null) {
    ("multiple answer");
  }
  pilihanGandaStore.setData(params);
  // getCurrentAnswer(data.value[page.value].id);
  if (time.value == 0) {
    stopTimer();
    swal.fire({ icon: "success", text: "Waktu Hasbis! Quizz selesai!" });
    try {
      await axios.put("/api/update-status/" + Result_RTO, {
        status: "done",
      });

      router.push({ path: `/tryouts/campuran/${Result_RTO}/result` });
    } catch (error) {
      console.error("Error while updating status:", error);
      // Tindakan lain yang sesuai untuk menangani kesalahan
    }

    pilihanGandaStore.setData(params);
  }
};

const getCurrentAnswer = async () => {
  await pilihanGandaStore.setDB(Result_RTO);
  pilihanGandaStore.question_anser_user.forEach((stateItem) => {
    const index = selected.value.findIndex(
      (item) => item.question_id == stateItem.question_id
    );
    // console.log('index', index);
    if (index !== -1) {
      selected.value[index].answer_user = stateItem.answer_user;
      selected.value[index].a = stateItem.a;
      selected.value[index].b = stateItem.b;
      selected.value[index].c = stateItem.c;
      selected.value[index].d = stateItem.d;
      selected.value[index].e = stateItem.e;
    }
  });
};
// Define the timer logic
const timer = ref(null);
const time = ref(null);
const formattedTime = ref(null);

function formatTime(seconds) {
  const hours = Math.floor(seconds / 3600); // Menghitung jumlah jam
  const minutes = Math.floor((seconds % 3600) / 60); // Menghitung jumlah menit
  const remainingSeconds = seconds % 60; // Menghitung jumlah detik
  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`; // Menggabungkan jam, menit, dan detik
}

function startTimer(type) {
  if (time.value <= 0) {
    formattedTime.value = "Waktu Habis";
    clearInterval(timer.value);
    return;
  }
  timer.value = setInterval(() => {
    time.value--;
    spend_time.value++;
    formattedTime.value = formatTime(time.value);

    if (time.value === 0) {
      clearInterval(timer.value);
      if (time.value === 0 && type != "hint") {
        clearInterval(timer.value);
        stopTimer();
      } else {
        clearInterval(timer.value);
        onClickHint();
      }
    }
  }, 1000);
}
let doneIndex = ref([]);
async function stopTimer() {
  if (data.value[page.value]) {
    let currentColumnId = data.value[page.value].column.id;
    const currentIndex = page.value;

    // Check if the current column is not the last one
    if (currentIndex < data.value.length - 1) {
      // Check if the next column is different and not included in doneIndex
      let nextColumnId = data.value[currentIndex + 1].column.id;
      if (
        nextColumnId !== currentColumnId &&
        doneIndex.value.indexOf(currentIndex + 1) === -1
      ) {
        clearInterval(timer.value);
        doneIndex.value.push(currentIndex);
        page.value = currentIndex + 1; // Move to the next column
      } else {
        // If the conditions are not met, move to the next page without advancing the column
        page.value++;
        stopTimer();
      }
    } else {
      // If the current column is the last one, proceed to the else block
      clearInterval(timer.value);
      try {
        await axios.put("/api/update-status/" + Result_RTO, {
          status: "done",
        });

        swal.fire({ icon: "success", text: "Quizz selesai!" });
        router.push({ path: `/tryouts/campuran/${Result_RTO}/result` });
      } catch (err) {
        console.error("Error while updating status:", err);
        // Tindakan lain yang sesuai untuk menangani kesalahan
      }
    }
  } else {
    clearInterval(timer.value);
    try {
      await axios.put("/api/update-status/" + Result_RTO, {
        status: "done",
      });

      swal.fire({ icon: "success", text: "Quizz selesai!" });
      router.push({ path: `/tryouts/campuran/${Result_RTO}/result` });
    } catch (err) {
      console.error("Error while updating status:", err);
      // Tindakan lain yang sesuai untuk menangani kesalahan
    }
  }
}

const checkTimeOut = (status) => {
  if (status == "done") {
    stopTimer();
    swal.fire({ icon: "error", text: "Waktu kamu sudah habis" });
    router.push({ path: `/tryouts/campuran/${Result_RTO}/result` });
  }
  if (time.value <= 0) {
    swal.fire({ icon: "error", text: "Waktu kamu sudah habis" });
    // router.push({ path: `/tryouts/kecermatan/${Result_RTO}/result` });
  }
};

function ubahFormatTanggal(tanggal) {
  // Buat objek Date dari string input
  let dateObj = new Date(tanggal);

  // Ambil bagian tanggal, bulan, dan tahun
  let tahun = dateObj.getFullYear();
  let bulan = String(dateObj.getMonth() + 1).padStart(2, "0");
  let tanggalPart = String(dateObj.getDate()).padStart(2, "0");

  // Ambil bagian jam dan menit
  let jam = String(dateObj.getHours()).padStart(2, "0");
  let menit = String(dateObj.getMinutes()).padStart(2, "0");

  // Gabungkan kembali dalam format yang diinginkan
  return `${tahun}-${bulan}-${tanggalPart}T${jam}:${menit}`;
}

const backable = ref(false);

function timeout(minutes) {
  var now = new Date();
  var futureTime = new Date(now.getTime() + minutes * 60000); // Menambahkan menit ke waktu sekarang

  var year = futureTime.getFullYear();
  var month = ("0" + (futureTime.getMonth() + 1)).slice(-2); // Menambahkan 1 karena bulan dimulai dari 0
  var day = ("0" + futureTime.getDate()).slice(-2);
  var hours = ("0" + futureTime.getHours()).slice(-2);
  var minutes = ("0" + futureTime.getMinutes()).slice(-2);
  var seconds = ("0" + futureTime.getSeconds()).slice(-2);

  var formattedDateTime =
    year +
    "-" +
    month +
    "-" +
    day +
    " " +
    hours +
    ":" +
    minutes +
    ":" +
    seconds;
  return formattedDateTime;
}

let hint = ref(true);

const fetchData = async () => {
  try {
    const response = await axios.get(
      `api/result_tryout_users/${Result_RTO}/edit`
    );
    TRYOUT_ID.value = response.data.result.tryout_id;
    const res = await axios.get(
      `/api/tryout/${response.data.result.tryout_id}/questions`
    );
    console.log(res.data.questions[0]);
    let timerColumn = res.data.questions[0].column.hints[0].time;
    if (timerColumn) {
      timoutData.value = ubahFormatTanggal(timeout(timerColumn));
      timeState.value = timoutData.value;
      time.value = dateToTime(ubahFormatTanggal(timeout(0)));
      formattedTime.value = formatTime(time.value);
      checkTimeOut(response.data.result.status);
      startTimer("hint");
    } else {
      formattedTime.value = "---:--";
    }
    backable.value = res.data.questions[0].tryout.backable ? true : false;
    data.value = res.data.questions;
    console.log(data.value);
    for (let index = 0; index < data.value.length; index++) {
      selected.value.push({
        question_id: data.value[index].id,
        answer_user: null,
        result_tryout_user_id: Result_RTO,
      });
    }
    await getCurrentAnswer();
  } catch (error) {
    console.error("An error occurred:", error);
  }
};

// logic hint ( petunjuk )
const onClickHint = async () => {
  hint.value = false;
  clearInterval(timer.value);
  let timerColumn = data.value[page.value].column.time;
  timoutData.value = ubahFormatTanggal(timeout(timerColumn));
  timeState.value = timoutData.value;
  time.value = dateToTime(ubahFormatTanggal(timeout(0)));
  formattedTime.value = formatTime(time.value);
  startTimer("soal");
};

watch(
  () => page.value,
  async (newValue, oldValue) => {
    answerDeret.value = [
      {
        number: 1,
        chosen: false,
      },
      {
        number: 2,
        chosen: false,
      },
      {
        number: 3,
        chosen: false,
      },
      {
        number: 4,
        chosen: false,
      },
      {
        number: 5,
        chosen: false,
      },
      {
        number: 6,
        chosen: false,
      },
      {
        number: 7,
        chosen: false,
      },
      {
        number: 8,
        chosen: false,
      },
      {
        number: 9,
        chosen: false,
      },
      {
        number: 0,
        chosen: false,
      },
    ];
    let newID = data.value[newValue].column.id;
    let oldID = data.value[oldValue].column.id;
    if (newID != oldID) {
      clearInterval(timer.value);
      if (data.value[newValue].column.hints.length > 0) {
        if (data.value[newValue].column.hints[0].time) {
          hint.value = true;
          let timerColumn = data.value[newValue].column.hints[0].time;
          timoutData.value = ubahFormatTanggal(timeout(timerColumn));
          timeState.value = timoutData.value;
          time.value = dateToTime(ubahFormatTanggal(timeout(0)));
          formattedTime.value = formatTime(time.value);
          startTimer();
        }
      } else {
        let timerColumn = data.value[newValue].column.time;
        timoutData.value = ubahFormatTanggal(timeout(timerColumn));
        timeState.value = timoutData.value;
        time.value = dateToTime(ubahFormatTanggal(timeout(0)));
        formattedTime.value = formatTime(time.value);
        startTimer();
      }
    }
  }
);

onBeforeUnmount(() => {
  stopTimer();
});
onMounted(() => {
  fetchData();
});
</script>

<style scoped>
.type_img {
  width: 250px;
  height: 250px;
  object-fit: cover;
}

.answer_wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  /* background-color: #409940; */
  max-height: 420px !important;
  overflow-y: scroll;
}

.btn-outline-primary {
  border-radius: 20px;
}

.btn-primary {
  border-radius: 20px;
}
.w-max {
  width: max-content;
}
.answer {
  width: 56px;
  height: 56px;
  border-radius: 20px;
  border: solid 1px;
  border-color: #1365df;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.answer:hover {
  background-color: #1365df;
  color: white;
}

.active {
  background-color: #409940;
  border: none;
  color: white;
}

.active_now {
  background-color: #1365df;
  color: white;
}

.active_mul {
  background-color: #409940;
  color: white;
}

.content {
  padding: 30px;
  border-radius: 30px;
  background: rgb(235, 235, 235);
}
.content_header {
  display: flex;
  justify-content: center;
  width: 100%;
}
.timer {
  background: #409940;
  color: white;
  border-radius: 20px;
  width: max-content;
}
.card_test_kecermatan {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Align content vertically center */
  align-items: center;
  background: #1365df;
  color: white;
}

.card_test_kecermatan__content {
  height: 100%; /* Fill up the height of the card */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Align content vertically center */
  align-items: center;
}

.card_test_kecermatan_lost {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Align content vertically center */
  align-items: center;
  background: white;
  color: black;
  transition: all 0.3s ease;
}

.card_test_kecermatan__content_lost {
  height: 100%; /* Fill up the height of the card */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Align content vertically center */
  align-items: center;
}

.card_test_kecermatan_lost:hover {
  background: #1365df;
  color: white;
}

.card_test_body {
  margin-top: 5px; /* Adjust as needed */
}

.cursor-pointer {
  cursor: pointer;
}

@media screen and (max-width: 1199px) {
  .soal-mobile {
    display: none;
  }
}
@media screen and (min-width: 1199px) {
  .soal-desktop {
    display: none;
  }
}

.chosen {
  text-decoration: line-through;
}
</style>
