import { defineStore } from "pinia";
import axios from "axios";

export const usePaymentStore = defineStore({
  id: "payment",
  actions: {
    async payment(data) {
      try {
        const resp = await axios.post("/api/payments", data);
        return resp;
      } catch (error) {
        throw error;
      }
    },
    async deletePayment(data) {
      try {
        const resp = await axios.delete("/api/payments/" + data);
        return resp;
      } catch (error) {
        throw error;
      }
    },
    async callbackPayment(data) {
      try {
        const resp = await axios.post("/api/payments/check-status-snap", data);
        return resp;
      } catch (error) {
        throw error;
      }
    },
  }

});
