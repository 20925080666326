<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <div class="content">
        <div class="content-page-header px-3 content-page-headersplit mb-0">
          <h5>Daftar Tryouts</h5>
          <div class="list-btn">
            <router-link to="/admin/tryouts/create" class="btn btn-primary">
              <i class="fa fa-plus me-2"></i>Add Tryouts
            </router-link>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-resposnive table-div">
              <div class="table datatable">
                <a-table :columns="columns" :data-source="data">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.title === 'created_at'">
                      {{ formatDateTime(record.created_at) }}
                    </template>
                    <template v-if="column.title === 'updated_at'">
                      {{ formatDateTime(record.updated_at) }}
                    </template>

                    <template v-if="column.title === 'Lihat Soal'">
                      <div class="d-flex flex justify-content-center gap-2">
                        <router-link
                          class="btn delete-table me-2"
                          :to="`/admin/questions/tryouts/${record.id}`"
                        >
                          <i class="feather feather-edit"></i>
                        </router-link>
                      </div>
                    </template>
                    <template v-if="column.title === 'Action'">
                      <div class="d-flex flex justify-content-center gap-2">
                        <router-link
                          class="btn delete-table me-2"
                          :to="`/admin/tryouts/${record.id}/edit`"
                        >
                          <i class="feather feather-edit"></i>
                        </router-link>
                        <button
                          @click="onDelete(record.id)"
                          class="btn delete-table"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#delete"
                        >
                          <i class="feather feather-trash-2"></i>
                        </button>
                      </div>
                    </template>
                    <template v-if="column.title === 'backable'">
                      <div class="d-flex flex justify-content-center gap-2">
                        {{ record.backable ? "True" : "False" }} 
                      </div>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Confirm
    message="Are you sure you want to delete this tryout?"
    title="Delete"
    buttonText="delete"
    id="delete"
    :url="URL"
    @submitted="fetchData()"
  />
</template>

<script setup>
import { reactive, ref } from "vue";
import axios from "axios";
import Confirm from "@/components/model/Confirm/index.vue";
const URL = ref(null);
const ID = ref();
const BASEURL = ref(process.env.VUE_APP_API_URL + "/api/tryouts/");
const onDelete = (id) => {
  ID.value = id;
  URL.value = BASEURL.value + ID.value;
};
const columns = reactive([
{
    title: "No",
    dataIndex: "index",
    sorter: {
      compare: (a, b) => a.index - b.index,
    },
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    sorter: {
      compare: (a, b) => {
        a = a.name.toLowerCase();
        b = b.name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Genre",
    dataIndex: "genre",
    sorter: {
      compare: (a, b) => {
        a = a.genre.toLowerCase();
        b = b.genre.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Access",
    dataIndex: "access",
    key: "access",
    sorter: {
      compare: (a, b) => {
        a = a.access.toLowerCase();
        b = b.access.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Time",
    dataIndex: "time",
    sorter: {
      compare: (a, b) => {
        a = a.time.toLowerCase();
        b = b.time.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Group",
    dataIndex: "group",
    sorter: {
      compare: (a, b) => {
        a = a.group.toLowerCase();
        b = b.group.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Backable",
    dataIndex: "backable",
    key: "backable",
    sorter: {
      compare: (a, b) => {
        a = a.group.toLowerCase();
        b = b.group.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Created_at",
    dataIndex: "created_at",
    sorter: {
      compare: (a, b) => {
        a = a.created_at.toLowerCase();
        b = b.created_at.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Updated_at",
    dataIndex: "updated_at",
    sorter: {
      compare: (a, b) => {
        a = a.updated_at.toLowerCase();
        b = b.updated_at.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Lihat Soal",
    key: "soal",
    sorter: true,
    class: "text-center",
  },
  {
    title: "Action",
    key: "id",
    sorter: true,
    class: "text-center",
  },
]);

const data = ref([]);

function formatDateTime(dateTimeString) {
  const dateTime = new Date(dateTimeString);

  const dateOptions = { year: "numeric", month: "long", day: "numeric" };
  const timeOptions = { hour: "numeric", minute: "numeric", second: "numeric" };

  const formattedDate = dateTime.toLocaleDateString("en-US", dateOptions);
  const formattedTime = dateTime.toLocaleTimeString("en-US", timeOptions);

  return `${formattedDate} - ${formattedTime}`;
}

const fetchData = async () => {
  try {
    const response = await axios.get("/api/tryouts");
    data.value = response.data.tryouts;
    console.log(data.value);
    data.value = data.value.map((item,index) => ({
      ...item,
      group: `${item.group.type.versi.category.name} - ${item.group.type.versi.name} - ${item.group.type.name} - ${item.group.name} `,
      index : index + 1,
    }));
    console.log(data.value);
  } catch (error) {
    console.error("Error while fetching tryouts:", error);
  }
};

fetchData();
</script>

<style>
.img {
  width: 140px;
  height: 140px;
  object-fit: cover;
}
</style>
