<template>
  <!-- Header -->
  <div class="header">
    <div class="header-left">
      <div class="sidebar-logo" style="width: 100%;">
        <router-link to="/">
          <img src="@/assets/img/edu.png" class="img-fluid logo" alt="" />
          <!-- <div class="img-fluid px-3 title">Tryouts</div> -->
        </router-link>
        <!-- <router-link to="/">
          <img src="@/assets/img/logo-small.png" class="img-fluid logo-small" alt="" /> 
          <div class="img-fluid">dashboardnavbar</div>
        </router-link> -->
      </div>
      <div class="siderbar-toggle">
        <label class="switch" id="toggle_btn">
          <input type="checkbox" @click="toggleSidebar" />
          <span class="slider round"></span>
        </label>
      </div>
    </div>
    <a
      class="mobile_btns"
      id="mobile_btns"
      href="javascript:void(0);"
      @click="toggleSidebar1"
    >
      <i class="feather-align-left"></i>
    </a>
    <div class="header-split">
      <div class="page-headers">
        <!-- <div class="search-bar">
          <span><i class="feather-search"></i></span>
          <input type="text" placeholder="Search" class="form-control" />
        </div> -->
        <a class="btn btn-outline-primary" href="https://wa.me/085721347799?tekt=Hai,%20saya%20mau%20bertanya">
          Wa Support
        </a>
      </div>
   
      <ul class="nav user-menu noti-pop-detail">
        <!-- Notifications -->
        <!-- <li class="nav-item">
          <router-link to="/" class="viewsite"
            ><i class="feather-globe me-2"></i>View Site</router-link
          >
        </li> -->
        <!-- <li class="nav-item dropdown has-arrow dropdown-heads flag-nav">
          <a class="nav-link" data-bs-toggle="dropdown" href="javascript:;" role="button">
            <img src="@/assets/img/flags/us1.png" alt="" height="20" />
          </a>
          <div class="dropdown-menu dropdown-menu-right">
            <a href="javascript:void(0);" class="dropdown-item">
              <img src="@/assets/img/flags/us.png" class="me-2" alt="" height="16" />
              English
            </a>
            <a href="javascript:void(0);" class="dropdown-item">
              <img src="@/assets/img/flags/fr.png" class="me-2" alt="" height="16" />
              French
            </a>
            <a href="javascript:void(0);" class="dropdown-item">
              <img src="@/assets/img/flags/es.png" class="me-2" alt="" height="16" />
              Spanish
            </a>
            <a href="javascript:void(0);" class="dropdown-item">
              <img src="@/assets/img/flags/de.png" class="me-2" alt="" height="16" />
              German
            </a>
          </div>
        </li> -->
        <!-- <li class="nav-item has-arrow dropdown-heads">
          <a href="javascript:;">
            <i class="feather-moon"></i>
          </a>
        </li> -->
        <!-- Notifications -->
        <li
          class="nav-item has-arrow dropdown-heads dropdown logged-item noti-nav noti-wrapper"
        >
          <!-- <a
            href="javascript:;"
            class="dropdown-toggle nav-link notify-link"
            data-bs-toggle="dropdown"
          >
            <span class="noti-message">1</span>
            <img src="@/assets/img/icons/bell-icon.svg" alt="Bell" />
          </a> -->
          <div class="dropdown-menu notify-blk notifications">
            <!-- <div class="topnav-dropdown-header">
              <div>
                <p class="notification-title">Notifications <span> 3 </span></p>
              </div>
              <a href="javascript:void(0)" class="clear-noti"
                ><i class="fa-regular fa-circle-check"></i> Mark all as read
              </a>
            </div> -->
            <div class="noti-content">
              <ul class="notification-list">
                <!-- <li class="notification-message">
                  <router-link to="notification">
                    <div class="media noti-img d-flex">
                      <span class="avatar avatar-sm flex-shrink-0">
                        <img
                          class="avatar-img rounded-circle img-fluid"
                          alt="User Image"
                          src="@/assets/img/notifications/avatar-01.jpg"
                        />
                      </span>
                      <div class="media-body flex-grow-1">
                        <p class="noti-details">
                          Lex Murphy left 6 comments on Isla Nublar SOC2
                          compliance report
                        </p>
                        <p class="noti-time">
                          <span class="notification-time">1m ago</span>
                        </p>
                      </div>
                    </div>
                  </router-link>
                </li> -->
                <!-- <li class="notification-message">
                  <div class="media noti-img d-flex">
                    <router-link to="notification">
                      <span class="avatar avatar-sm flex-shrink-0">
                        <img
                          class="avatar-img rounded-circle img-fluid"
                          alt="User Image"
                          src="@/assets/img/notifications/avatar-02.jpg"
                        />
                      </span>
                    </router-link>
                    <div class="media-body flex-grow-1">
                      <router-link to="notification"
                        ><p class="noti-details">
                          Ray Arnold requested access to UNIX directory tree
                          hierarchy
                        </p></router-link
                      >
                      <p class="noti-time">
                        <span class="notification-time">1 min</span>
                      </p>
                      <div class="notify-btns">
                        <button class="btn btn-secondary">Decline</button>
                        <button class="btn btn-primary">Accept</button>
                      </div>
                    </div>
                  </div>
                </li> -->
                <!-- <li class="notification-message">
                  <router-link to="notification">
                    <div class="media noti-img d-flex">
                      <span class="avatar avatar-sm flex-shrink-0">
                        <img
                          class="avatar-img rounded-circle img-fluid"
                          alt="User Image"
                          src="@/assets/img/notifications/avatar-03.jpg"
                        />
                      </span>
                      <div class="media-body flex-grow-1">
                        <p class="noti-details">
                          Dennis Nedry commented on Isla Nublar SOC2 compliance
                          report
                        </p>
                        <p class="noti-time">
                          <span class="notification-time">1m ago</span>
                        </p>
                      </div>
                    </div>
                  </router-link>
                </li> -->
                <!-- <li class="notification-message">
                  <router-link to="notification">
                    <div class="media noti-img d-flex">
                      <span class="avatar avatar-sm flex-shrink-0">
                        <img
                          class="avatar-img rounded-circle img-fluid"
                          alt="User Image"
                          src="@/assets/img/notifications/avatar-04.jpg"
                        />
                      </span>
                      <div class="media-body flex-grow-1">
                        <p class="noti-details">
                          John Hammond created Isla Nublar SOC2 compliance
                          report
                        </p>
                        <p class="noti-time">
                          <span class="notification-time">1m ago</span>
                        </p>
                      </div>
                    </div>
                  </router-link>
                </li> -->
              </ul>
            </div>
            <!-- <div class="topnav-dropdown-footer">
              <router-link to="notification"
                >View All Notifications
                <img src="@/assets/img/icons/arrow-right-01.svg" alt="Arrow"
              /></router-link>
            </div> -->
          </div>
        </li>
        <!-- /Notifications -->
        <li class="nav-item">
          <router-link to="/vouchers" class="viewsite"
            >
            <button class="btn btn-primary">Beli Voucher</button>
            </router-link
          >
        </li>

        <li class="nav-item has-arrow dropdown-heads" @click="initFullScreen">
          <a href="javascript:;" class="win-maximize">
            <i class="feather-maximize"></i>
          </a>
        </li>

        <!-- User Menu -->
        <li class="nav-item dropdown has-arrow account-item">
          <a
            href="javascript:;"
            class="dropdown-toggle nav-link"
            data-bs-toggle="dropdown"
          >
            <div class="user-infos">
              <span class="user-img">
                <img
                  src="@/assets/img/user.png"
                  class="rounded-circle"
                  alt=""
                />
              </span>
              <div class="user-info">
                <h6>{{ authStore.user.name }} | {{ authStore.user.type }}</h6>
                <p>{{ authStore.user.email }}</p>
              </div>
            </div>
          </a>
          <div class="dropdown-menu dropdown-menu-end emp" style="overflow: hidden">
            <router-link class="dropdown-item" to="/profile">
              <i class="feather-user me-2"></i> Profile
            </router-link>
            <div class="dropdown-item cursor" @click="logout">
              <i class="feather-log-out me-2"></i> Logout
            </div>
          </div>
        </li>
        <!-- /User Menu -->
      </ul>
    </div>
  </div>
  <!-- /Header -->
</template>

<script setup>
import { ref } from 'vue';
import { useAuthStore } from '@/stores/authStore';
import { useRouter } from 'vue-router';
const authStore = useAuthStore();
const router = useRouter();
import { useLoading } from 'vue-loading-overlay';
const $loading = useLoading();
import swal from 'sweetalert2';
const logout = async () => {
  const loader = $loading.show({});
  try {
    await authStore.logout();
    loader.hide();
    swal.fire('Logged out successfully', '', 'success');
    router.push('/login');
  } catch (error) {
    loader.hide();
  }
};
const initFullScreen = () => {
  document.body.classList.toggle("fullscreen-enable");
  if (
    !document.fullscreenElement &&
    /* alternative standard method */
    !document.mozFullScreenElement &&
    !document.webkitFullscreenElement
  ) {
    // current working methods
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.mozRequestFullScreen) {
      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
      document.documentElement.webkitRequestFullscreen(
        Element.ALLOW_KEYBOARD_INPUT
      );
    }
  } else {
    if (document.cancelFullScreen) {
      document.cancelFullScreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen();
    }
  }
};

const toggleSidebar = () => {
  const body = document.body;
  body.classList.toggle("mini-sidebar");
};

const toggleSidebar1 = () => {
  const body = document.body;
  body.classList.toggle("slide-nav");
};

import { onMounted } from 'vue';

onMounted(() => {
  document.addEventListener("mouseover", function (e) {
    e.stopPropagation();
    if (
      document.body.classList.contains("mini-sidebar") &&
      document.getElementById("toggle_btn").offsetParent !== null
    ) {
      var target = e.target.closest(".sidebar");
      if (target) {
        document.body.classList.add("expand-menu");
        var subdropUl = document.querySelectorAll(".subdrop + ul");
        subdropUl.forEach(function (ul) {
          ul.style.display = "block";
        });
      } else {
        document.body.classList.remove("expand-menu");
        var subdropUl = document.querySelectorAll(".subdrop + ul");
        subdropUl.forEach(function (ul) {
          ul.style.display = "none";
        });
      }
      e.preventDefault();
      return false;
    }
  });
});
</script>


<style>
.title {
  font-size: 16px;
  font-weight: 700;
  color: black;
}

.cursor{
  cursor: pointer;
}
</style>
