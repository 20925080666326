import { defineStore } from "pinia";
import axios from "axios";
export const usePilihanGandaStore = defineStore({
  id: "pilihanGanda",
  state: () => ({
    time: 0,
    question_anser_user: [],
    result: {},
  }),
  actions: {
    async setDB(result_tryout_user_id) {
      try {
        const response = await axios.get(`/api/result-tryout/${result_tryout_user_id}`);
        this.question_anser_user = response.data.question_answer_users;
        this.a = response.data.a;
        this.b = response.data.b;
        this.c = response.data.c;
        this.d = response.data.d;
        this.e = response.data.e;
      } catch (error) {
        console.error("Error while fetching result data:", error);
        // Tindakan lain yang sesuai untuk menangani kesalahan
      }      
    },
    setTime(time) {
      this.time = time;
    },
    getTime() {
      return this.time;
    },
    setResult(result) {
      this.result = result;
    },
    setData(newData) {
      const index = this.question_anser_user.findIndex(
        (item) =>
          item.question_id == newData.question_id &&
          item.result_tryout_user_id == newData.result_tryout_user_id
      );
      if (index !== -1) {
        this.question_anser_user[index].answer_user = newData.answer_user;
        this.question_anser_user[index].time_spend = newData.time_spend;
        this.question_anser_user[index].a = newData.a;
        this.question_anser_user[index].b = newData.b;
        this.question_anser_user[index].c = newData.c;
        this.question_anser_user[index].d = newData.d;
        this.question_anser_user[index].e = newData.e;
        try {
          axios
            .put(
              `/api/question/${newData.question_id}/result-answer/${newData.result_tryout_user_id}`,
              {
                answer_user: newData.answer_user,
                time_spend: newData.time_spend,
                a: newData.a,
                b: newData.b,
                c: newData.c,
                d: newData.d,
                e: newData.e,
              }
            )
        } catch (error) {
          throw error;
        }
      } else {
        // Jika tidak ada, tambahkan newData ke statePil
        this.question_anser_user.push(newData);
        try {
          axios.post("/api/submit-answer", newData);
        } catch (error) {
          throw error;
        }
      }
    },
    clearState() {
      this.question_anser_user = [];
      this.time = 0;
      this.result = {};
    },
  },
});
