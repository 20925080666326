<template>
  <!-- Add Coupon  -->
  <div class="modal modal-lg fade custom-modal" id="add-coupon">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content doctor-profile">
        <div class="modal-header border-bottom-0 justify-content-between">
          <h5 class="modal-title">Add Coupon</h5>
          <button
            type="button"
            class="close-btn"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <form @submit.prevent="submitFormOne">
            <div class="row align-items-center">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="col-form-label">Services</label>
                  <vue-select
                    :options="ProviderService"
                    id="providerservice"
                    placeholder="Select Service"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="col-form-label">Coupon Name</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Coupon Name"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="col-form-label">Code</label>
                  <input type="text" class="form-control" placeholder="Enter Code" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="col-form-label">Coupon Type</label>
                  <vue-select :options="CouponType" id="coupontype" placeholder="Fixed" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="col-form-label">Discount</label>
                  <input type="text" class="form-control" placeholder="10" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="col-form-label">Start Date</label>
                  <datepicker
                    v-model="startdate"
                    :input-format="dateFormat"
                    class="form-control datetimepicker"
                    :editable="true"
                    :clearable="false"
                    placeholder="Add Date"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="col-form-label">End Date</label>
                  <datepicker
                    v-model="startdate1"
                    :input-format="dateFormat"
                    class="form-control datetimepicker"
                    :editable="true"
                    :clearable="false"
                    placeholder="Add Date"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="col-form-label">Company Name</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Company Name"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="col-form-label"></label>
                  <div
                    class="status-toggle blue-tog d-flex align-items-center text-dark fw-500"
                  >
                    <input type="checkbox" id="status" class="check" checked="" />
                    <label for="status" class="checktoggle me-2">checkbox</label>
                    Once per customer
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="col-form-label">Status</label>
                  <div class="form-group coupon-radio">
                    <label class="custom_radio d-inline-block me-3">
                      <input type="radio" name="status" checked="" />
                      <span class="checkmark"></span> Active
                    </label>
                    <label class="custom_radio d-inline-block">
                      <input type="radio" name="status" />
                      <span class="checkmark"></span> Inactive
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row float-end">
              <div class="col-md-5 coupon-submit">
                <button type="submit" class="btn btn-primary">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Coupon  -->

  <!-- Edit Coupon  -->
  <div class="modal modal-lg fade custom-modal" id="edit-coupon">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content doctor-profile">
        <div class="modal-header border-bottom-0 justify-content-between">
          <h5 class="modal-title">Edit Coupon</h5>
          <button
            type="button"
            class="close-btn"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <form @submit.prevent="submitFormOne">
            <div class="row align-items-center">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="col-form-label">Services</label>
                  <vue-select
                    :options="ProviderService1"
                    id="providerservice1"
                    placeholder="Select Service"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="col-form-label">Coupon Name</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Coupon Name"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="col-form-label">Code</label>
                  <input type="text" class="form-control" placeholder="Enter Code" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="col-form-label">Coupon Type</label>
                  <vue-select
                    :options="CouponType1"
                    id="coupontype1"
                    placeholder="Fixed"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="col-form-label">Discount</label>
                  <input type="text" class="form-control" placeholder="10" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="col-form-label">Start Date</label>
                  <datepicker
                    v-model="startdate"
                    :input-format="dateFormat"
                    class="form-control datetimepicker"
                    :editable="true"
                    :clearable="false"
                    placeholder="Add Date"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="col-form-label">End Date</label>
                  <datepicker
                    v-model="startdate1"
                    :input-format="dateFormat"
                    class="form-control datetimepicker"
                    :editable="true"
                    :clearable="false"
                    placeholder="Add Date"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="col-form-label">Company Name</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Company Name"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="col-form-label"></label>
                  <div
                    class="status-toggle blue-tog d-flex align-items-center text-dark fw-500"
                  >
                    <input type="checkbox" id="status1" class="check" checked="" />
                    <label for="status" class="checktoggle me-2">checkbox</label>
                    Once per customer
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="col-form-label">Status</label>
                  <div class="form-group coupon-radio">
                    <label class="custom_radio d-inline-block me-3">
                      <input type="radio" name="status" checked="" />
                      <span class="checkmark"></span> Active
                    </label>
                    <label class="custom_radio d-inline-block">
                      <input type="radio" name="status" />
                      <span class="checkmark"></span> Inactive
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row float-end">
              <div class="col-md-5 coupon-submit">
                <button type="submit" class="btn btn-primary">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit Coupon  -->

  <!-- Delete Account -->
  <div class="modal fade custom-modal" id="del-coupons">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-bottom-0 justify-content-between">
          <h5 class="modal-title">Delete Account ?</h5>
          <button
            type="button"
            class="close-btn"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <div class="write-review">
            <form @submit.prevent="submitForm">
              <p>
                Are you sure you want to delete this account? To delete your account, Type
                your password.
              </p>
              <div class="form-group">
                <label class="col-form-label">Password</label>
                <div class="pass-group">
                  <input
                    type="password"
                    class="form-control pass-input"
                    placeholder="*************"
                  />
                  <span class="toggle-password feather-eye-off"></span>
                </div>
              </div>
              <div class="modal-submit text-end">
                <a
                  href="javascript:;"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                  >Cancel</a
                >
                <button type="submit" class="btn btn-danger">Delete Account</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Account -->
</template>
<script>
import { ref } from "vue";
const currentDate = ref(new Date());
const currentDate1 = ref(new Date());
export default {
  data() {
    return {
      select2: null,
      ProviderService: ["Select Service", "Car Repair", "House Cleaning"],
      ProviderService1: ["Select Service", "Car Repair", "House Cleaning"],
      CouponType: ["Fixed", "Pecentage"],
      CouponType1: ["Fixed", "Pecentage"],
      startdate: currentDate,
      startdate1: currentDate1,
      dateFormat: "dd-MM-yyyy",
    };
  },
  methods: {
    submitForm() {
      this.$router.push("/login");
    },
    submitFormOne() {
      this.$router.push("/provider-coupons");
    },
  },
};
</script>
