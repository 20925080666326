<template>
  <div class="provider-body">
    <div class="main-wrapper">
      <dashboardnavbar />

      <div class="page-wrapper">
        <sidebar />
        <div class="container">
          <div class="content p-5">
            <h5 class="my-4">Pembahasan Tryouts</h5>
            <div
              @click="onGo(item.url, item.access)"
              v-for="(item, index) in data"
            >
              <div class="tryouts__group_content_list">
                <div class="tryout__group_content_list_name">
                  {{ item.title }}
                </div>
                <div class="tryout__group_content_list_access">
                  <div
                    :class="item.access"
                    class="tryout__group_content_list_access_content"
                  >
                    {{ item.access }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useAuthStore } from "@/stores/authStore";
import axios from "axios";
import { onMounted, ref } from "vue";
import swal from "sweetalert2";
import { useRouter } from "vue-router";
const authStore = useAuthStore();
const userType = authStore.user.type;
const router = useRouter();
const onGo = (url, access) => {
  if (
    userType === access ||
    (userType === "premium" && access === "superior") ||
    (userType === "genius" && access === "vip") ||
    (userType === "superior" && access === "premium") ||
    (userType === "vip" && access === "genius") ||
    (userType === "genius" && (access === "premium" || access === "superior"))
  ) {
    window.location.href = url;
  } else {
    swal
      .fire({
        title: "Anda tidak memiliki akses",
        text: "Untuk mengakses premium silahkan membeli voucher terlebih dahulu",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Beli Voucher",
        cancelButtonText: "Tidak",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          router.push("/vouchers");
        } else {
        }
      });
  }
};
let data = ref([]);
const fetchData = async () => {
  try {
    const res = await axios.get("/api/tryout_discussions");
    data.value = res.data.tryout_discussions;
    console.log(data.value);
  } catch (err) {
    console.error("Error while fetching group data:", err);
    // Tindakan lain yang sesuai untuk menangani kesalahan
  }
};

onMounted(() => {
  fetchData();
});
</script>

<style scoped>
details[open] summary ~ * {
  animation: sweep 0.2s ease-in-out;
}

details[close] summary ~ * {
  animation: sweep 0.2s ease-in-out;
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-left: -10px;
  }
  100% {
    opacity: 1;
    margin-left: 0px;
  }
}

.tryouts__group {
  padding: 20px;
  border-radius: 10px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.tryouts__group_content {
  margin-top: 20px;
  border-radius: 20px;
}

.tryouts__group_content_list {
  cursor: pointer;
  color: black;
  padding: 10px 30px 10px 30px;
  border-top: 1px solid #22222b44;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: whitesmoke;
}

.tryouts__group_content_list:hover {
  background: #1365df;
  color: white;
}

.tryout__group_content_list_access_content {
  width: 100px;
  border-radius: 5px;
  text-align: center;
  padding: 10px;
}

.free {
  background: #ed7f409c;
  color: white;
}

.premium {
  background: #40994091;
  color: white;
}

.vip {
  background: #1365df;
  color: white;
}
</style>
