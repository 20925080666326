import { defineStore } from "pinia";
import axios from "axios";
import { router } from "@/router";

export const useAuthStore = defineStore({
  id: "auth",
  state: () => ({
    user: {},
    isLoggedIn: false,
    token: null,
  }),
  actions: {
    async register(data) {
      try {
        console.log(data);
        const resp = await axios.post("/api/register", data);
        return resp;
      } catch (error) {
        throw error;
      }
    },
    async login(data) {
      try {
        const resp = await axios.post("/api/login", data);
        this.token = resp.data.token;
        this.setUser(resp.data.user);
        axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;
        return resp;
      } catch (error) {
        throw error;
      }
    },

    setUser(data) {
      this.user = data;
      this.isLoggedIn = true;
    },

    async changePassword(data) {
      try {
        const resp = await axios.post("change-password", data);
        return resp;
      } catch (error) {
        throw error;
      }
    },
    async me() {
      try {
        const resp = await axios.get("/api/me");
        return resp;
      } catch (error) {
        throw error;
      }
    },
    async logout() {
      try {
        await axios.post("/api/logout");
        this.token = null;
        axios.defaults.headers.common["Authorization"] = null;
        this.user = {};
        this.isLoggedIn = false;
        this.$reset();
        router.push("/");
      } catch (error) {
        // Tangani kesalahan jika ada
        console.error("Error during logout:", error);
        // Lakukan tindakan lain sesuai kebutuhan, seperti menampilkan pesan kesalahan kepada pengguna
      }
    },
    async clearState() {
      this.token = null;
      axios.defaults.headers.common["Authorization"] = null;
      this.user = {};
      this.isLoggedIn = false;
      this.$reset();
      router.push("/");
    },
    async requestEmailVerify() {
      try {
        const resp = await axios.post("email/request-verification");
        return resp;
      } catch (error) {
        throw error;
      }
    },
    async emailVerify(token) {
      localStorage.setItem("token", token);
      try {
        const resp = await axios.post("/api/email/verify", {token: token});
        this.setUser(resp.data.data);
        this.token = token
        return resp;
      } catch (error) {
        throw error;
      }
    },

    async forgetPassword(data) {
      try {
        const resp = await axios.post("/api/email/forget-password", data);
        return resp;
      } catch (error) {
        throw error;
      }
    },
    async resetPassword(data) {
      try {
        const resp = await axios.post(
          "/api/reset-password",
          {
            token: data.token,
            password: data.password,
            password_confirmation: data.password_confirmation,
          }
        );
        return resp;
      } catch (error) {
        throw error;
      }
    },
  },
  getters: {
    getUser: (state) => state.user.user,
    getIsLoggedIn: (state) => state.isLoggedIn,
  },
  mutations: {
    setUser(data) {
      this.user = data;
      this.isLoggedIn = true;
    },
  },
  persist: true,
});
