<template>
  <div class="provider-body">
    <div class="main-wrapper">
      <dashboardnavbar />

      <div class="page-wrapper">
        <sidebar />

        <breadcrumb :title="title" :text="text" :text1="text1" />

        <div class="content">
          <div class="container" v-if="versi.length > 0">
            <div class="row">
              <!-- Versi List -->
              <div
                class="col-md-6 col-lg-4 d-flex"
                v-for="(item, index) in versi"
                :key="index"
              >
                <router-link
                  :to="{ path: '/tryouts/type', query: { versi: item.id } }"
                >
                  <CardTO
                    :image="item.image"
                    :name="item.name"
                    :icon="item.icon"
                    :description="item.description"
                  />
                </router-link>
              </div>
              <!-- /Versi List -->
            </div>
          </div>
          <div class="center" v-else>
            <img :src="comingsoon" style="width: 100%;" />
            <br />
            <div class="m-4 row">
              <div class="col-12 mt-4">
                <h3 style="text-align: center;">Coming Soon</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import comingsoon from '@/assets/img/coming-soon.png';
import CardTO from "@/views/tryouts/components/CardTO.vue";
import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import axios from "axios";
const title = "Pilih Try Out yang Akan Kamu Kerjakan!";
const text = "Home";
const text1 = "Versi";
const versi = ref([]);
const $route = useRoute();

// Function to filter data based on category id
const fetchData = async (id) => {
  try {
    const res = await axios.get(`/api/versi/category/${id}`);
    versi.value = res.data.versi;
  } catch (error) {
    console.error("Error while fetching versi data:", error);
    // Tindakan lain yang sesuai untuk menangani kesalahan
  }
};

// Initial assignment of filteredVersi
// Watch for changes in $route.query.category
watch(
  () => $route.query.category,
  (newValue, oldValue) => {
    versi.value = fetchData(newValue);
  }
);

onMounted(() => {
  fetchData($route.query.category);
});
</script>

<style scoped>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 30%;
}

.card_info {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
}

.card_info img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(100%);
  transition: all 0.3s ease-in-out;
}

.card_info::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(76, 64, 237, 0.349);
}

.card_info:hover img {
  filter: grayscale(0);
}

.card_info__content {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Align content vertically center */
  align-items: center;
  text-align: center;
  padding: 20px;
}

.card_info__content h5 {
  color: white;
  font-weight: bold;
  font-size: 2rem;
}
</style>
