<template>
  <div class="provider-body">
    <div class="main-wrapper">

      <dashboardnavbar />

      <div class="page-wrapper">

        <sidebar />

        <breadcrumb :title="title"  :text="text" :text1="text1" />

        <div class="content">
          <div class="container">
            <div class="row">

              <!-- Category List -->
              <div class="col-md-6 col-lg-4 d-flex" v-for="(item, index) in data" :key="index">
                <router-link :to="{
                      path: '/tryouts/versi',
                      query: { category: item.id },
                    }"
                  >
                  <CardTO
                    :image="item.image"
                    :name="item.name"
                    :icon="item.icon"
                    :description="''"
                  />
                </router-link>
              </div>
              <!-- /Category List -->

            </div>
          </div>
        </div>

       
      </div>
      
    </div>
  </div>
</template>

<script setup>
import axios from "axios";
import { onMounted, ref } from "vue";
import { useLoading } from "vue-loading-overlay";
const $loading = useLoading({});
let data = ref([]);
const title = "Pilih Try Out yang Akan Kamu Kerjakan!";
const text = "Home";
const text1 = "Kategori"
import CardTO from "@/views/tryouts/components/CardTO.vue";
const fetchData = async () => {
  const loader = $loading.show({});
  try {
    const res = await axios.get("/api/categories");
    loader.hide();
    data.value = res.data.categories;
  } catch (err) {
    loader.hide();
  }
};
onMounted(() => {
  fetchData();
});
</script>

<style scoped>
.card_info {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
}

.card_info img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(100%);
  transition: all 0.3s ease-in-out;
}

.card_info::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(76, 64, 237, 0.349);
}

.card_info:hover img {
  filter: grayscale(0);
}

.card_info__content {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Align content vertically center */
  align-items: center;
  text-align: center;
  padding: 20px;
}

.card_info__content h5 {
  color: white;
  font-weight: bold;
  font-size: 2rem;
}
</style>
