<template>
  <div class="provider-body">
    <div class="main-wrapper">
      <dashboardnavbar />

      <div class="page-wrapper">
        <sidebar />
        <div class="container">
          <div class="content p-5">
            <div class="col-lg-9">
              <h6 class="user-title">General Information</h6>
              <div class="general-info">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-form-label">Name</label>
                      <input
                        v-model="user.name"
                        disabled
                        type="text"
                        class="form-control"
                        placeholder="Enter Your Name"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-form-label">Email </label>
                      <input
                        disabled
                        v-model="user.email"
                        type="email"
                        class="form-control"
                        placeholder="Enter Email Address"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-form-label">Mobile Number</label>
                      <input
                        v-model="user.phone_number"
                        disabled
                        type="text"
                        class="form-control"
                        placeholder="Enter Mobile Number"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-form-label">Paket</label>
                      <input
                        v-model="user.type"
                        disabled
                        type="text"
                        class="form-control"
                        placeholder="Enter Mobile Number"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-form-label"
                        >Masa Berlaku Paket</label
                      >
                      <input
                        disabled
                        type="text"
                        class="form-control"
                        placeholder="Anda tidak memiliki paket yang berlaku"
                        :value="
                          user.expired ? formatDateTime(user.expired) : ''
                        "
                      />
                    </div>
                    <button
                      @click="redirectToChangePassword"
                      class="btn btn-primary"
                    >
                      Change Password
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { useAuthStore } from "@/stores/authStore";

const authStore = useAuthStore();
const user = authStore.user;
const router = useRouter();
function redirectToChangePassword() {
  router.push(`/views/profile/${user.id}/changepass`);
}
function formatDateTime(dateTimeString) {
  // const dateTime = new Date(dateTimeString);

  // const dateOptions = { year: "numeric", month: "long", day: "numeric" };
  // const timeOptions = { hour: "numeric", minute: "numeric", second: "numeric" };

  // const formattedDate = dateTime.toLocaleDateString("en-US", dateOptions);
  // const formattedTime = dateTime.toLocaleTimeString("en-US", timeOptions);

  // return `${formattedDate} - ${formattedTime}`;

  // Convert the string to a Date object
  var dateObject = new Date(dateTimeString);

  // Format the date and time separately
  var formattedDate = dateObject.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
  var formattedTime = dateObject.toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
  });

  // Concatenate formatted date and time
  return formattedDate + " " + formattedTime;
}
</script>

<style scoped>
.free {
  background: #ed7f409c;
  color: white;
}

.premium {
  background: #40994091;
  color: white;
}

.vip {
  background: #0167f6;
  color: white;
}
</style>
