<template>
  <div class="login-body">
    <div class="content">
      <div class="container">
        <div class="row">
          <!-- Password Recovery -->
          <div class="col-md-6 col-lg-6 mx-auto">
            <div class="login-wrap">
              <div class="login-header">
                <h3>Password Recovery</h3>
                <p>
                  Enter your email and we will send you a link to reset your password.
                </p>
              </div>

              <form @submit.prevent="submitForm">
                <div class="log-form">
                  <div class="form-group">
                    <label class="col-form-label">E-mail</label>
                    <input
                      v-model="email"
                      type="text"
                      class="form-control"
                      placeholder="example@email.com"
                    />
                    <div v-if="errors.email" class="text-danger mt-2">
                      {{ errors.email[0] }}
                    </div>
                  </div>
                </div>
                <b-button
                  variant="primary w-100 login-btn"
                  type="submit"
                  >Submit</b-button
                >
                <p class="no-acc mt-0">
                  Remember Password ? <router-link to="login">Login</router-link>
                </p>
              </form>
            </div>
          </div>
          <!-- /Password Recovery -->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useAuthStore } from "@/stores/authStore";
import { useRoute, useRouter } from "vue-router";
import swal from "sweetalert2";
import { ref } from "vue";
import { useLoading } from "vue-loading-overlay";
const route = useRoute();
const router = useRouter();
const authStore = useAuthStore();
let email = ref('');
let errors = ref({});
const $loading = useLoading({});
const submitForm = async () => {
  let loader = $loading.show({});
  try {
    const response = await authStore.forgetPassword({email: email.value});
    loader.hide();
    email.value = '';
    swal.fire({ icon: "success", text: response.data.message });
  } catch (error) {
    loader.hide();
    errors.value = error.response.data;
  }
};
</script>
>
