<template>
    <div class="main-wrapper">

        <layouts></layouts>

        <div class="page-wrapper">
            <breadcrumb :title="title"  :text="text" :text1="text1" />
            
            <about-us />

            <aboutprovider />

            <truelyfooter />

        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            title: "About Us",
            text: "Home",
            text1: "About Us",
            
        }
    },
}
</script>