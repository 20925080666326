<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper">
      <div class="content">
        <b-form @submit.prevent="updateForm" enctype="multipart/form-data">
          <div class="row">
            <div class="col-lg-7 col-sm-12 m-auto">
              <div class="content-page-header">
                <h5 class="mb-2">Edit Group</h5>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Name</label>
                    <input
                      v-model="data.name"
                      type="text"
                      class="form-control"
                    />
                    <div v-if="errors.name" class="text-danger">
                      {{ errors.name[0] }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Type</label>
                    <vue-select
                      v-model="data.type_id"
                      :options="type"
                      placeholder="Type"
                    />
                    <div v-if="errors.type_id" class="text-danger">
                      {{ errors.type_id[0] }}
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="btn-path">
                    <router-link to="/admin/groups" class="btn btn-cancel me-3"
                      >Cancel</router-link
                    >
                    <b-button type="submit" variant="primary">
                      Edit Group</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import swal from "sweetalert2";
import axios from "axios";
import { useLoading } from "vue-loading-overlay";
import { useRoute, useRouter } from "vue-router";
let errors = ref({});
const API = process.env.VUE_APP_API_URL;
const $loading = useLoading({});
const router = useRouter();
const route = useRoute();
let type = ref();
let data = ref({
  type: null,
});

const updateForm = async () => {
  const loader = $loading.show({});
  const formData = new FormData();
  formData.append("type_id", data.value.type_id);

  formData.append("name", data.value.name);
  formData.append("_method", "put");
  for (const [key, value] of formData.entries()) {
    console.log(`${key}: ${value}`);
  }
  try {
    const response = await axios.post(
      `api/groups/${route.params.id}`,
      formData
    );
    loader.hide();
    swal.fire({ icon: "success", text: response.data.message });
    router.push("/admin/groups");
  } catch (error) {
    loader.hide();
    errors.value = error.response.data;
  }
};

function transformArray(data) {
  return data.map((item) => {
    console.log(item);
    return {
      id: item.id,
      text:
        item.versi.category.name + " - " + item.versi.name + " - " + item.name,
    };
  });
}

const fetchData = async () => {
  try {
    const response = await axios.get("api/types");
    type.value = response.data.types;
    type.value = transformArray(type.value);
    type.value.unshift({ id: null, text: "Pilih Type" });
  } catch (error) {
    console.error("Error while fetching types:", error);
  }

  try {
    const response = await axios.get(`api/groups/${route.params.id}/edit`);
    data.value = response.data.groups;
    data.value.type = data.value.type_id;
  } catch (error) {
    console.error("Error while fetching group data:", error);
  }
};

onMounted(() => {
  fetchData();
});
</script>
