<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper">
      <div class="content">
        <b-form @submit.prevent="updateForm" enctype="multipart/form-data">
          <div class="row">
            <div class="col-lg-7 col-sm-12 m-auto">
              <div class="content-page-header">
                <h5 class="mb-2">Edit Types</h5>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Name</label>
                    <input
                      v-model="data.name"
                      type="text"
                      class="form-control"
                    />
                    <div v-if="errors.name" class="text-danger">
                      {{ errors.name[0] }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Versi</label>
                    <vue-select
                      v-model="data.versi"
                      :options="versi"
                      placeholder="Versi"
                    />
                    <div v-if="errors.versi_id" class="text-danger">
                      {{ errors.versi_id[0] }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Description</label>
                    <textarea
                      v-model="data.description"
                      class="form-control"
                      placeholder="Add Your Description"
                    ></textarea>
                    <div v-if="errors.description" class="text-danger">
                      {{ errors.description[0] }}
                    </div>
                  </div>
                </div>

                <div class="mb-4">
                  <label class="form-label">Image</label>
                  <div class="form-uploads">
                    <div class="form-uploads-path">
                      <img
                        v-if="preview.image"
                        :src="preview.image"
                        alt="img"
                      />
                      <img
                        v-else-if="data.image && !preview.image"
                        :src="data.image"
                        alt="img"
                      />
                      <img
                        v-else
                        src="@/assets/admin_img/icons/upload.svg"
                        alt="img"
                      />
                      <div class="file-browse">
                        <h6>Pilih Gambar</h6>
                        <div class="file-browse-path">
                          <input @change="handleMainImageUpload" type="file" />
                          <a href="javascript:void(0);"> Browse</a>
                        </div>
                      </div>
                      <h5>Supported formates: JPEG, PNG</h5>
                    </div>
                  </div>
                  <div v-if="errors.image" class="text-danger">
                    {{ errors.image[0] }}
                  </div>
                </div>

                <div class="mb-4">
                  <label class="form-label">Icon</label>
                  <div class="form-uploads">
                    <div class="form-uploads-path">
                      <img v-if="preview.icon" :src="preview.icon" alt="img" />
                      <img
                        v-else-if="data.icon && !preview.icon"
                        :src="data.icon"
                        alt="img"
                      />
                      <img
                        v-else
                        src="@/assets/admin_img/icons/upload.svg"
                        alt="img"
                      />
                      <div class="file-browse">
                        <h6>Pilih Gambar</h6>
                        <div class="file-browse-path">
                          <input @change="handleIconUpload" type="file" />
                          <a href="javascript:void(0);"> Browse</a>
                        </div>
                      </div>
                      <h5>Supported formates: JPEG, PNG</h5>
                    </div>
                  </div>
                  <div v-if="errors.icon" class="text-danger">
                    {{ errors.icon[0] }}
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="btn-path">
                    <router-link to="/admin/types" class="btn btn-cancel me-3"
                      >Cancel</router-link
                    >
                    <b-button type="submit" variant="primary">
                      Edit Type</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import swal from "sweetalert2";
import axios from "axios";
import { useLoading } from "vue-loading-overlay";
import { useRoute, useRouter } from "vue-router";
let errors = ref({});
import { deleteFileByUrl } from "@/utils/fileUpload";
import { uploadFile } from "@/utils/fileUpload";
const $loading = useLoading({});
const router = useRouter();
const route = useRoute();
let versi = ref();
let data = ref({
  versi: null,
});

let preview = ref({
  image: null,
  icon: null,
});

let imageFirebase = ref(null);
let iconFirebase = ref(null);

const handleMainImageUpload = (event) => {
  preview.value.image = URL.createObjectURL(event.target.files[0]);
  imageFirebase.value = event.target.files[0];
};
const handleIconUpload = (event) => {
  preview.value.icon = URL.createObjectURL(event.target.files[0]);
  iconFirebase.value = event.target.files[0];
};

const updateForm = async () => {
  const loader = $loading.show({});
  const formData = new FormData();
  formData.append("versi_id", data.value.versi_id);
  if (imageFirebase.value) {
    try {
      await deleteFileByUrl(data.value.image);
    } catch (error) {
      console.error("Error while deleting file:", error);
    }
    try {
      const response = await uploadFile(imageFirebase.value);
      console.log(response);
      formData.append("image", response);
    } catch (error) {
      console.error("Error while uploading file:", error);
    }
  } else if (data.value.image) {
    console.log(data.value.image);
    formData.append("image", data.value.image);
  }
  if (iconFirebase.value) {
    try {
      await deleteFileByUrl(data.value.icon);
    } catch (error) {
      console.error("Error while deleting file:", error);
    }
    try {
      const response = await uploadFile(iconFirebase.value);
      console.log(response);
      formData.append("icon", response);
    } catch (error) {
      console.error("Error while uploading file:", error);
    }
  } else if (data.value.icon) {
    formData.append("icon", data.value.icon);
  }
  formData.append("name", data.value.name);
  formData.append("description", data.value.description);
  formData.append("_method", "put");
  for (const [key, value] of formData.entries()) {
    console.log(`${key}: ${value}`);
  }
  try {
    const response = await axios.post(`api/types/${route.params.id}`, formData);
    loader.hide();
    swal.fire({ icon: "success", text: response.data.message });
    router.push("/admin/types");
  } catch (error) {
    loader.hide();
    errors.value = error.response.data;
  }
};

function transformArray(data) {
  return data.map((item) => {
    return {
      id: item.id,
      text: `Category ${item.category.name} - ${item.name}`,
    };
  });
}

const fetchData = async () => {
  try {
    const response = await axios.get("api/versi");
    versi.value = response.data.versi;
    versi.value = transformArray(versi.value);
    versi.value.unshift({ id: null, text: "Pilih Versi" });
  } catch (error) {
    console.error("Error fetching versi data:", error);
  }

  try {
    const response = await axios.get(`api/types/${route.params.id}/edit`);
    data.value = response.data.type;
    data.value.versi = data.value.versi_id;
  } catch (error) {
    console.error("Error fetching type data:", error);
  }
};

onMounted(() => {
  fetchData();
});
</script>
