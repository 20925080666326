<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper">
      <div class="content">
        <b-form @submit.prevent="updateForm" enctype="multipart/form-data">
          <div class="row">
            <div class="col-lg-7 col-sm-12 m-auto">
              <div class="content-page-header">
                <h5 class="mb-2">Edit Tryout</h5>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Name</label>
                    <input
                      v-model="data.name"
                      type="text"
                      class="form-control"
                    />
                    <div v-if="errors.name" class="text-danger">
                      {{ errors.name[0] }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Genre</label>
                    <vue-select
                      v-model="data.genre"
                      :options="genres"
                      placeholder="Select Genre"
                    />
                    <div v-if="errors.genre" class="text-danger">
                      {{ errors.genre[0] }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Access</label>
                    <vue-select
                      v-model="data.access"
                      :options="accessOption"
                      placeholder="Access"
                    />
                    <div v-if="errors.access" class="text-danger">
                      {{ errors.access[0] }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Time</label>
                    <input
                      v-model="data.time"
                      type="text"
                      class="form-control"
                    />
                    <div v-if="errors.time" class="text-danger">
                      {{ errors.time[0] }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Group</label>
                    <vue-select
                      v-model="data.group_id"
                      :options="group"
                      placeholder="Group"
                    />
                    <div v-if="errors.group_id" class="text-danger">
                      {{ errors.group_id[0] }}
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Izinkan kembali ke soal sebelumnya ?</label>
                    <div class="backable-options">
                      <input
                        type="radio"
                        id="backable-true"
                        v-model="data.backable"
                        :value="true"
                        :checked="data.backable == true"
                      />
                      <label class="backable-label" for="backable-true"
                        >Izinkan</label
                      >
                      <input
                        type="radio"
                        id="backable-false"
                        v-model="data.backable"
                        :value="false"
                        :checked="data.backable == false"
                      />
                      <label class="backable-label" for="backable-false"
                        >Tidak</label
                      >
                    </div>
                  </div>
                </div>

                <!-- Add other fields as needed -->
                <div class="col-lg-12">
                  <div class="btn-path">
                    <router-link to="/admin/tryouts" class="btn btn-cancel me-3"
                      >Cancel</router-link
                    >
                    <b-button type="submit" variant="primary">
                      Edit Group</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import swal from "sweetalert2";
import axios from "axios";
import { useLoading } from "vue-loading-overlay";
import { useRoute, useRouter } from "vue-router";

let errors = ref({});
const API = process.env.VUE_APP_API_URL;
const $loading = useLoading({});
const router = useRouter();
const route = useRoute();

let genres = ref([
  {
    id: "number",
    text: "Kecermatan ( angka hilang, simbol hilang, atau gabungan)",
  },
  { id: "multiple_choice", text: "Multiple Choice" },
  { id: "kraepelin", text: "Kraepelin" },
  { id: "pauli", text: "Pauli" },
]);

let group = ref([]);

function transformArray(data) {
  return data.map((item) => {
    return {
      id: item.id,
      text: item.name,
    };
  });
}
let data = ref({
  genre: null,
  access: null,
  time: null,
  group_id: null,
  backable: null,
});

const updateForm = async () => {
  const loader = $loading.show({});
  try {
    const response = await axios.put(
      `api/tryouts/${route.params.id}`,
      data.value
    );
    loader.hide();
    swal.fire({ icon: "success", text: response.data.message });
    router.push("/admin/tryouts");
  } catch (error) {
    loader.hide();
    errors.value = error.response.data;
  }
};

const accessOption = [
  { id: "free", text: "Free" },
  { id: "superior", text: "Superior" },
  { id: "genius", text: "Genius" },
];

const genreOption = [
  { id: "number", text: "Kecermatan" },
  { id: "multiple_choice", text: "Pilihan Ganda" },
];

const fetchData = async () => {
  try {
    const response = await axios.get("api/groups");
    group.value = response.data.groups;
    group.value = transformArray(group.value);
    group.value.unshift({ id: null, text: "Pilih Group" });
  } catch (error) {
    console.error("Error while fetching groups:", error);
  }

  try {
    const response = await axios.get(`api/tryouts/${route.params.id}/edit`);
    data.value = response.data.tryout;
  } catch (error) {
    console.error("Error while fetching tryout data:", error);
  }
};

onMounted(() => {
  fetchData();
});
</script>
<style>
.backable-options {
  display: flex;
  align-items: center;
}

.backable-options input[type="radio"] {
  display: none;
}

.backable-label {
  display: inline-block;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 20px;
  margin-right: 10px;
  transition: background-color 0.3s ease;
}

.backable-label:hover {
  background-color: #f0f0f0;
}

.backable-label::before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  border: 2px solid #999;
  border-radius: 50%;
  vertical-align: middle;
}

.backable-options input[type="radio"]:checked + .backable-label::before {
  background-color: #007bff;
  border-color: #007bff;
}
</style>
