<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper">
      <div class="content">
        <b-form @submit.prevent="updateForm" enctype="multipart/form-data">
          <div class="row">
            <div class="col-lg-7 col-sm-12 m-auto">
              <div class="content-page-header">
                <h5 class="mb-2">Edit Vouchers</h5>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Name</label>
                    <input
                      v-model="data.name"
                      type="text"
                      class="form-control"
                    />
                    <div v-if="errors.name" class="text-danger">
                      {{ errors.name[0] }}
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Amount</label>
                    <input
                      v-model="data.amount"
                      type="text"
                      class="form-control"
                    />
                    <div v-if="errors.amount" class="text-danger">
                      {{ errors.amount[0] }}
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Day</label>
                    <input
                      v-model="data.day"
                      type="text"
                      class="form-control"
                    />
                    <div v-if="errors.day" class="text-danger">
                      {{ errors.day[0] }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Type</label>
                    <select
                      v-model="data.type"
                      class="form-control"
                      style="font-size: 14px; padding: 10px"
                    >
                      <option value="bimbel">Bimbel</option>
                      <option value="voucher">Voucher</option>
                    </select>
                    <div v-if="errors.type" class="text-danger">
                      {{ errors.type[0] }}
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Access</label>
                    <select
                      v-model="data.access"
                      class="form-control"
                      style="font-size: 14px; padding: 10px"
                    >
                      <option value=""></option>
                      <option value="superior">Superior</option>
                      <option value="genius">Genius</option>
                    </select>
                    <div v-if="errors.access" class="text-danger">
                      {{ errors.access[0] }}
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="btn-path">
                    <router-link
                      to="/admin/vouchers"
                      class="btn btn-cancel me-3"
                      >Cancel</router-link
                    >
                    <b-button type="submit" variant="primary">
                      Edit Voucher</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import swal from "sweetalert2";
import axios from "axios";
import { useLoading } from "vue-loading-overlay";
import { useRoute, useRouter } from "vue-router";
let errors = ref({});
const $loading = useLoading({});
const router = useRouter();
const route = useRoute();
let data = ref({});

const updateForm = async () => {
  const loader = $loading.show({});
  const formData = new FormData();
  formData.append("name", data.value.name);
  formData.append("amount", data.value.amount);
  formData.append("day", data.value.day);
  formData.append("type", data.value.type);
  formData.append("access", data.value.access);
  formData.append("_method", "put");
  try {
    const response = await axios.post(
      `api/vouchers/${route.params.id}`,
      formData
    );
    loader.hide();
    swal.fire({ icon: "success", text: response.data.message });
    router.push("/admin/vouchers");
  } catch (error) {
    loader.hide();
    errors.value = error.response.data;
  }
};

const fetchData = async () => {
  try {
    const response = await axios.get(`api/vouchers/${route.params.id}/edit`);
    data.value = response.data.voucher;
  } catch (error) {
    errors.value = error.response.data;
  }
};

onMounted(() => {
  fetchData();
});
</script>
