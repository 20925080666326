<template>
  <div class="provider-body">
    <div class="main-wrapper">
      <dashboardnavbar />

      <div class="page-wrapper">
        <sidebar />
        <div class="container">
          <div class="row justify-content-center">
            <div class="table-resposnive col-lg-8 table-div mt-5">
              <div class="table datatable">
                <!-- <a-table :columns="columns" :data-source="FINAL_SCORE.detail">
                </a-table> -->
                <h4>Hasil Tryouts</h4>
                <div class="table-resposnive table-div mt-3">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>Total jawaban benar</th>
                        <th>Total jawaban salah</th>
                        <th>SCORE</th>
                        <th>Kategori</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td v-if="FINAL_SCORE_DETAIL.finalscore">
                          {{
                            FINAL_SCORE_DETAIL.finalscore.true_answer || "N/A"
                          }}
                        </td>
                        <td v-if="FINAL_SCORE_DETAIL.finalscore">
                          {{
                            FINAL_SCORE_DETAIL.finalscore.false_answer || "N/A"
                          }}
                        </td>
                        <td v-if="FINAL_SCORE_DETAIL.finalscore">
                          {{
                            FINAL_SCORE_DETAIL.finalscore.final_score || "N/A"
                          }}
                        </td>
                        <td v-if="FINAL_SCORE_DETAIL.finalscore">
                          {{ FINAL_SCORE_DETAIL.finalscore.category || "N/A" }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <h4>Koreksi Jawaban</h4>
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">Jawaban</th>
                      <th scope="col">Kunci Jawaban</th>
                      <th scope="col">Hasil</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(
                        item, index
                      ) in FINAL_SCORE_DETAIL.question_answer_user"
                    >
                      <th scope="row">{{ index + 1 }}</th>
                      <td v-if="item.question.type != 'campuran'">
                        <span v-if="item.answer_user == item.question.choice_a"
                          >A</span
                        >
                        <span
                          v-else-if="item.answer_user == item.question.choice_b"
                          >B</span
                        >
                        <span
                          v-else-if="item.answer_user == item.question.choice_c"
                          >C</span
                        >
                        <span
                          v-else-if="item.answer_user == item.question.choice_d"
                          >D</span
                        >
                        <span
                          v-else-if="item.answer_user == item.question.choice_e"
                          >E</span
                        >
                        <span v-else>
                          <span v-if="item.a">A</span>
                          <span v-if="item.b">B</span>
                          <span v-if="item.c">C</span>
                          <span v-if="item.d">D</span>
                          <span v-if="item.e">E</span>
                        </span>
                        <!-- {{ item.answer_user }}
                        {{ item.question.choice_a }} -->
                      </td>
                      <td v-else>
                        {{ item.answer_user }}
                      </td>
                      <td>
                        <div v-if="item.question.type == 'multiple'">
                          {{ item.question.answer }}
                        </div>
                        <div v-else-if="item.question.type == 'campuran'">
                          {{ item.question.answer }}
                        </div>
                        <div v-else>
                          <span
                            v-if="
                              item.question.answer == item.question.choice_a
                            "
                            >A</span
                          >
                          <span
                            v-else-if="
                              item.question.answer == item.question.choice_b
                            "
                            >B</span
                          >
                          <span
                            v-else-if="
                              item.question.answer == item.question.choice_c
                            "
                            >C</span
                          >
                          <span
                            v-else-if="
                              item.question.answer == item.question.choice_d
                            "
                            >D</span
                          >
                          <span
                            v-else-if="
                              item.question.answer == item.question.choice_e
                            "
                            >E</span
                          >
                        </div>
                      </td>
                      <td>
                        <div v-if="item.question.type == 'multiple'">
                          {{ calculateMultipleChoiceResult(item) }}
                        </div>
                        <div v-else-if="item.question.type == 'campuran'">
                          {{ calculateCampuranResult(item) }}
                        </div>
                        <div v-else>
                          <div v-if="item.answer_user == item.question.answer">
                            Benar
                          </div>
                          <div v-else>Salah</div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <h4>Detail Soal</h4>
                <div
                  class="content mt-3"
                  v-for="(
                    item, index
                  ) in FINAL_SCORE_DETAIL.question_answer_user"
                >
                  <h6 class="bg-transparent">
                    No.{{ index + 1 }}

                    <div class="bg-transparent" v-if="item.question.column.hints.length > 0" style="font-weight: 300;">
                      Petunjuk
                      <section class="p-3 border bg-transparent mb-2" v-for="(item, index) in item.question.column.hints">
                        <div v-html="item.description" />
                        <img v-if="item.image" :src="item.image" alt="" class="img-responsive" style="max-width: 400px">
                      </section>
                    </div>
                    <div
                      style="background-color: #ebebeb"
                      v-html="item.question.questions"
                    ></div>
                    <div v-if="item.question.image" class="bg-transparent">
                      <img class="img-responsive my-2" :src="item.question.image" />
                    </div>
                  </h6>
                  <div
                    class="bg-transparent"
                    v-if="
                      item.question.type != 'campuran' &&
                      item.question.choice_a != null
                    "
                  >
                    A) {{ item.question.choice_a }}
                    <img
                      class="img-responsive my-2"
                      :src="item.question.img_choice_a"
                      v-if="item.question.img_choice_a !== null"
                    />
                    <span
                      class="bg-transparent"
                      v-if="
                        item.answer_user == item.question.choice_a &&
                        item.question != 'multiple'
                      "
                    >
                      (Jawaban Anda)
                    </span>
                    <span
                      class="bg-transparent"
                      v-if="
                        item.question.answer == item.question.choice_a &&
                        item.question != 'multiple'
                      "
                    >
                      (Jawaban benar)
                    </span>
                  </div>
                  <div
                    class="bg-transparent"
                    v-if="
                      item.question.type != 'campuran' &&
                      item.question.choice_b != null
                    "
                  >
                    B) {{ item.question.choice_b }}
                    <img
                      class="img-responsive my-2"
                      :src="item.question.img_choice_b"
                      v-if="item.question.img_choice_b !== null"
                    />
                    <span
                      class="bg-transparent"
                      v-if="
                        item.answer_user == item.question.choice_b &&
                        item.question != 'multiple'
                      "
                    >
                      (Jawaban Anda)
                    </span>
                    <span
                      class="bg-transparent"
                      v-if="
                        item.question.answer == item.question.choice_b &&
                        item.question != 'multiple'
                      "
                    >
                      (Jawaban benar)
                    </span>
                  </div>
                  <div
                    class="bg-transparent"
                    v-if="
                      item.question.type != 'campuran' &&
                      item.question.choice_c != null
                    "
                  >
                    C) {{ item.question.choice_c }}
                    <img
                      class="img-responsive my-2"
                      :src="item.question.img_choice_c"
                      v-if="item.question.img_choice_c !== null"
                    />
                    <span
                      class="bg-transparent"
                      v-if="
                        item.answer_user == item.question.choice_c &&
                        item.question != 'multiple'
                      "
                    >
                      (Jawaban Anda)
                    </span>
                    <span
                      class="bg-transparent"
                      v-if="
                        item.question.answer == item.question.choice_c &&
                        item.question != 'multiple'
                      "
                    >
                      (Jawaban benar)
                    </span>
                  </div>
                  <div
                    class="bg-transparent"
                    v-if="
                      item.question.type != 'campuran' &&
                      item.question.choice_d != null
                    "
                  >
                    D) {{ item.question.choice_d }}
                    <img
                      class="img-responsive my-2"
                      :src="item.question.img_choice_d"
                      v-if="item.question.img_choice_d !== null"
                    />
                    <span
                      class="bg-transparent"
                      v-if="
                        item.answer_user == item.question.choice_d &&
                        item.question != 'multiple'
                      "
                    >
                      (Jawaban anda)
                    </span>
                    <span
                      class="bg-transparent"
                      v-if="
                        item.question.answer == item.question.choice_d &&
                        item.question != 'multiple'
                      "
                    >
                      (Jawaban benar)
                    </span>
                  </div>
                  <div
                    class="bg-transparent"
                    v-if="
                      item.question.type != 'campuran' &&
                      item.question.choice_e != null
                    "
                  >
                    E) {{ item.question.choice_e }}
                    <img
                      class="img-responsive my-2"
                      :src="item.question.img_choice_e"
                      v-if="item.question.img_choice_e !== null"
                    />
                    <span
                      class="bg-transparent"
                      v-if="
                        item.answer_user == item.question.choice_e &&
                        item.question != 'multiple'
                      "
                    >
                      (Jawaban anda)
                    </span>
                    <span
                      class="bg-transparent"
                      v-if="
                        item.question.answer == item.question.choice_e &&
                        item.question != 'multiple'
                      "
                    >
                      (Jawaban benar)
                    </span>
                    <div
                      class="mt-3 rounded"
                      v-if="item.question.type == 'multiple'"
                    >
                      <div>
                        Jawaban benar :
                        <span>{{ item.question.answer }}</span>
                      </div>

                      <div>
                        Jawaban Anda :
                        <span v-if="item.a || item.b || item.c || item.d">
                          <span v-if="item.a">A</span>
                          <span v-if="item.b">B</span>
                          <span v-if="item.c">C</span>
                          <span v-if="item.d">D</span>
                          <span v-if="item.e">E</span>
                        </span>
                        <span v-else> Anda tidak menjawab </span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="bg-transparent"
                    v-if="item.question.type == 'campuran'"
                  >
                    Jawaban Benar = {{ item.question.answer }} <br />
                    Jawaban Anda = {{ item.answer_user }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 table-resposnive">
              <h3 class="mt-5">Leaderboard</h3>
              <div class="leadearboard">
                <ul>
                  <li
                    class="leadearboard__wrapper"
                    v-for="(item, index) in Leaderboard"
                  >
                    <div class="leadearboard__name">
                      {{ index + 1 }}. Nama : {{ item.user.name }}
                    </div>
                    <div class="leadearboard__score">hints
                      <span v-if="item.final_score">{{
                        item.final_score
                      }}</span>
                      <span v-else>-</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>hints
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import axios from "axios";
import { useRoute, useRouter } from "vue-router";
import { usePilihanGandaStore } from "@/stores/pilihanGandaStore";
import { useLoading } from "vue-loading-overlay";
import { useAuthStore } from "@/stores/authStore";
const $loading = useLoading({});
const pilihanGandaStore = usePilihanGandaStore();
pilihanGandaStore.clearState();
const route = useRoute();
const Result_RTO = route.params.test;
let FINAL_SCORE_DETAIL = ref({});
let tryout_id = ref(null);
let Leaderboard = ref([]);

function sortByRank(rankings) {
  // Mengubah objek menjadi array agar dapat diurutkan
  const rankingsArray = Object.values(rankings);
  rankingsArray.sort((a, b) => {
    return a.rank - b.rank;
  });

  return rankingsArray;
}

const authStore = useAuthStore();
const user = authStore.user.id;
const router = useRouter();
const fetchData = async () => {
  const loader = $loading.show({});
  try {
    const response = await axios.get(`/api/tryouts/${Result_RTO}/resultsPG`);
    // if (response.data.result_tryout_user.user_id != user) {
    //   router.push({ path: "/home" });
    //   return;
    // }
    FINAL_SCORE_DETAIL.value = response.data;
    console.log(FINAL_SCORE_DETAIL.value);
    if (response.data.question_answer_user[0]) {
      tryout_id.value = response.data.question_answer_user[0].tryout_id;
      try {
        const res = await axios.get(`/api/${tryout_id.value}/rangking`);
        Leaderboard.value = res.data;
        Leaderboard.value = sortByRank(Leaderboard.value.rankings);
      } catch (error) {
        console.error("Error while fetching leaderboard data:", error);
        // Tindakan lain yang sesuai untuk menangani kesalahan
      }
    }
  } catch (error) {
    console.error("Error while fetching tryout results:", error);
    // Tindakan lain yang sesuai untuk menangani kesalahan
  } finally {
    loader.hide();
  }
};

const calculateMultipleChoiceResult = (item) => {
  const kunci_jawaban = item.question.answer;
  let jawaban_benar = 0;
  let user_answer = [];

  // Check each option and push selected ones into user_answer array
  ["a", "b", "c", "d", "e"].forEach((option) => {
    if (item[option]) {
      user_answer.push(option.toUpperCase());
    }
  });

  // Check if each selected answer is correct
  user_answer.forEach((answer) => {
    if (kunci_jawaban.includes(answer)) {
      jawaban_benar++;
    }
  });

  return `${jawaban_benar}/${kunci_jawaban.length}`;
};

const calculateCampuranResult = (item) => {
  const kunci_jawaban = item.question.answer;
  const jawaban_user = item.answer_user;
  const array1 = kunci_jawaban.split("");
  const array2 = jawaban_user.split("");

  const set1 = new Set(array1);
  const set2 = new Set(array2);

  for (let num of set1) {
    if (!set2.has(num)) {
      return 'Salah'; // Ada angka yang tidak sama
    }
  }
  return 'Benar'; // Semua angka sama
};


fetchData();
</script>

<style scoped>
.content {
  padding: 30px;
  border-radius: 30px;
  background: rgb(235, 235, 235);
}

.bg-transparent {
  background: rgb(235, 235, 235) !important;
}

.leadearboard__wrapper {
  padding: 10px;
  display: block;
  margin-bottom: 10px;
  background: #f3f3f3;
  border-radius: 5px;
}

.leadearboard {
  height: 400px;
  overflow-y: scroll;
}

.img-responsive {
  max-width: 100%;
  width: 350px;
  height: 350px;
  object-fit: cover;
}
</style>
