<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper">
      <div class="content">
        <b-form @submit.prevent="submitForm">
          <div class="row">
            <div class="col-lg-7 col-sm-12 m-auto">
              <div class="content-page-header">
                <h5 class="mb-2">Add Versi</h5>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Name</label>
                    <input
                      v-model="data.name"
                      type="text"
                      class="form-control"
                    />
                    <div v-if="errors.name" class="text-danger">
                      {{ errors.name[0] }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Category</label>
                    <vue-select
                      v-model="data.category_id"
                      :options="category"
                      placeholder="Category"
                    />
                    <div v-if="errors.category_id" class="text-danger">
                      {{ errors.category_id[0] }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Description</label>
                    <textarea
                      v-model="data.description"
                      class="form-control"
                      placeholder="Add Your Description"
                    ></textarea>
                    <div v-if="errors.description" class="text-danger">
                      {{ errors.description[0] }}
                    </div>
                  </div>
                </div>

                <div class="mb-4">
                  <label class="form-label">Image</label>
                  <div class="form-uploads">
                    <div class="form-uploads-path">
                      <img
                        v-if="preview.image"
                        :src="preview.image"
                        alt="img"
                      />
                      <img
                        v-else
                        src="@/assets/admin_img/icons/upload.svg"
                        alt="img"
                      />
                      <div class="file-browse">
                        <h6>Pilih Gambar</h6>
                        <div class="file-browse-path">
                          <input @change="handleMainImageUpload" type="file" />
                          <a href="javascript:void(0);"> Browse</a>
                        </div>
                      </div>
                      <h5>Supported formates: JPEG, PNG</h5>
                    </div>
                  </div>
                  <div v-if="errors.image" class="text-danger">
                    {{ errors.image[0] }}
                  </div>
                </div>

                <div class="mb-4">
                  <label class="form-label">Icon</label>
                  <div class="form-uploads">
                    <div class="form-uploads-path">
                      <img v-if="preview.icon" :src="preview.icon" alt="img" />
                      <img
                        v-else
                        src="@/assets/admin_img/icons/upload.svg"
                        alt="img"
                      />
                      <div class="file-browse">
                        <h6>Pilih Gambar</h6>
                        <div class="file-browse-path">
                          <input @change="handleMainIconUpload" type="file" />
                          <a href="javascript:void(0);"> Browse</a>
                        </div>
                      </div>
                      <h5>Supported formates: JPEG, PNG</h5>
                    </div>
                  </div>
                  <div v-if="errors.icon" class="text-danger">
                    {{ errors.icon[0] }}
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="btn-path">
                    <router-link to="/admin/versi" class="btn btn-cancel me-3"
                      >Cancel</router-link
                    >
                    <b-button type="submit" variant="primary">
                      Add Versi</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import swal from "sweetalert2";
import axios from "axios";
import { uploadFile } from "@/utils/fileUpload";

import { useLoading } from "vue-loading-overlay";
import { useRouter } from "vue-router";

let errors = ref({});
const $loading = useLoading({});
const router = useRouter();

let category = ref();
let data = ref({
  category: null,
});

let preview = ref({
  image: null,
  icon: null,
});

let imageFirebase = ref(null);
let iconFirebase = ref(null);

const handleMainImageUpload = (event) => {
  preview.value.image = URL.createObjectURL(event.target.files[0]);
  imageFirebase.value = event.target.files[0];
};
const handleMainIconUpload = (event) => {
  preview.value.icon = URL.createObjectURL(event.target.files[0]);
  iconFirebase.value = event.target.files[0];
};

const submitForm = async () => {
  const loader = $loading.show({});
  const formData = new FormData();
  if (data.value.category_id) {
    formData.append("category_id", data.value.category_id);
  }
  if (imageFirebase.value) {
    try {
      const response = await uploadFile(imageFirebase.value);
      console.log(response);
      formData.append("image", response);
    } catch (error) {
      console.error("Error while uploading file:", error);
    }
  }
  if (iconFirebase.value) {
    try {
      const response = await uploadFile(iconFirebase.value);
      console.log(response);
      formData.append("icon", response);
    } catch (error) {
      console.error("Error while uploading file:", error);
    }
  }
  if (data.value.name) {
    formData.append("name", data.value.name);
  }
  if (data.value.description) {
    formData.append("description", data.value.description);
  }
  try {
    const response = await axios.post("api/versi", formData);
    loader.hide();
    swal.fire({ icon: "success", text: response.data.message });
    router.push("/admin/versi");
  } catch (error) {
    loader.hide();
    console.error("Error response:", error.response);
    if (error.response && error.response.data) {
      console.error("Error data:", error.response.data);
      errors.value = error.response.data;
    } else {
      console.error("Unknown error:", error);
    }
  }
};

function transformArray(data) {
  return data.map((item) => {
    return {
      id: item.id,
      text: item.name,
    };
  });
}

const fetchData = async () => {
  try {
    const response = await axios.get("api/categories");
    category.value = response.data.categories;
    category.value = transformArray(category.value);
    category.value.unshift({ id: null, text: "Pilih Category" });
  } catch (error) {
    console.error("Error fetching categories data:", error);
  }
};
onMounted(() => {
  fetchData();
});
</script>
