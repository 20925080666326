<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <div class="content">
        <div class="content-page-header px-3 content-page-headersplit mb-0">
          <h5>Data Transaksi</h5>
          <div class="d-flex gap-2">
            <input
              v-model="searchTerm"
              class="form-control mr-sm-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
            <b-button
              @click="filterDatas"
              class="btn btn-primary my-2 my-sm-0"
              type="submit"
            >
              Search
            </b-button>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-resposnive table-div">
              <div class="table datatable">
                <a-table :columns="columns" :data-source="filteredData">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'updated_at'">
                      {{ formatDateTime(record.updated_at) }}
                    </template>
                    <template v-if="column.title === 'Action'">
                      <div class="d-flex flex justify-content-center gap-2">
                        <router-link
                          class="btn delete-table me-2"
                          :to="`/admin/transaksi/${record.id}/edit`"
                        >
                          <i class="feather feather-edit"></i>
                        </router-link>
                      </div>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Confirm
    message="Are you sure you want to delete this column?"
    title="Delete"
    buttonText="delete"
    id="delete"
    :url="URL"
    @submitted="fetchData()"
  />
</template>

<script setup>
import { reactive, computed, ref, onMounted } from "vue";
import axios from "axios";
import Confirm from "@/components/model/Confirm/index.vue";
const URL = ref(null);
const ID = ref();
const BASEURL = ref(process.env.VUE_APP_API_URL + "/api/vouchers/");
const onDelete = (id) => {
  ID.value = id;
  URL.value = BASEURL.value + ID.value;
};
const columns = reactive([
  {
    title: "No",
    dataIndex: "index",
    sorter: {
      compare: (a, b) => a.index - b.index,
    },
  },
  {
    title: "no_transaction",
    dataIndex: "no_transaction",
    key: "no_transaction",
    sorter: {
      compare: (a, b) => {
        a = a.no_transaction.toLowerCase();
        b = b.no_transaction.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "total_amount",
    dataIndex: "total_amount",
    key: "total_amount",
  },
  {
    title: "total_day",
    dataIndex: "total_day",
    key: "total_day",
  },
  {
    title: "VA",
    dataIndex: "note",
    key: "note",
    sorter: {
      compare: (a, b) => {
        a = a.note.toLowerCase();
        b = b.note.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Payment Channel",
    dataIndex: "payment_channel",
    key: "payment_channel",
    sorter: {
      compare: (a, b) => {
        a = a.payment_channel.toLowerCase();
        b = b.payment_channel.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Payment Method",
    dataIndex: "payment_method",
    key: "payment_method",
    sorter: {
      compare: (a, b) => {
        a = a.payment_method.toLowerCase();
        b = b.payment_method.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "updated_at",
    dataIndex: "updated_at",
    key: "updated_at",
  },
  {
    title: "status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Action",
    key: "id",
    sorter: true,
    class: "text-center",
  },
]);

const data = ref([]);
function formatDateTime(dateTimeString) {
  var dateObject = new Date(dateTimeString);

  // Format the date and time separately
  var formattedDate = dateObject.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
  var formattedTime = dateObject.toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
  });

  // Concatenate formatted date and time
  return formattedDate + " " + formattedTime;
}

const fetchData = async () => {
  try {
    const response = await axios.get("/api/payments/admin");
    data.value = response.data.payments.map((category, index) => ({
      ...category,
      index: index + 1,
    }));
    console.log(data.value);
    filterDatas();
  } catch (error) {
    console.error("Error fetching vouchers data:", error);
  }
};

let filteredData = ref([]);
let searchTerm = ref("");

// Function to filter payments based on transaction number
const filterDatas = () => {
  filteredData.value = data.value.filter((data) =>
    data.no_transaction.includes(searchTerm.value)
  );
};

onMounted(() => {
  fetchData();
});
</script>
