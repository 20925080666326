<template>
  <div class="provider-body">
    <div class="main-wrapper">
      <dashboardnavbar />

      <div class="page-wrapper">
        <sidebar />
        <div class="container">
          <div class="content p-5">
            <h5 class="mb-4">Tryout Gabungan</h5>
            <transition-group name="fade">
              <details
                data-aos="fade-up"
                class="tryouts__group mb-3"
                v-for="(item, index) in group"
                :key="index"
              >
                <summary>{{ item.name }}</summary>
                <div class="tryouts__group_content">
                  <div
                    v-for="(itemTryout, index) in item.tryout"
                    @click="onStart(itemTryout.id, itemTryout)"
                  >
                    <div class="tryouts__group_content_list">
                      <div class="tryout__group_content_list_name">
                        {{ itemTryout.name }}
                      </div>
                      <div class="tryout__group_content_list_access">
                        <div
                          :class="itemTryout.access"
                          class="tryout__group_content_list_access_content"
                        >
                          {{ itemTryout.access }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </details>
            </transition-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import axios from "axios";
import { useRoute, useRouter } from "vue-router";
import { useAuthStore } from "@/stores/authStore";
import swal from "sweetalert2";
const router = useRouter();
const authStore = useAuthStore();
const onStart = (tryout_id, item) => {
  // :to="{ path: index == 0 ? '/tryouts/kecermatan/'+itemTryout.id : '/tryouts/pilihanGanda/'+itemTryout.id}"
  swal
    .fire({
      text: "Apakah anda ingin memulai tryout ini?",
      width: 340,
      padding: "30px",
      showCancelButton: true,
      confirmButtonText: "Mulai",
      reverseButtons: true,
      backdrop: `
      rgba(0,0,123,0.4)
      url("/images/nyan-cat.gif")
      left top
      no-repeat
    `,
    })
    .then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        if (authStore.user.type === item.access 
        || (authStore.user.type === 'premium' && item.access === 'superior') 
        || (authStore.user.type === 'genius' && item.access === 'vip')
        || (authStore.user.type === 'superior' && item.access === 'premium') 
        || (authStore.user.type === 'vip' && item.access === 'genius')
        || (authStore.user.type === 'genius' && item.access === 'premium') 
        || (authStore.user.type === 'genius' && item.access === 'superior')
      ) {
          swal.fire("Selamat mengerjakan", "", "success");
          try {
            const res = await axios.post(
              `/api/submit-result_tryout_user/${tryout_id}`
            );
            if (res.data.genre == "number") {
              router.push({
                path: "/tryouts/kecermatan/" + res.data.data.id,
              });
            } else if (res.data.genre == "multiple_choice") {
              router.push({
                path: "/tryouts/pilihanGanda/" + res.data.data.id,
              });
            }
          } catch (err) {}
        } else if (item.access == "free") {
          try {
            const res = await axios.post(
              `/api/submit-result_tryout_user/${tryout_id}`
            );

            if (res.data.genre == "number") {
              router.push({
                path: "/tryouts/kecermatan/" + res.data.data.id,
              });
            } else if (res.data.genre == "multiple_choice") {
              router.push({
                path: "/tryouts/pilihanGanda/" + res.data.data.id,
              });
            }
          } catch (err) {
            console.error("Error while submitting result:", err);
            // Tindakan lain yang sesuai untuk menangani kesalahan
          }
        } else {
    swal
      .fire({
        title: "Anda tidak memiliki akses",
        text: "Untuk mengakses premium silahkan membeli voucher terlebih dahulu",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Beli Voucher",
        cancelButtonText: "Tidak",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          router.push("/vouchers");
        } else {
          
        }
      });
  }
}
    });
};
let data = ref([]);
const rotue = useRoute();
const TYPE_ID = rotue.query.type;
const group = ref(data);
const fetchData = async () => {
  try {
    const res = await axios.get(`/api/tryout-gabungan`);
    group.value = res.data.groups;
    console.log(group.value); 
  } catch (err) {
    console.error("Error while fetching group data:", err);
    // Tindakan lain yang sesuai untuk menangani kesalahan
  }
};
onMounted(() => {
  fetchData();
});
</script>

<style scoped>
details[open] summary ~ * {
  animation: sweep 0.2s ease-in-out;
}

details[close] summary ~ * {
  animation: sweep 0.2s ease-in-out;
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-left: -10px;
  }
  100% {
    opacity: 1;
    margin-left: 0px;
  }
}

.tryouts__group {
  padding: 20px;
  border-radius: 10px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.tryouts__group_content {
  margin-top: 20px;
  border-radius: 20px;
}

.tryouts__group_content_list {
  cursor: pointer;
  color: black;
  padding: 10px 30px 10px 30px;
  border-top: 1px solid #22222b44;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: whitesmoke;
}

.tryouts__group_content_list:hover {
  background: #1365df;
  color: white;
}

.tryout__group_content_list_access_content {
  width: 100px;
  border-radius: 5px;
  text-align: center;
  padding: 10px;
}

.free {
  background: #ed7f409c;
  color: white;
}

.premium {
  background: #40994091;
  color: white;
}

.vip {
  background: #1365df;
  color: white;
}
</style>
