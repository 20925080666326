<template>
  <!-- Sidebar -->
  <div class="sidebar" id="sidebar">
    <div class="sidebar-header">
      <div class="sidebar-logo">
        <router-link to="/admin/categories">
          <img
            src="@/assets/img/edu1.png"
            class="img-fluid logo"
            alt=""
            style="max-width: 80%; height: auto"
          />
        </router-link>
        <router-link to="/admin/categories">
          <img
            src="@/assets/admin_img/logo-small.svg"
            class="img-fluid logo-small"
            alt=""
          />
        </router-link>
      </div>
      <div class="siderbar-toggle">
        <label class="switch" id="toggle_btn">
          <input type="checkbox" @click="toggleSidebar" />
          <span class="slider round"></span>
        </label>
      </div>
    </div>

    <div class="sidebar-inner slimscroll">
      <perfect-scrollbar
        class="scroll-area"
        :settings="settings"
        @ps-scroll-y="scrollHanle"
      >
        <div id="sidebar-menu" class="sidebar-menu">
          <ul>
            <li class="menu-title m-0">
              <h6>Home</h6>
            </li>
            <!-- <li
              v-bind:class="{
                active: currentPath == 'index',
              }"
            >
              <router-link to="/admin/index"
                ><i class="feather feather-grid"></i>
                <span>Dashboard</span></router-link
              >
            </li> -->
            <!-- <li class="submenu">
              <a
                href="#sidebarServices"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="sidebarServices"
                :class="{ active: ServicesMenu, subdrop: ServicesMenu }"
              >
                <i class="feather feather-briefcase"></i>
                <span>Services</span>
                <span class="menu-arrow"
                  ><i class="feather feather-chevron-right"></i
                ></span>
              </a>
              <ul
                class="collapse"
                id="sidebarServices"
                :class="{ show: ServicesMenu }"
              >
                <li>
                  <router-link
                    v-bind:class="{
                      active: currentPath == '/admin/add-services',
                    }"
                    to="/admin/add-service"
                    >Add Service</router-link
                  >
                </li>
                <li>
                  <router-link
                    v-bind:class="{
                      active:
                        currentPath == '/admin/services' ||
                        currentPath == '/admin/active-services' ||
                        currentPath == '/admin/pending-services' ||
                        currentPath == '/admin/inactive-services' ||
                        currentPath == '/admin/deleted-services',
                    }"
                    to="/admin/services"
                    >Services</router-link
                  >
                </li>
                <li>
                  <router-link
                    v-bind:class="{
                      active: currentPath == '/admin/service-settings',
                    }"
                    to="/admin/service-settings"
                    >Service Settings</router-link
                  >
                </li>
              </ul>
            </li> -->
            <li>
              <router-link to="/admin/categories"
                ><i class="feather feather-layers"></i>
                <span>Categories</span>
              </router-link>
            </li>
            <li>
              <router-link to="/admin/versi"
                ><i class="feather feather-server"></i>
                <span>Versi</span></router-link
              >
            </li>
            <li>
              <router-link to="/admin/Types"
                ><i class="feather feather-list"></i>
                <span>Types</span></router-link
              >
            </li>
            <li>
              <router-link to="/admin/columns"
                ><i class="feather feather-layout"></i>
                <span>Columns</span></router-link
              >
            </li>
            <li>
              <router-link to="/admin/hints"
                ><i class="feather feather-layout"></i>
                <span>Hint</span></router-link
              >
            </li>
            <li>
              <router-link to="/admin/groups"
                ><i class="feather feather-grid"></i>
                <span>Groups</span></router-link
              >
            </li>
            <li>
              <router-link to="/admin/tryouts"
                ><i class="feather feather-bookmark"></i>
                <span>Tryouts</span></router-link
              >
            </li>
            <li>
              <router-link to="/admin/vouchers"
                ><i class="feather feather-credit-card"></i>
                <span>Vouchers</span></router-link
              >
            </li>
            <li>
              <router-link to="/admin/courses"
                ><i class="feather feather-book-open"></i>
                <span>Program Bimbel</span></router-link
              >
            </li>
            <li>
              <router-link to="/admin/TryoutDiscussion"
                ><i class="feather feather-youtube"></i>
                <span>Pembahasan Try out</span></router-link
              >
            </li>
            <li>
              <router-link to="/admin/datausers"
                ><i class="feather feather-users"></i>
                <span>Data Users</span></router-link
              >
            </li>

            <li>
              <router-link to="/admin/dataadmin"
                ><i class="feather feather-user-check"></i>
                <span>Data Admin</span></router-link
              >
            </li>

            <li>
              <router-link to="/admin/transaksi"
                ><i class="feather feather-dollar-sign"></i>
                <span>Data Transaksi</span></router-link
              >
            </li>

            <!-- <li class="menu-title">
              <h6>Settings</h6>
            </li>
            <li>
              <router-link to="/admin/signin"
                ><i class="feather feather-log-out"></i>
                <span>Logout</span></router-link
              >
            </li> -->
          </ul>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
  <!-- /Sidebar -->
</template>
<script>
import axios from "axios";
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
export default {
  data() {
    return {
      ReviewMenus: false,
      PayoutMenus: false,
      AddMenus: false,
      AllMenus: false,
      CountriesMenus: false,
      NewMenus: false,
      ServicesMenus: false,
      tryoutArrowDown: false,
      tryoutsMenuOpen: false,
      settings: {
        suppressScrollX: true,
      },
      activeClass: "active",
    };
  },
  methods: {
    scrollHanle(evt) {},
    isCustomDropdown() {
      //Search bar
      var searchOptions = document.getElementById("search-close-options");
      var dropdown = document.getElementById("search-dropdown");
      var searchInput = document.getElementById("search-options");

      searchInput.addEventListener("focus", () => {
        var inputLength = searchInput.value.length;
        if (inputLength > 0) {
          dropdown.classList.add("show");
          searchOptions.classList.remove("d-none");
        } else {
          dropdown.classList.remove("show");
          searchOptions.classList.add("d-none");
        }
      });

      searchInput.addEventListener("keyup", () => {
        var inputLength = searchInput.value.length;
        if (inputLength > 0) {
          dropdown.classList.add("show");
          searchOptions.classList.remove("d-none");
        } else {
          dropdown.classList.remove("show");
          searchOptions.classList.add("d-none");
        }
      });

      searchOptions.addEventListener("click", () => {
        searchInput.value = "";
        dropdown.classList.remove("show");
        searchOptions.classList.add("d-none");
      });

      document.body.addEventListener("click", (e) => {
        if (e.target.getAttribute("id") !== "search-options") {
          dropdown.classList.remove("show");
          searchOptions.classList.add("d-none");
        }
      });
    },
    initActiveMenu(ele) {
      setTimeout(() => {
        if (document.querySelector("#sidebar")) {
          let a = document
            .querySelector("#sidebar")
            .querySelector('[href="' + ele + '"]');

          if (a) {
            a.classList.add("active");
            let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
            if (parentCollapseDiv) {
              parentCollapseDiv.classList.add("show");
              parentCollapseDiv.parentElement.children[0].classList.add(
                "active"
              );
              parentCollapseDiv.parentElement.children[0].setAttribute(
                "aria-expanded",
                "true"
              );
              if (
                parentCollapseDiv.parentElement.closest(
                  ".collapse.menu-dropdown"
                )
              ) {
                parentCollapseDiv.parentElement
                  .closest(".collapse")
                  .classList.add("show");
                if (
                  parentCollapseDiv.parentElement.closest(".collapse")
                    .previousElementSibling
                )
                  parentCollapseDiv.parentElement
                    .closest(".collapse")
                    .previousElementSibling.classList.add("active");
              }
            }
          }
        }
      }, 1000);
    },
    toggleSidebar() {
      const body = document.body;
      body.classList.toggle("mini-sidebar");
    },
    toggleTryouts() {
      this.tryoutsMenuOpen = !this.tryoutsMenuOpen;
      this.tryoutArrowDown = !this.tryoutArrowDown;
    }
  },
  mounted() {
    document.addEventListener("mouseover", function (e) {
      e.stopPropagation();
      if (
        document.body.classList.contains("mini-sidebar") &&
        document.getElementById("toggle_btn").offsetParent !== null
      ) {
        var target = e.target.closest(".sidebar");
        if (target) {
          document.body.classList.add("expand-menu");
          var subdropUl = document.querySelectorAll(".subdrop + ul");
          subdropUl.forEach(function (ul) {
            ul.style.display = "block";
          });
        } else {
          document.body.classList.remove("expand-menu");
          var subdropUl = document.querySelectorAll(".subdrop + ul");
          subdropUl.forEach(function (ul) {
            ul.style.display = "none";
          });
        }
        e.preventDefault();
        return false;
      }
    });
  },
  components: {
    PerfectScrollbar,
  },

  computed: {
    currentPath() {
      return this.$route.name;
    },
    ReviewMenu() {
      return (
        this.$route.name == "/admin/review-type" ||
        this.$route.name == "/admin/review"
      );
    },
    ServicesMenu() {
      return (
        this.$route.name == "/admin/add-service" ||
        this.$route.name == "/admin/services" ||
        this.$route.name == "/admin/active-services" ||
        this.$route.name == "/admin/pending-services" ||
        this.$route.name == "/admin/inactive-services" ||
        this.$route.name == "/admin/deleted-services" ||
        this.$route.name == "/admin/service-settings"
      );
    },
    PayoutMenu() {
      return (
        this.$route.name == "/admin/payout-request" ||
        this.$route.name == "/admin/payout-settings"
      );
    },
    AddMenu() {
      return (
        this.$route.name == "/admin/add-page" ||
        this.$route.name == "/admin/pages-list" ||
        this.$route.name == "/admin/page-list" ||
        this.$route.name == "/admin/edit-page"
      );
    },
    AllMenu() {
      return (
        this.$route.name == "/admin/all-blog" ||
        this.$route.name == "/admin/add-blog" ||
        this.$route.name == "/admin/pending-blog" ||
        this.$route.name == "/admin/inactive-blog" ||
        this.$route.name == "/admin/blogs-categories" ||
        this.$route.name == "/admin/blogs-comments" ||
        this.$route.name == "/admin/edit-blog"
      );
    },
    CountriesMenu() {
      return (
        this.$route.name == "/admin/countries" ||
        this.$route.name == "/admin/states" ||
        this.$route.name == "/admin/cities"
      );
    },
    NewMenu() {
      return (
        this.$route.name == "/admin/users" ||
        this.$route.name == "/admin/customers" ||
        this.$route.name == "/admin/providers"
      );
    },
  },
};
</script>
<style>
.feather-chevron-down {
  transition: transform 0.3s;
}

.rotate-right {
  transform: rotate(0deg);
}

.rotate-down {
  transform: rotate(90deg);
}
</style>