<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <div class="content">
        <div class="content-page-header px-3 content-page-headersplit mb-0">
          <h5>Columns</h5>
          <div class="list-btn">
            <router-link to="/admin/columns/create" class="btn btn-primary">
              <i class="fa fa-plus me-2"></i>Add Columns
            </router-link>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-resposnive table-div">
              <div class="table datatable">
                <a-table :columns="columns" :data-source="data">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.title === 'Action'">
                      <div class="d-flex flex justify-content-center gap-2">
                        <router-link
                          class="btn delete-table me-2"
                          :to="`/admin/columns/${record.id}/edit`"
                        >
                          <i class="feather feather-edit"></i>
                        </router-link>
                        <button
                          @click="onDelete(record.id)"
                          class="btn delete-table"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#delete"
                        >
                          <i class="feather feather-trash-2"></i>
                        </button>
                      </div>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Confirm
    message="Are you sure you want to delete this column?"
    title="Delete"
    buttonText="delete"
    id="delete"
    :url="URL"
    @submitted="fetchData()"
  />
</template>

<script setup>
import { reactive, computed, ref } from "vue";
import axios from "axios";
import Confirm from "@/components/model/Confirm/index.vue";
const URL = ref(null);
const ID = ref();
const BASEURL = ref(process.env.VUE_APP_API_URL + "/api/columns/");
const onDelete = (id) => {
  ID.value = id;
  URL.value = BASEURL.value + ID.value;
};
const columns = reactive([
  {
    title: "No",
    dataIndex: "index",
    sorter: {
      compare: (a, b) => a.index - b.index,
    },
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    sorter: {
      compare: (a, b) => {
        a = a.name.toLowerCase();
        b = b.name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Time",
    dataIndex: "time",
    sorter: {
      compare: (a, b) => {
        a = a.image.toLowerCase();
        b = b.image.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Hints",
    dataIndex: "hints",
    scopedSlots: { customRender: "hints" }
  },
  {
    title: "Action",
    key: "id",
    sorter: true,
    class: "text-center",
  },
]);

const data = ref([]);

const fetchData = async () => {
  try {
    const columnsResponse = await axios.get("/api/columns");
    const hintsResponse = await axios.get("/api/hints");;

    data.value = columnsResponse.data.columns.map((column, index) => {
      const hints = hintsResponse.data.hints.filter(hint => hint.column_id === column.id);
      return {
        ...column,
        index: index + 1,
        hints: hints.map(hint => {
          if (hint.description.length > 50) { 
            return hint.description.substring(0, 50) + '...';
          } else {
            return hint.description;
          }
        }).join(", ") 
      };
    });
  } catch (error) {
    console.error("Error fetching data:", error);
    // Handle error here
  }
};

fetchData();
</script>

<style>
.img {
  width: 140px;
  height: 140px;
  object-fit: cover;
}
</style>
