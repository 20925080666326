<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper">
      <div class="content">
        <b-form @submit.prevent="submitForm">
          <div class="row">
            <div class="col-lg-7 col-sm-12 m-auto">
              <div class="content-page-header">
                <h5 class="mb-2">Add Categories</h5>
                <!-- <div class="form-group mb-0">
                    <p class="contentpage">You are editing "English" version</p>
                  </div> -->
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Name</label>
                    <input
                      v-model="data.name"
                      type="text"
                      class="form-control"
                    />
                    <div v-if="errors.name" class="text-danger">
                      {{ errors.name[0] }}
                    </div>
                  </div>
                </div>
                <!-- <div class="col-lg-12">
                  <div class="form-group">
                    <label> language</label>
                    <vue-select :options="Language" placeholder="English" />
                  </div>
                </div> -->

                <div class="mb-4">
                  <label class="form-label">Image</label>
                  <div class="form-uploads">
                    <div class="form-uploads-path">
                      <img
                        v-if="preview.image"
                        :src="preview.image"
                        alt="img"
                      />
                      <img
                        v-else
                        src="@/assets/admin_img/icons/upload.svg"
                        alt="img"
                      />
                      <div class="file-browse">
                        <h6>Pilih Gambar</h6>
                        <div class="file-browse-path">
                          <input type="file" @change="handleMainImageUpload" />
                          <a href="javascript:void(0);"> Browse</a>
                        </div>
                      </div>
                      <h5>Supported formates: JPEG, PNG</h5>
                    </div>
                  </div>
                  <div v-if="errors.image" class="text-danger">
                    {{ errors.image[0] }}
                  </div>
                </div>

                <div class="mb-4">
                  <label class="form-label">Icon</label>
                  <div class="form-uploads">
                    <div class="form-uploads-path">
                      <img v-if="preview.icon" :src="preview.icon" alt="img" />
                      <img
                        v-else
                        src="@/assets/admin_img/icons/upload.svg"
                        alt="img"
                      />
                      <div class="file-browse">
                        <h6>Pilih Gambar</h6>
                        <div class="file-browse-path">
                          <input type="file" @change="handleMainIconUpload" />
                          <a href="javascript:void(0);"> Browse</a>
                        </div>
                      </div>
                      <h5>Supported formates: JPEG, PNG</h5>
                    </div>
                  </div>
                  <div v-if="errors.icon" class="text-danger">
                    {{ errors.icon[0] }}
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="btn-path">
                    <b-button
                      @click="onCancel()"
                      type="button"
                      class="btn btn-cancel me-3"
                      >Cancel</b-button
                    >
                    <b-button type="submit" variant="primary">
                      Add Category</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import swal from "sweetalert2";
import axios from "axios";
import { uploadFile } from "@/utils/fileUpload";
import { useLoading } from "vue-loading-overlay";
import { useRouter } from "vue-router";
let errors = ref({});
const $loading = useLoading({});
const router = useRouter();

let data = ref({});

let preview = ref({
  image: null,
  icon: null,
});

let imageFirebase = ref(null);
let iconFirebase = ref(null);

const handleMainImageUpload = (event) => {
  preview.value.image = URL.createObjectURL(event.target.files[0]);
  imageFirebase.value = event.target.files[0];
};
const handleMainIconUpload = (event) => {
  preview.value.icon = URL.createObjectURL(event.target.files[0]);
  iconFirebase.value = event.target.files[0];
};

const submitForm = async () => {
  const loader = $loading.show({});
  const formData = new FormData();
  if (imageFirebase.value) {
    try {
      const response = await uploadFile(imageFirebase.value);
      console.log(response);
      formData.append("image", response);
    } catch (error) {
      console.error("Error while uploading file:", error);
    }
  }
  if (iconFirebase.value) {
    try {
      const response = await uploadFile(iconFirebase.value);
      console.log(response);
      formData.append("icon", response);
    } catch (error) {
      console.error("Error while uploading file:", error);
    }
  }
  if (data.value.name) {
    formData.append("name", data.value.name);
  }
  try {
    const response = await axios.post("api/categories", formData);
    loader.hide();
    swal.fire({ icon: "success", text: response.data.message });
    router.push("/admin/categories");
  } catch (error) {
    loader.hide();
    errors.value = error.response.data;
  }
};
</script>
