<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <div class="content">
        <div class="content-page-header px-3 content-page-headersplit mb-0">
          <h5>Program Bimbel </h5>
          <div class="list-btn">
            <router-link to="/admin/courses/create" class="btn btn-primary">
              <i class="fa fa-plus me-2"></i>Add Program Bimbel
            </router-link>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-resposnive table-div">
              <div class="table datatable">
                <a-table :columns="columns" :data-source="data">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.title === 'Image'">
                      <img 
                      v-if="record.url"
                      class="img img-fluid" :src="record.url" alt="" />
                      <div v-else>Tidak Ada Gambar</div>
                    </template>
                    <template v-if="column.title === 'Description'">
                      <div v-html="record.description"></div>
                    </template>
                    <template v-if="column.title === 'Action'">
                      <div class="d-flex flex justify-content-center gap-2">
                        <router-link
                          class="btn delete-table me-2"
                          :to="`/admin/courses/${record.id}/edit`"
                        >
                          <i class="feather feather-edit"></i>
                        </router-link>
                        <button
                          @click="onDelete(record.id, record.url)"
                          class="btn delete-table"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#delete"
                        >
                          <i class="feather feather-trash-2"></i>
                        </button>
                      </div>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Confirm
    message="Are you sure you want to delete this column?"
    title="Delete"
    buttonText="delete"
    id="delete"
    :url="URL"
    @submitted="fetchData()"
  />
</template>

<script setup>
import { reactive, computed, ref } from "vue";
import axios from "axios";
import Confirm from "@/components/model/Confirm/index.vue";
const URL = ref(null);
const ID = ref();
const BASEURL = ref(process.env.VUE_APP_API_URL + "/api/courses/");
const imgFirebaseDel = ref(null);
const onDelete = (id, url) => {
  ID.value = id;
  imgFirebaseDel.value = url;
  URL.value = BASEURL.value + ID.value;
};
const columns = reactive([
{
    title: "No",
    dataIndex: "index",
    sorter: {
      compare: (a, b) => a.index - b.index,
    },
  },
  {
    title: "Image",
    dataIndex: "url",
    key: "url",
    sorter: {
      compare: (a, b) => {
        a = a.url.toLowerCase();
        b = b.url.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
    sorter: {
      compare: (a, b) => {
        a = a.title.toLowerCase();
        b = b.title.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    sorter: {
      compare: (a, b) => {
        a = a.description.toLowerCase();
        b = b.description.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Price",
    dataIndex: "voucher",
    sorter: {
      compare: (a, b) => {
        a = a.voucher.toLowerCase();
        b = b.voucher.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "id",
    sorter: true,
    class: "text-center",
  },
]);

const data = ref([]);

const fetchData = async () => {
  try {
    const response = await axios.get("/api/courses");
    data.value = response.data.courses.map((category, index) => ({
      ...category,
      voucher: category.voucher
        ? `${category.voucher.amount}`
        : "",
      index: index + 1,
    }));
  } catch (error) {
    // Tangani kesalahan jika ada
    console.error("Error while fetching courses:", error);
    // Lakukan tindakan lain sesuai kebutuhan, seperti menampilkan pesan kesalahan kepada pengguna
  }
};

fetchData();
</script>
