<template>
    <div class="main-wrapper">
      <layout></layout>
      <adminsidebar></adminsidebar>
  
      <div class="page-wrapper">
        <div class="content">
          <b-form @submit.prevent="submitForm">
            <div class="row">
              <div class="col-lg-7 col-sm-12 m-auto">
                <div class="content-page-header">
                  <h5 class="mb-2">Add Hints</h5>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label>Description</label>
                      <summernoteEditor
                      v-model="data.description"
                      type="text"
                      class="form-control"
                      required
                    />
                      <div v-if="errors.description" class="text-danger">
                        {{ errors.description[0] }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label>Column</label>
                      <vue-select
                        v-model="data.column"
                        :options="column"
                        placeholder="Column"
                      />
                      <div v-if="errors.column_id" class="text-danger">
                        {{ errors.column_id[0] }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label>Time</label>
                      <input
                        v-model="data.time"
                        class="form-control"
                        placeholder="Add Your Time"
                      />
                      <div v-if="errors.time" class="text-danger">
                        {{ errors.time[0] }}
                      </div>
                    </div>
                  </div>
  
                  <div class="mb-4">
                    <label class="form-label">Image</label>
                    <div class="form-uploads">
                      <div class="form-uploads-path">
                        <img
                          v-if="preview.image"
                          :src="preview.image"
                          alt="img"
                        />
                        <img
                          v-else
                          src="@/assets/admin_img/icons/upload.svg"
                          alt="img"
                        />
                        <div class="file-browse">
                          <h6>Pilih Gambar</h6>
                          <div class="file-browse-path">
                            <input @change="handleMainImageUpload" type="file" />
                            <a href="javascript:void(0);"> Browse</a>
                          </div>
                        </div>
                        <h5>Supported formates: JPEG, PNG</h5>
                      </div>
                    </div>
                    <div v-if="errors.image" class="text-danger">
                      {{ errors.image[0] }}
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="btn-path">
                      <router-link to="/admin/hints" class="btn btn-cancel me-3"
                        >Cancel</router-link
                      >
                      <b-button type="submit" variant="primary">
                        Add Hint</b-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { onMounted, ref } from "vue";
  import swal from "sweetalert2";
  import axios from "axios";
  import { uploadFile } from "@/utils/fileUpload";
  
  import { useLoading } from "vue-loading-overlay";
  import { useRouter } from "vue-router";
  let errors = ref({});
  const $loading = useLoading({});
  const router = useRouter();
  
  let column = ref();
  let data = ref({
    column: null,
  });
  
  let preview = ref({
    image: null,
  });
  
  let imageFirebase = ref(null);
  
  const handleMainImageUpload = (event) => {
    preview.value.image = URL.createObjectURL(event.target.files[0]);
    imageFirebase.value = event.target.files[0];
  };
  
  const submitForm = async () => {
    const loader = $loading.show({});
    const formData = new FormData();
    if (data.value.column) {
      formData.append("column_id", data.value.column);
    }
    if (imageFirebase.value) {
      try {
        const response = await uploadFile(imageFirebase.value);
        console.log(response);
        formData.append("image", response);
      } catch (error) {
        console.error("Error while uploading file:", error);
      }
    }
    if (data.value.description) {
      formData.append("description", data.value.description);
    }
    if (data.value.time) {
      formData.append("time", data.value.time);
    }
    try {
      const response = await axios.post("api/hints", formData);
      loader.hide();
      swal.fire({ icon: "success", text: response.data.message });
      router.push("/admin/hints");
    } catch (error) {
      loader.hide();
      errors.value = error.response.data;
    }
  };
  
  function transformArray(data) {
    return data.map((item) => {
      return {
        id: item.id,
        text: item.name,
      };
    });
  }
  
  const fetchData = async () => {
    try {
      const response = await axios.get("api/columns");
      column.value = response.data.columns;
      column.value = transformArray(column.value);
      column.value.unshift({ id: null, text: "Pilih Column" });
    } catch (error) {
      console.error("Error fetching column data:", error);
    }
  };
  
  onMounted(() => {
    fetchData();
  });
  </script>
  