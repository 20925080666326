import { defineStore } from "pinia";
import axios from "axios";
export const useKecermatanStore = defineStore({
  id: "kecermatan",
  state: () => ({
    time: 0,
    question_anser_user: [],
    result: {},
  }),
  actions: {
    setTime(time) {
      this.time = time;
    },
    getTime() {
      return this.time;
    },
    setResult(result) {
      this.result = result;
    },
    async setData(newData) {
      const index = this.question_anser_user.findIndex(
        (item) =>
          item.question_id === newData.question_id &&
          item.result_tryout_user_id === newData.result_tryout_user_id
      );
      if (index !== -1) {
        // Jika id_soal sudah ada dalam statePil, update answer
        this.question_anser_user[index].answer_user = newData.answer_user;
        this.question_anser_user[index].time_spend = newData.time_spend;
      } else {
        // Jika tidak ada, tambahkan newData ke statePil
        this.question_anser_user.push(newData);
        
        // try {
        //   axios.post("/api/submit-answer", newData);
        // } catch (error) {
        //   throw error;
        // }

        const maxRetries = 3;
        let retryCount = 0;
        let success = false;

        while (!success && retryCount < maxRetries) {
          try {
            const response = await axios.post("/api/submit-answer", newData);
            // Tandai operasi sebagai berhasil
            success = true;
            // Lakukan sesuatu dengan respon jika diperlukan
            console.log("Response:", response.data);
          } catch (error) {
            // Tangani kesalahan jika permintaan gagal
            console.error("Error while submitting answer:", error);
            // Tambahkan hitungan retry
            retryCount++;
            // Tunggu sebentar sebelum mencoba lagi
            await new Promise(resolve => setTimeout(resolve, 1000)); // Tunggu 1 detik (opsional)
          }
        }

        if (!success) {
          console.error("Failed to submit answer after maximum retries.");
          // Lakukan tindakan lain sesuai kebutuhan, seperti menampilkan pesan kesalahan kepada pengguna
        }


      }
    },
    clearState() {
      this.question_anser_user = [];
      this.time = 0;
      this.result = {};
    },
  },
});
