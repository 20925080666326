<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper">
      <div class="content">
        <b-form @submit.prevent="updateForm" enctype="multipart/form-data">
          <div class="row">
            <div class="col-lg-7 col-sm-12 m-auto">
              <div class="content-page-header">
                <h5 class="mb-2">Edit Category</h5>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Name</label>
                    <input
                      v-model="data.name"
                      type="text"
                      class="form-control"
                    />
                    <div v-if="errors.name" class="text-danger">
                      {{ errors.name[0] }}
                    </div>
                  </div>
                </div>
                <div class="mb-4">
                  <label class="form-label">Image</label>
                  <div class="form-uploads">
                    <div class="form-uploads-path">
                      <img
                        v-if="preview.image"
                        :src="preview.image"
                        alt="img"
                      />
                      <img
                        v-else-if="data.image && !preview.image"
                        :src="data.image"
                        alt="img"
                      />
                      <img
                        v-else
                        src="@/assets/admin_img/icons/upload.svg"
                        alt="img"
                      />
                      <div class="file-browse">
                        <h6>Pilih Gambar</h6>
                        <div class="file-browse-path">
                          <input @change="handleMainImageUpload" type="file" />
                          <a href="javascript:void(0);"> Browse</a>
                        </div>
                      </div>
                      <h5>Supported formates: JPEG, PNG</h5>
                    </div>
                  </div>
                  <div v-if="errors.image" class="text-danger">
                    {{ errors.image[0] }}
                  </div>
                </div>

                <div class="mb-4">
                  <label class="form-label">Icon</label>
                  <div class="form-uploads">
                    <div class="form-uploads-path">
                      <img v-if="preview.icon" :src="preview.icon" alt="img" />
                      <img
                        v-else-if="data.icon && !preview.icon"
                        :src="data.icon"
                        alt="img"
                      />
                      <img
                        v-else
                        src="@/assets/admin_img/icons/upload.svg"
                        alt="img"
                      />
                      <div class="file-browse">
                        <h6>Pilih Gambar</h6>
                        <div class="file-browse-path">
                          <input @change="handleIconUpload" type="file" />
                          <a href="javascript:void(0);"> Browse</a>
                        </div>
                      </div>
                      <h5>Supported formates: JPEG, PNG</h5>
                    </div>
                  </div>
                  <div v-if="errors.icon" class="text-danger">
                    {{ errors.icon[0] }}
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="btn-path">
                    <b-button to="/admin/categories" class="btn btn-cancel me-3"
                      >Cancel</b-button
                    >
                    <b-button type="submit" variant="primary">
                      Edit Category</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref } from "vue";
import swal from "sweetalert2";
import axios from "axios";
import { useLoading } from "vue-loading-overlay";
import { deleteFileByUrl } from "@/utils/fileUpload";
import { uploadFile } from "@/utils/fileUpload";
import { useRoute, useRouter } from "vue-router";
let errors = ref({});
const $loading = useLoading({});
const router = useRouter();
const route = useRoute();
let data = ref({});
let preview = ref({
  image: null,
  icon: null,
});
let imageFirebase = ref(null);
let iconFirebase = ref(null);

const handleMainImageUpload = (event) => {
  preview.value.image = URL.createObjectURL(event.target.files[0]);
  imageFirebase.value = event.target.files[0];
};
const handleIconUpload = (event) => {
  preview.value.icon = URL.createObjectURL(event.target.files[0]);
  iconFirebase.value = event.target.files[0];
};

const updateForm = async () => {
  const loader = $loading.show({});
  const formData = new FormData();
  if (imageFirebase.value) {
    try {
      await deleteFileByUrl(data.value.image);
    } catch (error) {
      console.error("Error while deleting file:", error);
    }
    try {
      const response = await uploadFile(imageFirebase.value);
      console.log(response);
      formData.append("image", response);
    } catch (error) {
      console.error("Error while uploading file:", error);
    }
  } else if (data.value.image) {
    console.log(data.value.image);
    formData.append("image", data.value.image);
  }
  if (iconFirebase.value) {
    try {
      await deleteFileByUrl(data.value.icon);
    } catch (error) {
      console.error("Error while deleting file:", error);
    }
    try {
      const response = await uploadFile(iconFirebase.value);
      console.log(response);
      formData.append("icon", response);
    } catch (error) {
      console.error("Error while uploading file:", error);
    }
  } else if (data.value.icon) {
    formData.append("icon", data.value.icon);
  }
  formData.append("name", data.value.name);
  formData.append("_method", "put");
  try {
    const response = await axios.post(
      `api/categories/${route.params.id}`,
      formData
    );
    loader.hide();
    swal.fire({ icon: "success", text: response.data.message });
    router.push("/admin/categories");
  } catch (error) {
    loader.hide();
    errors.value = error.response.data;
  }
};

const fetchData = async () => {
  try {
    const response = await axios.get(`api/categories/${route.params.id}/edit`);
    data.value = response.data.category;
  } catch (error) {
    console.error("Error fetching category:", error);
    // Handle error here, misalnya menampilkan pesan kesalahan kepada pengguna
  }
};

onMounted(() => {
  fetchData();
});
</script>
