import { createApp } from 'vue'
import { router } from '@/router';
import { createPinia } from 'pinia'
import App from '@/App.vue'
import {BootstrapVue3, BToastPlugin} from 'bootstrap-vue-3'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import VueSelect from 'vue3-select2-component'
import VCalendar from 'v-calendar';
import SummernoteEditor from 'vue3-summernote-editor';
import DatePicker from 'vue3-datepicker'
import VueTelInput from 'vue3-tel-input'
import VueTimepicker from 'vue3-timepicker'
import VueApexCharts from "vue3-apexcharts";
import VueEasyLightbox from "vue-easy-lightbox";
import Vue3Autocounter from 'vue3-autocounter';
import VueSweetalert2 from 'vue-sweetalert2' 
import 'sweetalert2/dist/sweetalert2.min.css';
import AOS from 'aos'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { VLazyImagePlugin } from 'v-lazy-image';

// Firebase
import firebase from "firebase/compat/app";
import 'firebase/compat/storage';
import "firebase/compat/analytics";
import "firebase/compat/database";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAsUmHxqP_vQKDTXlzrJdAYu2QFgjMXgrM",
  authDomain: "gps-tracker-3c2a1.firebaseapp.com",
  databaseURL: "https://gps-tracker-3c2a1.firebaseio.com",
  projectId: "gps-tracker-3c2a1",
  storageBucket: "gps-tracker-3c2a1.appspot.com",
  messagingSenderId: "893074004983",
  appId: "1:893074004983:web:f27abdb4faaa62d395acf2"
};

firebase.initializeApp(firebaseConfig);


// Components
import header from '@/views/frontend/layouts/header.vue'
import HeaderMenu from '@/components/headerMenu.vue'
import CustomerHeadMenu from '@/components/customerheadermenu.vue'
import Nav from '@/components/navbar.vue'
import CustomNav from '@/components/customernav.vue'
// import CustomBookCalendarNav from '@/components/customerbookcalendernav.vue'
import OtpHeader from '@/components/otpheader.vue'
import SuccessHeader from '@/components/successnav.vue'
import DashNav from '@/components/dashboardnav.vue'
import SideBar from '@/views/frontend/layouts/sidebar.vue'
// import Cursor from '@/views/frontend/layouts/cursor.vue'
import HeaderTop from '@/views/frontend/layouts/headertop.vue'
import IndexEightHeader from '@/components/indexeightheader.vue'
import Customer_Sidebar from '@/views/frontend/layouts/customer-sidebar.vue'

/************Page Components********************/
// import About from '@/views/frontend/pages/Pages/AboutUs/about.vue'
// import AboutProvider from '@/views/frontend/pages/Pages/AboutUs/aboutProviders.vue'
// import CouponAdd from '@/views/frontend/pages/Providers/Coupons/Addcoupon/couponadd.vue'
// import AddSubContent from '@/views/frontend/pages/Providers/Subscription/Addsubscription/subcontent.vue'
// import BlogContent from '@/views/frontend/pages/Blog/blogdetails/blogcontent.vue'
// import BlogSidebar from '@/views/frontend/pages/Blog/blogdetails/blogsidebar.vue'
// import BlogGridContent from '@/views/frontend/pages/Blog/bloggrid/bloggridcontent.vue'
// import BlogListContent from '@/views/frontend/pages/Blog/bloglist/bloglistcontent.vue'
// import BookingDoneContent from '@/views/frontend/pages/Pages/Booking/Booking-done/bookingdonecontent'
// import BookPayContent from '@/views/frontend/pages/Pages/Booking/Booking-payment/bookingpaymentcontent.vue'
// import BookingContent from '@/views/frontend/pages/Pages/Booking/booking/bookingcontent.vue'
// import CategoriesContent from '@/views/frontend/pages/Pages/Categories/categoriescontent.vue'
// import ChangePassWordContent from '@/views/frontend/pages/Pages/ChangePassword/changepasswordcontent.vue'
// import ChooseSignUpContent from '@/views/frontend/pages/Pages/Authentication/ChooseSignup/choosesignupcontent.vue'
// import ComingSoonContent from '@/views/frontend/pages/Pages/Comingsoon/comingsooncontent.vue'
// import ConnectedAppsContent from '@/views/frontend/pages/Customers/Settings/Connectedapps/connectappscontent.vue'
// import ContactContent from '@/views/frontend/pages/Pages/ContactUs/contactcontent.vue'
// import ContactMap from '@/views/frontend/pages/Pages/ContactUs/contactmap.vue'
// import CustomerCalendarContent from '@/views/frontend/pages/Customers/Booking/CustomerBookingCalender/Customercalendarcontent.vue'
// import CustomerBookDetails from '@/views/frontend/pages/Customers/Booking/CustomerBookingCalender/customerbookingdetails.vue'
// import CustomerReschedule from '@/views/frontend/pages/Customers/Booking/CustomerBookingCalender/reschedule.vue'
// import CancelAppointment from '@/views/frontend/pages/Customers/Booking/CustomerBookingCalender/cancelappointment.vue'
// import CustomerBookingContent from '@/views/frontend/pages/Customers/Booking/Customerbooking/customerbookingcontent.vue'
// import CustomerAddReview from '@/views/frontend/pages/Customers/Booking/Customerbooking/addreview.vue'
// import CustomerChatContent from '@/views/frontend/pages/Customers/Chat/customerchatcontent.vue'
// import CustomerBoardContent from '@/views/frontend/pages/Customers/Dashboard/customerdashboardcontent.vue'
// import CustomerFavContent from '@/views/frontend/pages/Customers/Favourites/customerfavouritecontent.vue'
// import CustomerNotifyContent from '@/views/frontend/pages/Customers/Settings/Customernotification/customernotificationcontent.vue'
// import CustomerNotifyDeleteAcc from '@/views/frontend/pages/Customers/Settings/Customernotification/deleteaccount.vue'
// import CustomerProfileContent from '@/views/frontend/pages/Customers/Settings/Customerprofile/customerprofilecontent.vue'
// import CustomProfileDelete from '@/views/frontend/pages/Customers/Settings/Customerprofile/deleteaccount.vue'
// import CustomerReviewContent from '@/views/frontend/pages/Customers/Reviews/customerreviewscontent.vue'
// import CustomerWalletContent from '@/views/frontend/pages/Customers/Wallet/customerwalletcontent.vue'
// import AddWallet from '@/views/frontend/pages/Customers/Wallet/addwallet.vue'
// import DevicemanageContent from '@/views/frontend/pages/Devicemanagement/devicemanagementcontent.vue'
// import EmailOtpContent from '@/views/Emailotp/emailotpcontent.vue'
// import Error404 from '@/views/frontend/pages/Pages/Errorpages/Error404/error404content.vue'
// import Error500 from '@/views/frontend/pages/Pages/Errorpages/Error500/error500content.vue'
// import FaqContent from '@/views/frontend/pages/Pages/Faq/faqcontent.vue'
import FooterDown from '@/views/frontend/layouts/footer.vue'
// import FreeTrialContent from '@/views/frontend/pages/Pages/Authentication/Freetrial/freetrialcontent.vue'
// import HowItsWorkContent from '@/views/frontend/pages/Pages/Howitswork/howitsworkcontent.vue'
// import InvoiceContent from '@/views/frontend/pages/Providers/Invoice/invoicecontent.vue'
import PasswordRecoveryContent from '@/views/Passwordupdate/passwordrecoverycontent.vue'
// import PaymentSettingContent from '@/views/frontend/pages/Providers/settings/Paymentsettings/paymentsettingcontent.vue'
// import PhoneOtpContent from '@/views/frontend/pages/Pages/Authentication/Phoneotp/phoneotpcontent.vue';
// import PricingContent from '@/views/frontend/pages/Pages/Pricingplan/pricingcontent.vue'
 import PrivacyPolicyContent from '@/views/frontend/pages/Pages/Privacypolicy/privacypolicycontent.vue'
// import ProviderAddonsContent from '@/views/frontend/pages/Providers/Subscription/Provideraddons/provideraddonscontent.vue'
// import ProviderDeleteAccount from '@/views/frontend/pages/Providers/Subscription/Provideraddons/deleteaccount.vue'
// import Dashboardbooking from '@/views/frontend/pages/Providers/Dashboard/dashboardbooking.vue'
// import Myserviceactive from '@/views/frontend/pages/Providers/Myservices/myserviceactive.vue'
// import Myserviceinactive from '@/views/frontend/pages/Providers/Myservices/myserviceinactive.vue'
// import Proivderbookinglist from '@/views/frontend/pages/Providers/Booking/Booking/proivderbookinglist.vue'
// import Pagination from '@/views/frontend/pages/Customers/Booking/Customerbooking/pagination.vue'
// import Dashboardwidget from '@/views/frontend/pages/Customers/Dashboard/dashboardwidget.vue'
// import Recentbooking from '@/views/frontend/pages/Customers/Dashboard/recentbooking.vue'
// import Recenttransaction from '@/views/frontend/pages/Customers/Dashboard/recenttransaction.vue'
// import Favouritesort from '@/views/frontend/pages/Customers/Favourites/favouritesort.vue'
// import Favouritelist from '@/views/frontend/pages/Customers/Favourites/favouritelist.vue'

// import AvailabilityContent from '@/views/frontend/pages/Providers/Availability/availabilitycontent.vue'
// import AvailabilityDeleteAcc from '@/views/frontend/pages/Providers/Availability/deleteaccount.vue'
// import BookingDetailsContent from '@/views/frontend/pages/Providers/Booking/Bookingdetails/bookingdetailscontent.vue'
// import BookingDetailsAddLeave from '@/views/frontend/pages/Providers/Booking/Bookingdetails/bookingdetailsaddleave.vue'
// import BookingDetailsDeleteAcc from '@/views/frontend/pages/Providers/Booking/Bookingdetails/deleteaccount.vue'
// import ProviderBookingcontent from '@/views/frontend/pages/Providers/Booking/Booking/bookingcontent.vue'
// import ProviderBookDelateAcc from '@/views/frontend/pages/Providers/Booking/Booking/deleteaccount.vue'
// import ProviderChatContent from '@/views/frontend/pages/Providers/Chat/chatcontent.vue'
// import ProviderChatDeleteAcc from '@/views/frontend/pages/Providers/Chat/deleteaccount.vue'
// import ProviderConnectAppContent from '@/views/frontend/pages/Providers/settings/Connectedapps/connectedappcontent.vue'
// import ProviderCouponContent from '@/views/frontend/pages/Providers/Coupons/Coupons/couponscontent.vue'
// import ProviderDashboardContent from '@/views/frontend/pages/Providers/Dashboard/dashboardcontent.vue'
// import ProviderDashboardDeleteAcc from '@/views/frontend/pages/Providers/Dashboard/deleteaccount.vue'
// import ProviderDetails from '@/views/frontend/pages/Services/Provider/ProviderDetails/detailscontent.vue'
// import ProviderDeviceManagementContent from '@/views/frontend/pages/Providers/Devicemanagement/devicemanagementcontent.vue'
// import ProviderEarningContent from '@/views/frontend/pages/Providers/Earnings/earningscontent.vue'
// import ProviderEarningDeleteAcc from '@/views/frontend/pages/Providers/Earnings/deleteaccount.vue'
// import ProviderEditServiceContent from '@/views/frontend/pages/Providers/Myservices/Editservices/editservicecontent.vue'
// import ProviderHolidayContent from '@/views/frontend/pages/Providers/Holiday&Leaves/holidaycontent.vue'
// import ProviderAddHoliday from '@/views/frontend/pages/Providers/Holiday&Leaves/addholiday.vue'
// import ProviderAlertMessage from '@/views/frontend/pages/Providers/Holiday&Leaves/alertmessage.vue'
// import ProviderNotificationContent from '@/views/frontend/pages/Providers/settings/Notification/notificationcontent.vue'
// import ProviderOfferContent from '@/views/frontend/pages/Providers/Offers/offerscontent.vue'
// import ProviderAddCoupon from '@/views/frontend/pages/Providers/Offers/addcoupon.vue'
// import ProviderPayoutContent from '@/views/frontend/pages/Providers/Payout/payoutcontent.vue'
// import ProviderAddWallet from '@/views/frontend/pages/Providers/Payout/addwallet.vue'
// import ProviderPlanContent from '@/views/frontend/pages/Providers/settings/Plan&billings/plancontent.vue'
// import ProviderProfileSettingsContent from '@/views/frontend/pages/Providers/settings/Accountsettings/profilesettingscontent.vue'
// import ProviderReviewContent from '@/views/frontend/pages/Providers/Reviews/reviewcontent.vue'
// import ProviderReviewDeleteAcc from '@/views/frontend/pages/Providers/Reviews/deleteaccount.vue'
// import ProviderSecurityContent from '@/views/frontend/pages/Providers/settings/Security/securitycontent.vue'
// import ProviderSecurityChangeEmail from '@/views/frontend/pages/Providers/settings/Security/changeemail.vue'
// import ProviderServiceContent from '@/views/frontend/pages/Providers/Myservices/Serviceslist/serviceslistcontent.vue'
// import ProviderServiceActive from '@/views/frontend/pages/Providers/Myservices/Serviceslist/activeservice.vue'
// import ProviderServiceInactive from '@/views/frontend/pages/Providers/Myservices/Serviceslist/inactiveservice.vue'
// import ProviderServiceDeleteService from '@/views/frontend/pages/Providers/Myservices/Serviceslist/deleteservice.vue'
// import ProviderServicesContent from '@/views/frontend/pages/Providers/Myservices/servicecontent.vue'
// import ProviderActiveService from '@/views/frontend/pages/Providers/Myservices/activeservice.vue'
// import ProviderDeleteService from '@/views/frontend/pages/Providers/Myservices/deleteservice.vue'
// import ProviderSocialProfileContent from '@/views/frontend/pages/Providers/settings/Socialprofiles/socialprofilecontent.vue'
// import ProviderSubscriptionContent from '@/views/frontend/pages/Providers/Subscription/subscriptioncontent.vue'
// import ProviderSubscriptionDeleteAcc from '@/views/frontend/pages/Providers/Subscription/deleteaccount.vue'
// import ResetpasswordContent from '@/views/Resetpassword/resetpasswordcontent.vue'
// import SearchListContent from '@/views/frontend/pages/Services/Searchlist/searchlistcontent.vue'
// import SecuritySettingsContent from '@/views/frontend/pages/Customers/Settings/Securitysettings/securitysettingscontent.vue'
// import SecuritySettingsChangeMail from '@/views/frontend/pages/Customers/Settings/Securitysettings/changeemail.vue'
// import ServiceDetailsContent from '@/views/frontend/pages/Services/Servicedetails/servicedetailscontent.vue'
// import ServiceListContent from '@/views/frontend/pages/Services/Servicelist/servicelistcontent.vue'
import UserSignupContent from '@/views/frontend/pages/Pages/Authentication/ChooseSignup/Usersignup/usersignupcontent.vue'
// import IndexProfessional from '@/views/frontend/pages/Index/indexprofessional.vue'
// import IndexFeatureService from '@/views/frontend/pages/Index/indexfeatureservice.vue'
// import IndexOffering from '@/views/frontend/pages/Index/indexoffering.vue'
// import IndexPlaning from '@/views/frontend/pages/Index/indexplaning.vue'
// import IndexBlog from '@/views/frontend/pages/Index/indexblog.vue'
// import RelatedService from '@/views/frontend/pages/Services/Servicedetails/relatedservice.vue'
// import Scroll from '@/views/frontend/pages/scroll.vue'
// import IndexEightBanner from '@/views/frontend/pages/indexeight/indexeightbanner.vue'
// import IndexEightPopular from '@/views/frontend/pages/indexeight/indexeightpopular.vue'
// import IndexEightMeet from '@/views/frontend/pages/indexeight/indexeightmeet.vue'
// import IndexEightCustomer from '@/views/frontend/pages/indexeight/indexeightcustomer.vue'
// import IndexEightBlog from '@/views/frontend/pages/indexeight/indexeightblog.vue'
// import IndexEightFooter from '@/views/frontend/pages/indexeight/footer.vue'
// import ServiceInformation from '@/views/frontend/pages/Services/Createservice/service-information.vue'
// import ServiceAvailability from '@/views/frontend/pages/Services/Createservice/availability.vue'
// import ServiceLocation from '@/views/frontend/pages/Services/Createservice/service-location.vue'
// import ServiceGallery from '@/views/frontend/pages/Services/Createservice/service-gallery.vue'
// import ServiceSeo from '@/views/frontend/pages/Services/Createservice/service-seo.vue'
// import Bookingsort from '@/views/frontend/pages/Customers/Booking/Customerbooking/bookingsort.vue'
// import Bookinglist from '@/views/frontend/pages/Customers/Booking/Customerbooking/bookinglist.vue'
// import TimePicker from '@/views/frontend/pages/Services/Createservice/timepicker.vue'
// import ServiceLightbox from '@/views/frontend/pages/Services/Servicedetails/service-lightbox.vue'
// import Servicessticksidebar from '@/views/frontend/pages/Services/Servicedetails/servicessticksidebar.vue'
// import Servicedetails from '@/views/frontend/pages/Services/Servicedetails/service.vue'

import BreadCrumb from '@/components/Breadcrumb/Component.vue'
import BlogBreadcrumb from '@/components/Breadcrumb/Blogcomponent.vue'
import ProviderHeader from '@/components/Breadcrumb/provider-header.vue'

// *************** Model Components ***************
import Model from '@/components/model/model.vue'
import C_Booking_Model from '@/components/model/c-booking-model.vue'
import Provider_Coupons_Model from '@/components/model/provider-coupons-model.vue'
import Provider_Booking_Model from '@/components/model/provider-booking-model.vue'
import Provider_settings_Model from '@/components/model/provider-settings-model.vue'



//admin component
// import Adminabusereport from '@/components/admin/pageheader.vue'
// import AdminServiceInformation from '@/views/admin/pages/Services/Addservice/service-information.vue'
// import AdminServiceAvailability from '@/views/admin/pages/Services/Addservice/availability.vue'
// import AdminServiceLocation from '@/views/admin/pages/Services/Addservice/location.vue'
// import AdminServiceGallery from '@/views/admin/pages/Services/Addservice/gallery.vue'
// import AdminServiceTimepicker from '@/views/admin/pages/Services/Addservice/timepicker.vue'
// import AdminServiceSeo from '@/views/admin/pages/Services/Addservice/seo.vue'

//admin 
import Adminheader from '@/views/admin/layouts/header.vue'
import Adminsidebar from '@/views/admin/layouts/sidebar.vue'
import SideLinkHeading from '@/views/admin/layouts/sidelinkheading.vue'
import ServicesTab from '@/views/admin/layouts/servicestab'
// import Active_Blog from '@/views/admin/pages/Content/Blog/active-blog.vue'
// import Inactive_Bloglist from '@/views/admin/pages/Content/Blog/inactive-bloglist.vue'
// import Pending_Bloglist from '@/views/admin/pages/Content/Blog/pending-bloglist.vue'
// import Chat_Profile from '@/views/admin/pages/Others/chat-profile.vue'
// import Chatcontent from '@/views/admin/pages/Others/chatcontent.vue'
// import Chatuser_list from '@/views/admin/pages/Others/chatuser-list.vue'

/*********************** Admin Model  *******************/
// import Announcement_Model from '@/components/model/admin-model/announcement-model'
// import Abusemodel from '@/components/model/admin-model/abusemodel.vue'
// import Sms_Template_Model from '@/components/model/admin-model/sms-template-model.vue'
// import Email_Template_Model from '@/components/model/admin-model/email-template-model.vue'
// import Email_Newsletter_Model from '@/components/model/admin-model/email-newsletter-model.vue'
// import Featured_Service_Model from '@/components/model/admin-model/featured-service-model.vue'
// import Service_Offers_Model from '@/components/model/admin-model/service-offers-model.vue'
// import Marketing_Coupons_Model from '@/components/model/admin-model/marketing-coupons-model.vue'
// import Roles_Model from '@/components/model/admin-model/roles-model.vue'
// import Customers_Model from '@/components/model/admin-model/customers-model.vue'
// import Provider_Model from '@/components/model/admin-model/provider-model.vue'
// import Faq_Model from '@/components/model/admin-model/faq-model.vue'
// import Testimonials_Model from '@/components/model/admin-model/testimonials-model.vue'
// import Conutries_Model from '@/components/model/admin-model/conutries-model.vue'
// import States_Model from '@/components/model/admin-model/states-model.vue'
// import Cities_Model from '@/components/model/admin-model/cities-model.vue'
// import Users_Model from '@/components/model/admin-model/users-model.vue'
// import Pages_Model from '@/components/model/admin-model/pages-model.vue'
// import Payout_Request_Model from '@/components/model/admin-model/payout-request-model.vue'
// import Sub_Categories_Model from '@/components/model/admin-model/sub-categories-model.vue'
// import Categories_Model from '@/components/model/admin-model/categories-model.vue'
// import Review_Type_Model from '@/components/model/admin-model/review-type-model.vue'
// import Report_Delete_Model from '@/components/model/admin-model/report-delete-model.vue'
// import Delete_Model from '@/components/model/admin-model/delete-model.vue'
// import Delete_Model_One from '@/components/model/admin-model/delete-model-one.vue'
// import Taxrate_model from '@/components/model/admin-model/taxratemodel.vue'
// import Security_Model from '@/components/model/admin-model/security-model.vue'
// import Blog_Categories_Model from '@/components/model/admin-model/blog-categories-model.vue'
// import Database_Backup_Model from '@/components/model/admin-model/database-backup-model.vue'
// import System_Model from '@/components/model/admin-model/system-model.vue'
// import Banip_Model from '@/components/model/admin-model/banip-model.vue'
// import Storage_Model from '@/components/model/admin-model/storage-model.vue'
// import Currencies_Model from '@/components/model/admin-model/currencies-model.vue'
// import Payment_Model from '@/components/model/admin-model/payment-model.vue'
// import Gateways_Model from '@/components/model/admin-model/gateways-model.vue'
// import Sms_Model from '@/components/model/admin-model/sms-model.vue'
// import Email_Model from '@/components/model/admin-model/email-model.vue'
// import Social_Model from '@/components/model/admin-model/social-model.vue'
// import Contact_Message_Model from '@/components/model/admin-model/contact-message-model.vue'
// import Offers_Model from '@/components/model/admin-model/offers-model.vue'


// plugins
import 'aos/dist/aos.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@/assets/plugins/summernote/summernote-lite.min.css';
import '@/assets/plugins/summernote/summernote-lite.min.js';
import 'vue3-timepicker/dist/VueTimepicker.css';
import '@/assets/css/feather.css';
import '@/assets/css/feather.min.js';


import swal from 'sweetalert2';
window.Swal = swal;

if(window.location.href.includes("/admin")) {
    require('@/assets/css/admin.css');
  }
  else {
    require('vue3-tel-input/dist/vue3-tel-input.css');
    require('@/assets/css/style.css');
}


const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
const app = createApp(App)
app.use(pinia)

// Set bahasa default ke bahasa Inggris
app.config.globalProperties.$lang = 'en';

//admin component
// app.component('abusereport', Adminabusereport)
// app.component('admin-service-information', AdminServiceInformation)
// app.component('admin-service-availability', AdminServiceAvailability)
// app.component('admin-service-location', AdminServiceLocation)
// app.component('admin-service-gallery', AdminServiceGallery)
// app.component('admin-service-timepicker', AdminServiceTimepicker)
// app.component('admin-service-seo', AdminServiceSeo)
// app.component('active-blog',Active_Blog)
// app.component('inactive-bloglist',Inactive_Bloglist)
// app.component('pending-bloglist',Pending_Bloglist)
// app.component('chat-profile',Chat_Profile)
// app.component('chatuser-list',Chatuser_list)
// app.component('chatcontent',Chatcontent)


// *************** layout Components ***************
app.component('layout', Adminheader)
app.component('adminsidebar', Adminsidebar)
app.component('sidelinkheading',SideLinkHeading)
app.component('servicestab', ServicesTab)

app.component('layouts',header)
app.component('headerMenu',HeaderMenu)
app.component('customerheadmenu',CustomerHeadMenu)
app.component('otpheader',OtpHeader)
app.component('navbar',Nav)
app.component('customernav',CustomNav)
app.component('dashboardnavbar',DashNav)
app.component('successheader',SuccessHeader)
app.component('headertop',HeaderTop)
app.component('indexeightheader',IndexEightHeader)
app.component('customer-sidebar',Customer_Sidebar)


// *************** pages Components ***************

// app.component('aboutprovider',AboutProvider)
app.component('truelyfooter',FooterDown)
app.component('sidebar',SideBar)
// app.component('couponadd',CouponAdd)
// app.component('addsubcontent',AddSubContent)
// app.component('cursorpointer',Cursor)
// app.component('about-us',About)
// app.component('blogcontent',BlogContent)
// app.component('blogsidebar',BlogSidebar)
// app.component('bloggridcontent',BlogGridContent)
// app.component('bloglistcontent',BlogListContent)
// app.component('bookingdonecontent',BookingDoneContent)
// app.component('bookingpaycontent',BookPayContent)
// app.component('bookingcontent',BookingContent)
// app.component('categoriescontent',CategoriesContent)
// app.component('changepasswordcontent',ChangePassWordContent)
// app.component('choosesignupcontent',ChooseSignUpContent)
// app.component('comingsooncontent',ComingSoonContent)
// app.component('connectappscontent',ConnectedAppsContent)
// app.component('contactcontent',ContactContent)
// app.component('contactmap',ContactMap)
// app.component('custombookcalendarnav',CustomBookCalendarNav)
// app.component('customercalendarcontent',CustomerCalendarContent)
// app.component('customerbookdetails',CustomerBookDetails)
// app.component('customerreschedule',CustomerReschedule)
// app.component('cancelappointment',CancelAppointment)
// app.component('customerbookingcontent',CustomerBookingContent)
// app.component('customeraddreview',CustomerAddReview)
// app.component('customerchatcontent',CustomerChatContent)
// app.component('customerboardcontent',CustomerBoardContent)
// app.component('customerfavcontent',CustomerFavContent)
// app.component('customernotifycontent',CustomerNotifyContent)
// app.component('customnotifydeleteacc',CustomerNotifyDeleteAcc)
// app.component('customerprofilecontent',CustomerProfileContent)
// app.component('customprofiledelete',CustomProfileDelete)
// app.component('customerreviewcontent',CustomerReviewContent)
// app.component('customerwalletcontent',CustomerWalletContent)
// app.component('addwallet',AddWallet)
// app.component('devicemanagementcontent',DevicemanageContent)
// app.component('emailotpcontent',EmailOtpContent)
// app.component('error404',Error404)
// app.component('error500',Error500)
// app.component('faqcontent',FaqContent)
// app.component('freetrialcontent',FreeTrialContent)
// app.component('howitsworkcontent',HowItsWorkContent)
// app.component('invoicecontent',InvoiceContent)
app.component('passwordrecoverycontent',PasswordRecoveryContent)
// app.component('paymentsettingcontent',PaymentSettingContent)
// app.component('phoneotpcontent',PhoneOtpContent)
// app.component('pricingcontent',PricingContent)
app.component('privacypolicycontent',PrivacyPolicyContent)
// app.component('provideraddonscontent',ProviderAddonsContent)
// app.component('providerdeleteaccount',ProviderDeleteAccount)
// app.component('availabilitycontent',AvailabilityContent)
// app.component('availabilitydelectacc',AvailabilityDeleteAcc)
// app.component('bookingdetailscontent',BookingDetailsContent)
// app.component('bookingdetailsaddleave',BookingDetailsAddLeave)
// app.component('bookingdetailsdeleteacc',BookingDetailsDeleteAcc)
// app.component('providerbookingcontent',ProviderBookingcontent)
// app.component('providerbookdeleteacc',ProviderBookDelateAcc)
// app.component('providerchatcontent',ProviderChatContent)
// app.component('providerchatdeleteacc',ProviderChatDeleteAcc)
// app.component('providerconnectappcontent',ProviderConnectAppContent)
// app.component('providercouponcontent',ProviderCouponContent)
// app.component('providerdashboardcontent',ProviderDashboardContent)
// app.component('providerdashboarddeleteacc',ProviderDashboardDeleteAcc)
// app.component('providerdetails',ProviderDetails)
// app.component('providerdevicemanagementContent',ProviderDeviceManagementContent)
// app.component('providerearningcontent',ProviderEarningContent)
// app.component('providerearningdeleteacc',ProviderEarningDeleteAcc)
// app.component('providereditservicecontent',ProviderEditServiceContent)
// app.component('providerholidaycontent',ProviderHolidayContent)
// app.component('provideraddholiday',ProviderAddHoliday)
// app.component('provideralertmessage',ProviderAlertMessage)
// app.component('providernotificationcontent',ProviderNotificationContent)
// app.component('provideroffercontent',ProviderOfferContent)
// app.component('provideraddcoupon',ProviderAddCoupon)
// app.component('providerpayoutcontent',ProviderPayoutContent)
// app.component('provideraddwallet',ProviderAddWallet)
// app.component('providerPlancontent',ProviderPlanContent)
// app.component('providerprofilesettingscontent',ProviderProfileSettingsContent)
// app.component('providerreviewcontent',ProviderReviewContent)
// app.component('providerreviewdeleteacc',ProviderReviewDeleteAcc)
// app.component('providersecuritycontent',ProviderSecurityContent)
// app.component('providersecuritychangeemail',ProviderSecurityChangeEmail)
// app.component('providerservicecontent',ProviderServiceContent)
// app.component('providersserviceactive',ProviderServiceActive)
// app.component('providerserviceinactive',ProviderServiceInactive)
// app.component('providerservicedeleteservice',ProviderServiceDeleteService)
// app.component('providerservicescontent',ProviderServicesContent)
// app.component('provideractiveservice',ProviderActiveService)
// app.component('providerdeleteservice',ProviderDeleteService)
// app.component('providersocialprofilecontent',ProviderSocialProfileContent)
// app.component('providersubscriptioncontent',ProviderSubscriptionContent)
// app.component('providersubscriptiondeleteacc',ProviderSubscriptionDeleteAcc)
// app.component('resetpasswordcontent',ResetpasswordContent)
// app.component('searchlistcontent',SearchListContent)
// app.component('securitysettingscontent',SecuritySettingsContent)
// app.component('securitysettingschangemail',SecuritySettingsChangeMail)
// app.component('servicedetailscontent',ServiceDetailsContent)
// app.component('servicelistcontent',ServiceListContent)
app.component('usersignupcontent',UserSignupContent)
// app.component('indexprofessional',IndexProfessional)
// app.component('indexfeatureservice',IndexFeatureService)
// app.component('indexoffering',IndexOffering)
// app.component('indexplaning',IndexPlaning)
// app.component('indexblog',IndexBlog)
// app.component('relatedservice',RelatedService)
// app.component('scroll',Scroll)
// app.component('indexeightbanner',IndexEightBanner)
// app.component('indexeightpopular',IndexEightPopular)
// app.component('indexeightmeet',IndexEightMeet)
// app.component('indexeightcustomer',IndexEightCustomer)
// app.component('indexeightblog',IndexEightBlog)
// app.component('indexeightfooter',IndexEightFooter)
// app.component('service-information',ServiceInformation)
// app.component('service-availability', ServiceAvailability)
// app.component('service-location', ServiceLocation)
// app.component('service-gallery',ServiceGallery)
// app.component('service-seo', ServiceSeo)
// app.component('bookingsort',Bookingsort)
// app.component('bookinglist',Bookinglist)
// app.component('frontend-timepicker', TimePicker)
// app.component('service-lightbox',ServiceLightbox)
// app.component('servicessticksidebar',Servicessticksidebar)
// app.component('servicedetails',Servicedetails)
// app.component('dashboardbooking',Dashboardbooking)
// app.component('myserviceactive',Myserviceactive)
// app.component('myserviceinactive',Myserviceinactive)
// app.component('providerbookinglist',Proivderbookinglist)
// app.component('pagination',Pagination)
// app.component('recenttransaction',Recenttransaction)
// app.component('dashboardwidget',Dashboardwidget)
// app.component('recentbooking',Recentbooking)
// app.component('favouritelist',Favouritelist)
// app.component('favouritesort',Favouritesort)

app.component('breadcrumb',BreadCrumb)
app.component('blogbreadcrumb',BlogBreadcrumb)
app.component('provider-header', ProviderHeader)

/****************** Admin Model  ****************/
// app.component('announcement-model',Announcement_Model)
// app.component('abuse-model',Abusemodel)
// app.component('sms-template-model',Sms_Template_Model)
// app.component('email-template-model',Email_Template_Model)
// app.component('email-newsletter-model',Email_Newsletter_Model)
// app.component('featured-service-model',Featured_Service_Model)
// app.component('service-offers-model',Service_Offers_Model)
// app.component('roles-model',Roles_Model)
// app.component('customers-model',Customers_Model)
// app.component('provider-model',Provider_Model)
// app.component('faq-model',Faq_Model)
// app.component('testimonials-model',Testimonials_Model)
// app.component('conutries-model',Conutries_Model)
// app.component('states-model',States_Model)
// app.component('cities-model',Cities_Model)
// app.component('users-model',Users_Model)
// app.component('pages-model',Pages_Model)
// app.component('payout-request-model',Payout_Request_Model)
// app.component('sub-categories-model',Sub_Categories_Model)
// app.component('categories-model',Categories_Model)
// app.component('review-type-model',Review_Type_Model)
// app.component('report-delete-model',Report_Delete_Model)
// app.component('delete-model',Delete_Model)
// app.component('delete-model-one',Delete_Model_One)
// app.component('taxratemodel',Taxrate_model)
// app.component('security-model',Security_Model)
// app.component('blog-categories-model',Blog_Categories_Model)
// app.component('marketing-coupons-model',Marketing_Coupons_Model)
// app.component('database-backup-model',Database_Backup_Model)
// app.component('system-model',System_Model)
// app.component('banip-model',Banip_Model)
// app.component('storage-model',Storage_Model)
// app.component('currencies-model',Currencies_Model)
// app.component('payment-model',Payment_Model)
// app.component('gateways-model',Gateways_Model)
// app.component('sms-model',Sms_Model)
// app.component('email-model',Email_Model)
// app.component('social-model',Social_Model)
// app.component('contact-message-model',Contact_Message_Model)
// app.component('offers-model',Offers_Model)



// *************** Model Components ***************
app.component('model', Model)
app.component('c-booking-model',C_Booking_Model)
app.component('provider-coupons-model',Provider_Coupons_Model)
app.component('provider-booking-model',Provider_Booking_Model)
app.component('provider-settings-model',Provider_settings_Model)

app.component('SummernoteEditor', SummernoteEditor);
app.component('vue-select', VueSelect)
app.component('datepicker', DatePicker)
app.component('timepicker',VueTimepicker)
app.component('vue3-autocounter', Vue3Autocounter)

.use(VueTelInput)
.use(BootstrapVue3)
.use(BToastPlugin)
.use(Antd)
.use(AOS.init())
app.use(VueSweetalert2)
app.use(VCalendar, {})
app.use(VueApexCharts);
app.use(VueEasyLightbox);
import {LoadingPlugin} from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
app.use(LoadingPlugin);
app.use(VLazyImagePlugin);
app.use(router).mount('#app');

import axios from 'axios';
import { useAuthStore } from './stores/authStore';
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
// add token to localstorage
if (useAuthStore().token) {
    axios.defaults.headers.common['Authorization'] = 'bearer ' + useAuthStore().token;
}

import { useRoute } from 'vue-router';
const route = useRoute();
// check error handling
axios.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response && error.response.data) {
    
    // error token expired
    if (error.response.data.status == 401) {
      console.log(error.response.data.message);
      swal.fire({icon: 'error', text: error.response.data.message});
      useAuthStore().clearState();
      router.push('/login')
      setTimeout(() => {
        window.location.reload(); 
      }, 1000); 
      // router.go();
    }

    // error email not verified
    if (error.response.status == 403) {
      router.replace('/admin/email-verification');
    }

    // error validasi form
    // if (error.response.status == 422) {
    //   let message = '';
    //   Object.keys(error.response.data).forEach(element => {
    //       message += error.response.data[element][0] + '<br />'
    //   })
    //   swal.fire({icon: 'error', html: message});
    // }

    // error message
    if (error.response.status == 400) {
      swal.fire({icon: 'error', html: error.response.data.message});
    }

    return Promise.reject(error);
  }

  // error network
  if (!error.status) {
    swal.fire({icon: 'error', html: error.message});
  }
  return Promise.reject(error);
});

