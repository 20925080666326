<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper">
      <div class="mt-4">
        <div class="row">
          <div class="col-lg-7 col-sm-12 m-auto">
            <div class="content-page-header">
              <h5 class="mb-2">Metode {{ metode }}</h5>
            </div>
            <div class="form-group">
              <label>Pilih Metode Untuk Upload File</label>
              <div class="backable-options">
                <input
                  type="radio"
                  id="backable-manual"
                  v-model="metode"
                  :value="'manual'"
                />
                <label class="backable-label" for="backable-manual"
                  >Manual Upload</label
                >

                <input
                  type="radio"
                  id="backable-import"
                  v-model="metode"
                  :value="'import'"
                />
                <label class="backable-label" for="backable-import"
                  >Import With Excel File ( .XLSS )</label
                >

                <input
                  type="radio"
                  id="backable-kecermatan"
                  v-model="metode"
                  :value="'kecermatan'"
                />
                <label class="backable-label" for="backable-kecermatan"
                  >Kecermatan</label
                >

                <input
                  type="radio"
                  id="backable-kraepelin"
                  v-model="metode"
                  :value="'kraepelin'"
                />
                <label class="backable-label" for="backable-kraepelin"
                  >Kraepein / Pauli</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content" v-if="metode == 'manual'">
        <b-form @submit.prevent="submitForm">
          <div class="row">
            <div class="col-lg-7 col-sm-12 m-auto">
              <div class="content-page-header">
                <h5 class="mb-2">Add Question</h5>
              </div>

              <div v-if="type" class="col-lg-12">
                <div class="form-group">
                  <label>Tipe Soal</label>
                  <vue-select
                    v-model="data.type"
                    :options="typeOption"
                    required
                    placeholder="Tipe Soal"
                  />
                  <div v-if="errors.type" class="text-danger">
                    {{ errors.type[0] }}
                  </div>
                </div>
              </div>
              <div v-if="type" class="col-lg-12">
                <div class="form-group">
                  <label>Kolom</label>
                  <vue-select
                    v-model="data.column_id"
                    :options="column"
                    required
                    placeholder="column"
                  />
                  <div v-if="errors.column_id" class="text-danger">
                    {{ errors.column_id[0] }}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="col-lg-12"></div>
                  <div v-if="type" class="form-group">
                    <label>Soal</label>
                    <summernoteEditor
                      
                      v-model="data.questions"
                      type="text"
                      class="form-control"
                      required
                      :maxlength="type === 'number' ? 5 : null"
                      @input="validateInput"
                    />
                    <!-- <input
                      v-else
                      v-model="data.questions"
                      type="text"
                      class="form-control"
                      required
                      :maxlength="type === 'number' ? 5 : null"
                      @input="validateInput"
                    /> -->
                    <div v-if="errors.questions" class="text-danger">
                      {{ errors.questions[0] }}
                    </div>
                  </div>
                  <div v-if="type == 'multiple_choice' || data.type == 'image'" class="mb-4">
                    <label class="form-label">Image (Optional)</label>
                    <div class="form-uploads">
                      <div class="form-uploads-path">
                        <img
                          v-if="preview.image"
                          :src="preview.image"
                          alt="img"
                        />
                        <img
                          v-else
                          src="@/assets/admin_img/icons/upload.svg"
                          alt="img"
                        />
                        <div class="file-browse">
                          <h6>Pilih Gambar</h6>
                          <div class="file-browse-path">
                            <input
                              @change="handleMainImageUpload"
                              type="file"
                            />
                            <a href="javascript:void(0);"> Browse</a>
                          </div>
                        </div>
                        <h5>Supported formates: JPEG, PNG</h5>
                      </div>
                    </div>
                    <div v-if="errors.image" class="text-danger">
                      {{ errors.image[0] }}
                    </div>
                  </div>
                </div>

                <div v-if="type" class="col-lg-12">
                  <div class="form-group">
                    <label>Point</label>
                    <input
                      v-model="data.point"
                      required
                      type="text"
                      class="form-control"
                    />
                    <div v-if="errors.point" class="text-danger">
                      {{ errors.point[0] }}
                    </div>
                  </div>
                </div>

                <div
                  v-if="
                    type && (type === 'multiple_choice' || data.type != 'campuran')
                  "
                  class="col-lg-6"
                >
                  <div class="form-group">
                    <label>Pilihan A</label>
                    <input
                      v-model="data.choice_a"
                      type="text"
                      class="form-control"
                    />
                    <br />
                    <div v-if="errors.choice_a" class="text-danger">
                      {{ errors.choice_a[0] }}
                    </div>
                    <!-- Tambahkan input file untuk upload gambar -->
                    <div class="form-uploads">
                      <label>(Optional)</label>
                      <div class="form-uploads-path">
                        <img
                          v-if="preview.img_choice_a"
                          :src="preview.img_choice_a"
                          alt="Uploaded Image A"
                        />
                        <img
                          v-else
                          src="@/assets/admin_img/icons/upload.svg"
                          alt="Upload Icon A"
                        />
                        <div class="file-browse">
                          <h6>Pilih Gambar</h6>
                          <div class="file-browse-path">
                            <input
                              @change="handleMainImageAUpload"
                              type="file"
                            />
                            <a href="javascript:void(0);"> Browse</a>
                          </div>
                        </div>
                        <h5>Supported formats: JPEG, PNG</h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  v-if="
                    type && (type === 'multiple_choice' || data.type != 'campuran')
                  "
                  class="col-lg-6"
                >
                  <div class="form-group">
                    <label>Pilihan B</label>
                    <input
                      v-model="data.choice_b"
                      type="text"
                      class="form-control"
                    />
                    <br />
                    <div v-if="errors.choice_b" class="text-danger">
                      {{ errors.choice_a[0] }}
                    </div>
                    <!-- Tambahkan input file untuk upload gambar -->
                    <div class="form-uploads">
                      <label>(Optional)</label>
                      <div class="form-uploads-path">
                        <img
                          v-if="preview.img_choice_b"
                          :src="preview.img_choice_b"
                          alt="Uploaded Image B"
                        />
                        <img
                          v-else
                          src="@/assets/admin_img/icons/upload.svg"
                          alt="Upload Icon B"
                        />
                        <div class="file-browse">
                          <h6>Pilih Gambar</h6>
                          <div class="file-browse-path">
                            <input
                              @change="handleMainImageBUpload"
                              type="file"
                            />
                            <a href="javascript:void(0);"> Browse</a>
                          </div>
                        </div>
                        <h5>Supported formats: JPEG, PNG</h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  v-if="
                    type && (type === 'multiple_choice' || data.type != 'campuran')
                  "
                  class="col-lg-6"
                >
                  <div class="form-group">
                    <label>Pilihan C</label>
                    <input
                      v-model="data.choice_c"
                      type="text"
                      class="form-control"
                    />
                    <br />
                    <div v-if="errors.choice_c" class="text-danger">
                      {{ errors.choice_c[0] }}
                    </div>
                    <!-- Tambahkan input file untuk upload gambar -->
                    <div class="form-uploads">
                      <label>(Optional)</label>
                      <div class="form-uploads-path">
                        <img
                          v-if="preview.img_choice_c"
                          :src="preview.img_choice_c"
                          alt="Uploaded Image C"
                        />
                        <img
                          v-else
                          src="@/assets/admin_img/icons/upload.svg"
                          alt="Upload Icon C"
                        />
                        <div class="file-browse">
                          <h6>Pilih Gambar</h6>
                          <div class="file-browse-path">
                            <input
                              @change="handleMainImageCUpload"
                              type="file"
                            />
                            <a href="javascript:void(0);"> Browse</a>
                          </div>
                        </div>
                        <h5>Supported formats: JPEG, PNG</h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  v-if="
                    type && (type === 'multiple_choice' || data.type != 'campuran')
                  "
                  class="col-lg-6"
                >
                  <div class="form-group">
                    <label>Pilihan D</label>
                    <input
                      v-model="data.choice_d"
                      type="text"
                      class="form-control"
                    />
                    <br />
                    <div v-if="errors.choice_d" class="text-danger">
                      {{ errors.choice_d[0] }}
                    </div>
                    <!-- Tambahkan input file untuk upload gambar -->
                    <div class="form-uploads">
                      <label>(Optional)</label>
                      <div class="form-uploads-path">
                        <img
                          v-if="preview.img_choice_d"
                          :src="preview.img_choice_d"
                          alt="Uploaded Image D"
                        />
                        <img
                          v-else
                          src="@/assets/admin_img/icons/upload.svg"
                          alt="Upload Icon D"
                        />
                        <div class="file-browse">
                          <h6>Pilih Gambar</h6>
                          <div class="file-browse-path">
                            <input
                              @change="handleMainImageDUpload"
                              type="file"
                            />
                            <a href="javascript:void(0);"> Browse</a>
                          </div>
                        </div>
                        <h5>Supported formats: JPEG, PNG</h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  v-if="
                    type && (type === 'multiple_choice' || data.type != 'campuran')
                  "
                  class="col-lg-6"
                >
                  <div class="form-group">
                    <label>Pilihan E</label>
                    <input
                      v-model="data.choice_e"
                      type="text"
                      class="form-control"
                    />
                    <br />
                    <div v-if="errors.choice_e" class="text-danger">
                      {{ errors.choice_e[0] }}
                    </div>
                    <!-- Tambahkan input file untuk upload gambar -->
                    <div class="form-uploads">
                      <label>(Optional)</label>
                      <div class="form-uploads-path">
                        <img
                          v-if="preview.img_choice_e"
                          :src="preview.img_choice_e"
                          alt="Uploaded Image E"
                        />
                        <img
                          v-else
                          src="@/assets/admin_img/icons/upload.svg"
                          alt="Upload Icon E"
                        />
                        <div class="file-browse">
                          <h6>Pilih Gambar</h6>
                          <div class="file-browse-path">
                            <input
                              @change="handleMainImageEUpload"
                              type="file"
                            />
                            <a href="javascript:void(0);"> Browse</a>
                          </div>
                        </div>
                        <h5>Supported formats: JPEG, PNG</h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  v-if="type"
                  :class="
                    type == 'multiple_choice' ||
                    data.type == 'image' ||
                    data.type == 'multiple'
                      ? 'col-lg-6'
                      : 'col-lg-12'
                  "
                >
                  <div class="form-group">
                    <label v-if="type != 'number'">Jawaban Benar</label>
                    <label v-else>Angka/Simbol yang hilang</label>
                    <div
                      v-if="
                        type == 'multiple_choice' ||
                        data.type == 'multiple'
                      "
                      class="d-flex gap-2"
                    >
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="answerOption.a"
                          id="flexCheckDefault"
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          A
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="answerOption.b"
                          id="flexCheckDefault"
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          B
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="answerOption.c"
                          id="flexCheckDefault"
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          C
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="answerOption.d"
                          id="flexCheckDefault"
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          D
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="answerOption.e"
                          id="flexCheckDefault"
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          E
                        </label>
                      </div>
                    </div>
                    <div v-if="type == '' || data.type == 'image'" class="d-flex gap-2">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          value="A"
                          v-model="data.answer"
                          id="pilihanA"
                        />
                        <label class="form-check-label" for="pilihanA">
                          A
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          value="B"
                          v-model="data.answer"
                          id="pilihanB"
                        />
                        <label class="form-check-label" for="pilihanB">
                          B
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          value="C"
                          v-model="data.answer"
                          id="pilihanC"
                        />
                        <label class="form-check-label" for="pilihanC">
                          C
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          value="D"
                          v-model="data.answer"
                          id="pilihanD"
                        />
                        <label class="form-check-label" for="pilihanD">
                          D
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          value="E"
                          v-model="data.answer"
                          id="pilihanE"
                        />
                        <label class="form-check-label" for="pilihanE">
                          E
                        </label>
                      </div>
                    </div>
                    <div
                      class="form-group"
                      v-if="
                        type != 'multiple_choice' &&
                        data.type != 'image' &&
                        data.type != 'multiple'
                      "
                    >
                      <input
                        v-model="data.answer"
                        type="text"
                        required
                        class="form-control"
                      />

                      <div v-if="errors.answer" class="text-danger">
                        {{ errors.answer[0] }}
                      </div>
                    </div>

                    <div v-if="errors.answer" class="text-danger">
                      {{ errors.answer[0] }}
                    </div>
                  </div>
                </div>


                <div class="col-lg-12">
                  <div class="btn-path">
                    <b-button @click="goBack" class="btn btn-cancel me-3"
                      >Cancel</b-button
                    >
                    <b-button type="submit" variant="primary">
                      Add Question</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </div>
      <div class="content" v-if="metode == 'import'">
        <div class="row">
          <div class="col-lg-7 col-sm-12 m-auto">
            <div class="content-page-header">
              <h5 class="mb-2">Upload Via Excel ( .xlss )</h5>
            </div>
            <div>
              <b-btn @click="onDownload" class="btn btn-primary"
                >Download Format</b-btn
              >
              <b-form @submit.prevent="onImport">
                <div class="my-3">
                  <label for="formFile" class="form-label"
                    >Upload FIle Excel (.xlss)</label
                  >
                  <input
                    @change="handleFileUpload"
                    class="form-control py-2"
                    type="file"
                    id="formFile"
                  />

                  <div v-if="errors.file" class="text-danger">
                    {{ errors.file[0] }}
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="btn-path">
                    <b-button @click="goBack" class="btn btn-cancel me-3"
                      >Cancel</b-button
                    >
                    <b-button type="submit" variant="primary"> Import</b-button>
                  </div>
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
      <div class="content" v-if="metode == 'kecermatan'">
        <b-form @submit.prevent="submitForm">
          <div class="row">
            <div class="col-lg-7 col-sm-12 m-auto">
              <div class="content-page-header">
                <h5 class="mb-2">Add Question</h5>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="col-lg-12"></div>
                  <div v-if="type" class="form-group">
                    <label>Total Perkolom</label>
                    <input
                      v-model="data.total_percolumn"
                      type="text"
                      class="form-control"
                      required
                      :maxlength="type === 'number' ? 2 : null"
                      @input="validateInput"
                    />
                    <div v-if="errors.total_percolumn" class="text-danger">
                      {{ errors.total_percolumn[0] }}
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="col-lg-12"></div>
                  <div v-if="type" class="form-group">
                    <label>Soal</label>
                    <summernoteEditor
                      v-model="data.questions"
                      type="text"
                      class="form-control"
                      required
                      :maxlength="type === 'number' ? 5 : null"
                      @input="validateInput"
                    />
                    <div v-if="errors.questions" class="text-danger">
                      {{ errors.questions[0] }}
                    </div>
                  </div>
                </div>

                <div v-if="type" class="col-lg-12">
                  <div class="form-group">
                    <label>Point</label>
                    <input
                      v-model="data.point"
                      required
                      type="text"
                      class="form-control"
                    />
                    <div v-if="errors.point" class="text-danger">
                      {{ errors.point[0] }}
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="btn-path">
                    <b-button @click="goBack" class="btn btn-cancel me-3"
                      >Cancel</b-button
                    >
                    <b-button type="submit" variant="primary">
                      Add Question</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </div>
      <div class="content" v-if="metode == 'kraepelin'">
        <b-form @submit.prevent="submitForm">
          <div class="row">
            <div class="col-lg-7 col-sm-12 m-auto">
              <div class="content-page-header">
                <h5 class="mb-2">Add Question</h5>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="col-lg-12"></div>
                  <div v-if="type" class="form-group">
                    <label>Total Perkolom</label>
                    <input
                      v-model="data.total_percolumn"
                      type="text"
                      class="form-control"
                      required
                      :maxlength="type === 'number' ? 2 : null"
                      @input="validateInput"
                    />
                    <div v-if="errors.total_percolumn" class="text-danger">
                      {{ errors.total_percolumn[0] }}
                    </div>
                  </div>
                </div>

                <div v-if="type" class="col-lg-12">
                  <div class="form-group">
                    <label>Point</label>
                    <input
                      v-model="data.point"
                      required
                      type="text"
                      class="form-control"
                    />
                    <div v-if="errors.point" class="text-danger">
                      {{ errors.point[0] }}
                    </div>
                  </div>
                </div>

                <div v-if="type" class="col-lg-12">
                  <div class="form-group">
                    <label>Kolom</label>
                    <vue-select
                      v-model="data.column_id"
                      :options="column"
                      required
                      placeholder="column"
                    />
                    <div v-if="errors.column_id" class="text-danger">
                      {{ errors.column_id[0] }}
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="btn-path">
                    <b-button @click="goBack" class="btn btn-cancel me-3"
                      >Cancel</b-button
                    >
                    <b-button type="submit" variant="primary">
                      Add Question</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import swal from "sweetalert2";
import axios from "axios";
import { uploadFile } from "@/utils/fileUpload";

import { useLoading } from "vue-loading-overlay";
import { useRouter, useRoute } from "vue-router";

let metode = ref("manual");
let errors = ref({});
const $loading = useLoading({});
const router = useRouter();
const route = useRoute();
const tryout_id = ref(route.params.id);
let column = ref();
let data = ref({
  questions: null,
});

import * as XLSX from "xlsx";
const onDownload = () => {
  const data = [
    [
      "tryout_id",
      "questions",
      "image",
      "point",
      "choice_a",
      "choice_b",
      "choice_c",
      "choice_d",
      "choice_e",
      "answer",
      "column_id",
      "type",
      "img_choice_a",
      "img_choice_b",
      "img_choice_c",
      "img_choice_d",
      "img_choice_e",
      "total_percolumn",
    ],
    [
      tryout_id.value,
      "",
      "",
      "",
      "pilihan a",
      "pilihan b",
      "pilihan c",
      "pilihan d",
      "pilihan e",
      "jawaban",
      "",
      "single",
      "",
      "",
      "",
      "",
      "",
    ],
  ];

  const ws = XLSX.utils.aoa_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  XLSX.writeFile(wb, "format.xlsx");
};

const selectedFile = ref(null);

const handleFileUpload = (event) => {
  selectedFile.value = event.target.files[0];
  console.log(selectedFile.value);
};

const onImport = async () => {
  const formData = new FormData();
  formData.append("file", selectedFile.value);
  const loader = $loading.show({});
  try {
    const response = await axios.post("/api/questions/import", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    loader.hide();
    if (response.data.status == 500) {
      swal.fire({ icon: "error", text: response.data.message });
    } else {
      goBack();
      swal.fire({ icon: "success", text: response.data.message });
    }
  } catch (error) {
    loader.hide();
  }
};

let answerOption = ref({
  choice_a: null,
  choice_b: null,
  choice_c: null,
  choice_d: null,
  choice_e: null,
});
let preview = ref({
  image: null,
  img_choice_a: null,
  img_choice_b: null,
  img_choice_c: null,
  img_choice_d: null,
  img_choice_e: null,
  icon: null,
});

let type = ref("");

const typeOption = [
  { id: "multiple", text: "Pilihan Ganda" },
  { id: "campuran", text: "Deret" },
  { id: "image", text: "Image" },
];

let imageFirebase = ref(null);
let img_choice_aFirebase = ref(null);
let img_choice_bFirebase = ref(null);
let img_choice_cFirebase = ref(null);
let img_choice_dFirebase = ref(null);
let img_choice_eFirebase = ref(null);

const handleMainImageUpload = (event) => {
  preview.value.image = URL.createObjectURL(event.target.files[0]);
  imageFirebase.value = event.target.files[0];
};
const handleMainImageAUpload = (event) => {
  preview.value.img_choice_a = URL.createObjectURL(event.target.files[0]);
  img_choice_aFirebase.value = event.target.files[0];
};
const handleMainImageBUpload = (event) => {
  preview.value.img_choice_b = URL.createObjectURL(event.target.files[0]);
  img_choice_bFirebase.value = event.target.files[0];
};
const handleMainImageCUpload = (event) => {
  preview.value.img_choice_c = URL.createObjectURL(event.target.files[0]);
  img_choice_cFirebase.value = event.target.files[0];
};
const handleMainImageDUpload = (event) => {
  preview.value.img_choice_d = URL.createObjectURL(event.target.files[0]);
  img_choice_dFirebase.value = event.target.files[0];
};
const handleMainImageEUpload = (event) => {
  preview.value.img_choice_e = URL.createObjectURL(event.target.files[0]);
  img_choice_eFirebase.value = event.target.files[0];
};Option
const goBack = () => {
  router.go(-1); // atau gunakan router.back();
};

const setTrueAnswer = () => {
  const answerOptions = {
    a: data.value.choice_a ? data.value.choice_a : "A",
    b: data.value.choice_b ? data.value.choice_b : "B",
    c: data.value.choice_c ? data.value.choice_c : "C",
    d: data.value.choice_d ? data.value.choice_d : "D",
    e: data.value.choice_e ? data.value.choice_e : "E",
  };
  const selectedOptions = Object.keys(answerOptions).filter(
    (option) => answerOption.value[option]
  );

  const trueAnswer = selectedOptions.length > 0 ? selectedOptions.join("") : "";
  data.value.type = selectedOptions.length > 0 ? "multiple" : "single";
  data.value.answer = trueAnswer.toUpperCase();
  if (data.value.answer == "A") {
    data.value.answer = data.value.choice_a ? data.value.choice_a : "A";
    data.value.type = "single";
  } else if (data.value.answer == "B") {
    data.value.answer = data.value.choice_b ? data.value.choice_b : "B";
    data.value.type = "single";
  } else if (data.value.answer == "C") {
    data.value.answer = data.value.choice_c ? data.value.choice_c : "C";
    data.value.type = "single";
  } else if (data.value.answer == "D") {
    data.value.answer = data.value.choice_d ? data.value.choice_d : "D";
    data.value.type = "single";
  } else if (data.value.answer == "E") {
    data.value.answer = data.value.choice_e ? data.value.choice_e : "E";
    data.value.type = "single";
  }
};

const setOption = () => {
  if (data.value.questions.length == 5) {
    const a = data.value.questions[0];
    const b = data.value.questions[1];
    const c = data.value.questions[2];
    const d = data.value.questions[3];
    const e = data.value.questions[4];
    data.value.choice_a = a;
    data.value.choice_b = b;
    data.value.choice_c = c;
    data.value.choice_d = d;
    data.value.choice_e = e;
  }
};

const submitForm = async () => {
  const loader = $loading.show({});
  const formData = new FormData();
  if (type.value != "multiple_choice" && data.value.type != "image" && data.value.type != "campuran" && data.value.type != "multiple") {
    if (metode.value !== "kraepelin") {
      setOption();
    }
    formData.append("answer", data.value.answer);
    formData.append("type", "single");
  } else {
    console.log('datra',data.value.type);
    if (data.value.type != 'image' && data.value.type != 'campuran') {
      setTrueAnswer();
      console.log('12');
    }
    if (data.value.type) {
      formData.append("type", data.value.type);
    }
  }
  formData.append("tryout_id", tryout_id.value);
  if (data.value.column_id) {
    formData.append("column_id", data.value.column_id);
  }
  if (imageFirebase.value) {
    try {
      const response = await uploadFile(imageFirebase.value);
      console.log(response);
      formData.append("image", response);
    } catch (error) {
      console.error("Error while uploading file:", error);
    }
  }
  if (img_choice_aFirebase.value) {
    try {
      const response = await uploadFile(img_choice_aFirebase.value);
      console.log(response);
      formData.append("img_choice_a", response);
    } catch (error) {
      console.error("Error while uploading file:", error);
    }
  }
  if (img_choice_bFirebase.value) {
    try {
      const response = await uploadFile(img_choice_bFirebase.value);
      console.log(response);
      formData.append("img_choice_b", response);
    } catch (error) {
      console.error("Error while uploading file:", error);
    }
  }
  if (img_choice_cFirebase.value) {
    try {
      const response = await uploadFile(img_choice_cFirebase.value);
      console.log(response);
      formData.append("img_choice_c", response);
    } catch (error) {
      console.error("Error while uploading file:", error);
    }
  }
  if (img_choice_dFirebase.value) {
    try {
      const response = await uploadFile(img_choice_dFirebase.value);
      console.log(response);
      formData.append("img_choice_d", response);
    } catch (error) {
      console.error("Error while uploading file:", error);
    }
  }
  if (img_choice_eFirebase.value) {
    try {
      const response = await uploadFile(img_choice_eFirebase.value);
      console.log(response);
      formData.append("img_choice_e", response);
    } catch (error) {
      console.error("Error while uploading file:", error);
    }
  }
  if (data.value.questions) {
    formData.append("questions", data.value.questions);
  }
  if (data.value.total_percolumn) {
    formData.append("total_percolumn", data.value.total_percolumn);
  }
  if (data.value.point) {
    formData.append("point", data.value.point);
  }
  if (data.value.choice_a) {
    formData.append("choice_a", data.value.choice_a);
  } else if (img_choice_aFirebase.value) {
    {
      formData.append("choice_a", "A");
    }
  }

  if (data.value.choice_b) {
    formData.append("choice_b", data.value.choice_b);
  } else if (img_choice_cFirebase.value) {
    {
      formData.append("choice_b", "B");
    }
  }
  if (data.value.choice_c) {
    formData.append("choice_c", data.value.choice_c);
  } else if (img_choice_cFirebase.value) {
    {
      formData.append("choice_c", "C");
    }
  }
  if (data.value.choice_d) {
    formData.append("choice_d", data.value.choice_d);
  } else if (img_choice_dFirebase.value) {
    {
      formData.append("choice_d", "D");
    }
  }
  if (data.value.choice_e) {
    formData.append("choice_e", data.value.choice_e);
  } else if (img_choice_eFirebase.value) {
    {
      formData.append("choice_e", "E");
    }
  }

  if (data.value.answer) {
    formData.append("answer", data.value.answer);
  }
  try {
    let response = "";

    if (metode.value == "kecermatan") {
      response = await axios.post("/api/generate-kecermatan", formData);
    } else if (metode.value == "kraepelin") {
      response = await axios.post("/api/generate-kraepelin", formData);
    } else {
      response = await axios.post("/api/questions", formData);
    }

    loader.hide();
    swal.fire({ icon: "success", text: response.data.message });
    goBack();
  } catch (error) {
    loader.hide();
    errors.value = error.response.data;
  }
};

function transformArray(data) {
  return data.map((item) => {
    return {
      id: item.id,
      text: item.name,
    };
  });
}

const fetchData = async () => {
  try {
    const response = await axios.get(`api/tryouts/${route.params.id}/edit`);
    type.value = response.data.tryout.genre;
    data.value.type = response.data.tryout.genre;
  } catch (error) {
    console.error("Error while fetching tryout data:", error);
  }

  try {
    const response = await axios.get("api/columns");
    column.value = response.data.columns;
    column.value = transformArray(column.value);
    column.value.unshift({ id: null, text: "Pilih column" });
    console.log(column.value);
  } catch (error) {
    console.error("Error while fetching columns:", error);
  }
};

const validateInput = () => {
  if (
    type.value === "number" &&
    data.value.questions &&
    data.value.questions.length !== 5
  ) {
    errors.value.questions = ["Input harus memiliki 5 digit"];
  } else {
    errors.value.questions = null;
  }
};

onMounted(() => {
  fetchData();
});
</script>

<style scoped>
.content {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}
</style>
