<template>
  <div class="login-body">
    <div class="main-wrapper">
      <otpheader />

      <div class="page-wrapper">
        <div class="content">
          <div class="container">
            <div class="row">
              <div class="col-md-6 col-lg-6 mx-auto">
                <div class="login-wrap">
                  <div class="login-header">
                    <h3>Login</h3>
                  </div>

                  <!-- Login Form -->
                  <form @submit.prevent="submitForm">
                    <div class="log-form">
                      <div class="form-group">
                        <label class="col-form-label">E-mail</label>
                        <input
                          v-model="data.email"
                          type="text"
                          class="form-control"
                          placeholder="example@email.com"
                        />
                        <div v-if="errors.email" class="text-danger">
                          {{ errors.email[0] }}
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-form-label d-block">Password</label>
                        <div class="pass-group">
                          <input
                            v-if="showPassword"
                            type="text"
                            class="form-control pass-input"
                            v-model="data.password"
                            placeholder="**********"
                          />
                          <input
                            v-else
                            type="password"
                            class="form-control pass-input"
                            placeholder="**********"
                            v-model="data.password"
                          />
                          <span
                            class="toggle-password"
                            @click="toggleShow"
                            :class="{
                              'feather-eye': showPassword,
                              'feather-eye-off': !showPassword,
                            }"
                          ></span>
                          <div v-if="errors.password" class="text-danger">
                            {{ errors.password[0] }}
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="char-length">
                            <p>Must be 6 Characters at Least</p>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="text-md-end">
                            <router-link
                              class="forgot-link"
                              to="password-recovery"
                              >Forgot password?</router-link
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <b-button variant="primary w-100 login-btn" type="submit"
                      >Login</b-button
                    >
                    <div class="login-or">
                      <span class="or-line"></span>
                      <span class="span-or">Or, Login with your email</span>
                    </div>
                    <p class="no-acc">
                      Don't have an account ?
                      <router-link to="/register">Register</router-link>
                    </p>
                  </form>
                  <!-- /Login Form -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <truelyfooter />
    </div>
  </div>
</template>
<script setup>
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/stores/authStore";
import swal from "sweetalert2";
import { useLoading } from "vue-loading-overlay";

const $loading = useLoading({
  // options
});

const authStore = useAuthStore();
const showPassword = ref(false);
const data = ref({
  email: "",
  password: "",
});
const router = useRouter();

const toggleShow = () => {
  showPassword.value = !showPassword.value;
};
let errors = ref({});

const submitForm = async () => {
  // router.push("/customer-dashboard");
  const loader = $loading.show({});
  try {
    const response = await authStore.login(data.value);
    data.value = {};
    loader.hide();
    if (response.data.user.role == "admin") {
      await router.push("/admin/categories");
      router.go();
    }else{
      swal.fire({ icon: "success", text: response.data.message });
      router.push("/home");
    }
  } catch (error) {
    if (error.response) {
      errors.value = error.response.data;
      swal.fire({ icon: "error", text: error.response.data.message ? error.response.data.message : 'Data tidak boleh kosong' });
    } else {
    }
    loader.hide();
  }
};
</script>
